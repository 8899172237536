import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { setVisilityBeginnersMark } from "ducks/GuiderSteps/slice";
import { useEffect } from "react";
import { contactProgressStatusColumn } from "utils/contexts/ContactProgressStatus";
import { debugLog } from "utils/log";
import { toggle, selector } from "ducks/Loading";
import { useState } from "react";

export const columns = [
  {
    field: "processingState",
    ...contactProgressStatusColumn,
  },
  {
    field: "receivedAt",
    headerName: "お問い合わせ日時",
    width: 180,
    valueFormatter: (params) => {
      return DateTime.fromISO(params.value).toFormat("yyyy/MM/dd hh:mm:ss a");
    },
    type: "date",
  },
  {
    field: "companyName",
    headerName: "会社名",
    flex: 1,
  },
  {
    field: "nameOfInquirer",
    headerName: "担当者",
    width: 170,
  },
  {
    field: "category",
    headerName: "問い合わせ種類",
    width: 170,
  },
  {
    field: "method",
    headerName: "問い合わせ方法",
    width: 170,
  },
];

const GuiderSteps = [
  {
    element: ".guide-add",
    position: "left",
    intro: "新しいお問い合わせを作成できます。",
  },
  {
    element: ".guide-filter-button",
    position: "right",
    intro: "テーブルでお問い合わせをフィルターする",
  },
  {
    element: ".guide-searcher-area",
    position: "right",
    intro: "フリーワードでお問い合わせを検索できます。",
  },
  {
    element: ".MuiDataGrid-main",
    position: "top-middle-aligned",
    intro: "全てのお問い合わせ情報が表示します。",
  },
];

/**
 * お問い合わせ管理の表示を行うコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {array} rows お問い合わせ情報
 * @param {Promise} submit 送信処理
 * @fires Container#onSubmitted 送信後時処理
 * @fires Container#onError エラー時
 * @fires Container#onTransite ページ遷移時
 * @fires Container#onFilterModelChange フィルタリング時
 * @fires Container#onSortModelChange ソート時
 * @fires Container#onSearchByText テキスト検索時
 * @param {boolean} isLoading 読み込み中であるか
 */
export const Container = ({
  render,
  rows = [],
  submit = Promise.resolve(),
  onSubmitted = () => {},
  onError = () => {},
  onTransite,
  contactXGridParams,
  isLoading,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector(selector).status;

  useEffect(() => {
    dispatch(setVisilityBeginnersMark(GuiderSteps?.length > 0));
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggle(isLoading));
  }, [dispatch, isLoading]);

  const handleSubmit = (data) => {
    dispatch(toggle(true));
    submit(data)
      .then((res) => {
        setTimeout(() => {
          onSubmitted(res);
          setOpen(false);
          dispatch(toggle(false));
        }, 5000);
      })
      .catch((err) => {
        debugLog(err);
        onError(err);
        dispatch(toggle(false));
      });
  };

  return render({
    contactXGridParams: contactXGridParams,
    columns: columns,
    isLoading: loading,
    onChangeSelection: onTransite,
    open: open,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    onChangeDataSubmit: handleSubmit,
    GuiderSteps: GuiderSteps,
    ...props,
  });
};
