import { makeStyles } from "@mui/styles";

export const styles = makeStyles((theme) => ({
  box_TextSearcher: {
    "& input": {
      boxSizing: "content-box",
      "&::-webkit-input-placeholder": {
        /* Edge */ fontSize: "14px",
      },

      "&:-ms-input-placeholder": {
        /* Internet Explorer 10-11 */ fontSize: "14px",
      },

      "&::placeholder": {
        fontSize: "14px",
      },
    },
  },
}));
