import { API, graphqlOperation } from "aws-amplify";
import { listSystemNotifications } from "api/graphql/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";

const SystemNotificationsKey = "ba8d09b0-1096-c791-1b89-132ab5d5b483";

/**
 * 既読idリストをlocalStorageから取得します。
 * @returns {JSON Object}
 */
const getMarkAsRead = () => {
  return JSON.parse(
    localStorage.getItem(SystemNotificationsKey) ?? `{ "items": [] }`
  );
};

/**
 * 既読idリストをlocalStorageに保存します。
 * @param {JSON Object} jsonObject JSONオブジェクト
 */
const saveMarkAsRead = (jsonObject) => {
  localStorage.setItem(SystemNotificationsKey, JSON.stringify(jsonObject));
};

/**
 * 現れなくなった通知idを消します。
 * @param {array} ids 現在の通知idリスト
 */
const optimizateMarkAsRead = (ids) => {
  if (!ids || ids.length <= 0) {
    return;
  }

  let result = getMarkAsRead();

  let removeIds = [];
  for (const item of result.items) {
    if (!ids.includes(item)) {
      removeIds.push(item);
    }
  }

  for (const id of removeIds) {
    const index = result.items.findIndex((item) => item === id);
    result.items.splice(index, 1);
  }

  saveMarkAsRead(result);
};

/**
 * 未読リストを取得します。
 * @param {object[]} notifications 通知リスト
 * @returns {object[]}
 */
const getUnReadNotifications = (notifications) => {
  if (!notifications || notifications.length <= 0) {
    return [];
  }

  let markAsRead = getMarkAsRead();

  if (markAsRead.items.length <= 0) {
    return notifications;
  }

  let result = [];
  for (const notification of notifications) {
    if (!markAsRead.items.includes(notification.id)) {
      result.push(notification);
    }
  }

  return result;
};

/**
 * 取得します。
 * @returns {object[]}
 */
const fetch = async () => {
  let result = [];
  try {
    const fetchedData = await API.graphql(
      graphqlOperation(listSystemNotifications)
    );
    result = fetchedData.data.listSystemNotifications.items;
    const ids = result.map((value) => value.id);
    optimizateMarkAsRead(ids);
    result = getUnReadNotifications(result);
  } catch (error) {
    throw error;
  }
  return result;
};

export const fetchSystemAlert = createAsyncThunk(
  "systemAlert/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);

/**
 * 既読にします。
 * @param {array} ids システム通知固有番号
 */
const markAsRead = (ids) => {
  let result = getMarkAsRead();
  for (const id of ids) {
    result.items.push(id);
  }
  saveMarkAsRead(result);
};

/**
 * 既読にします。
 * @param {array} ids システム通知固有番号
 */
export const markAsReadSystemAlert = createAsyncThunk(
  "systemAlert/markAsRead",
  async (ids, thunkApi) => {
    markAsRead(ids);
    return fetch();
  }
);
