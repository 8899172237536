import { useState } from "react";
import { useSelector } from "react-redux";
import { EmailConfim, EmailLogin, EmailRegister, ShowEmailUser } from "./Child";
import { useStyles } from "./styles";

/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({ render, activeStep, onNextStep, ...props }) => {
  const classes = useStyles();
  const { step02 } = useSelector((state) => state.Signup);
  const [activeStepChild, setActiveStepChild] = useState(0);

  //次へのボタンをクリックする
  const handleClickButtonNextStep = async () => {
    onNextStep && onNextStep();
  };

  const handleNext = () => {
    setActiveStepChild((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStepChild((prevActiveStep) => prevActiveStep - 1);
  };
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <EmailConfim onNext={handleNext} value={step02?.users} />;
      case 1:
        return step02.registered === true ? (
          <EmailLogin
            value={step02?.users}
            onBack={handleBack}
            onNext={handleNext}
          />
        ) : (
          <EmailRegister value={step02?.users} onBack={handleBack} />
        );
      case 2:
        return <ShowEmailUser value={step02?.logined} />;
      default:
        break;
    }
  };
  const isValid = Boolean(step02?.logined);
  return render({
    classes: classes,
    isValid: isValid,
    onClickButton: handleClickButtonNextStep,
    activeStep: activeStep,
    getStepContent: getStepContent,
    activeStepChild: activeStepChild,

    ...props,
  });
};
