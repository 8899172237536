import { yupResolver } from "@hookform/resolvers/yup";
import { SecondStepLogin } from "ducks/Signup/slice";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { loginSchema } from "./validations";

const defalultValue = {
  email: "",
  password: "",
};
/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({ render, value, onBack, onNext, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onChange",
    defaultValues: defalultValue,
    resolver: yupResolver(loginSchema),
  });
  const {
    getValues,
    trigger,
    control,
    formState: { errors },
    reset,
  } = methods;

  const handleBackToConfirm = () => {
    onBack && onBack();
  };
  const handleLogin = async () => {
    const result = await trigger();
    if (result) {
      const data = getValues();
      dispatch(
        SecondStepLogin({
          ...data,
          username: value?.username ? value?.username : "産廃　太郎",
        })
      );
      onNext && onNext();
    }
  };
  useEffect(() => {
    if (value) {
      reset({ ...defalultValue, ...value });
    }
  }, [reset, value]);
  return render({
    classes: classes,
    methods: methods,
    control: control,
    errors: errors,
    onBackToConfirm: handleBackToConfirm,
    onLogin: handleLogin,
    ...props,
  });
};
