import { Box, FormControl, MenuItem, Select } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { FormHelperText } from "@mui/material";

/**
 * ポップアップセレクションでデータを選択するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <div className={props.classes.label}>
        {props.label}{" "}
        {props.required ? (
          <span className={props.classes.required}>必須</span>
        ) : (
          ""
        )}
      </div>
      <FormControl
        fullWidth={props.fullWidth}
        variant="outlined"
        className={props.classes.style_inputField}
        size="small"
        error={Boolean(props?.error)}
      >
        <Select
          id={props.id}
          autoWidth={true}
          value={props?.value ?? ""}
          onChange={props.onChange}
          displayEmpty
          className={clsx(!props.value && props.classes.emptyValue)}
          inputProps={{
            name: `${props.name}`,
            code: `${props.name}-label-placeholder`,
          }}
        >
          {props?.placeholder && (
            <MenuItem value="" className={props.classes.placeholder}>
              {props?.placeholder}
            </MenuItem>
          )}
          {props.options?.map((option, index) => (
            <MenuItem key={index} value={option.code}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </>
  );
};
