import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { Box, Button, Chip, Paper, Stack } from "@mui/material";
import { getUserAccount } from "api/graphql/queries";
import { userSelector } from "ducks/User";
import { RoleChanger } from "features/Member/RoleChanger";
import { Details } from "features/Member/Details";
import { ResumeDialog } from "features/Member/ResumeDialog";
import { StopDialog } from "features/Member/StopDialog";
import { canControl } from "features/Member/utils";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useOpener } from "utils/useOpener";
import { useQuery } from "utils/useQuery";
import { If } from "views/atoms";
import { DetailsPageFrame } from "views/organisms/DetailsPageFrame";
import { RemoveDialog } from "features/Member/RemoveDialog";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggle } from "ducks/Loading";

/**
 * ユーザーの詳細を表示するコンポーネントです。
 * @returns {JSX.Element}
 */
export const Member = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector(userSelector);
  const authoritiesDialog = useOpener();
  const stopDialog = useOpener();
  const resumeDialog = useOpener();
  const removeDialog = useOpener();

  const onRemove = () => {
    removeDialog.toggle(false);
    navigate(
      {
        pathname: `/members`,
      },
      {
        replace: true,
      }
    );
  };

  const user = useQuery({
    query: getUserAccount,
    variables: {
      id: id,
    },
    onCompleted: () => {},
  });

  dispatch(toggle(user.loading));

  return (
    <>
      <DetailsPageFrame
        information={
          <Box>
            <If condition={user?.data?.getUserAccount?.state === "suspend"}>
              <Chip icon={<DoNotDisturbOnIcon />} label="停止" color="error" />
            </If>
          </Box>
        }
        action={
          <If
            condition={
              user?.loading === false &&
              canControl(currentUser, user?.data?.getUserAccount)
            }
          >
            <Stack direction="row">
              <If condition={user?.data?.getUserAccount?.state === "active"}>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  disabled={user?.loading}
                  onClick={() => stopDialog.toggle(true)}
                >
                  停止する
                </Button>
              </If>
              <If condition={user?.data?.getUserAccount?.state === "active"}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={user?.loading}
                  onClick={() => authoritiesDialog.toggle(true)}
                >
                  権限を変更する
                </Button>
              </If>
              <If condition={user?.data?.getUserAccount?.state === "suspend"}>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  disabled={user?.loading}
                  onClick={() => removeDialog.toggle(true)}
                >
                  削除する
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  disabled={user?.loading}
                  onClick={() => resumeDialog.toggle(true)}
                >
                  利用再開する
                </Button>
              </If>
            </Stack>
          </If>
        }
      >
        <Paper
          sx={{
            p: 2,
            mt: 1,
          }}
        >
          <Details value={user?.data?.getUserAccount} loading={user?.loading} />
        </Paper>
      </DetailsPageFrame>
      <StopDialog
        value={user?.data?.getUserAccount}
        open={stopDialog.open}
        onCompleted={() => {
          user.refetch();
        }}
        onClose={() => stopDialog.toggle(false)}
      />
      <ResumeDialog
        value={user?.data?.getUserAccount}
        open={resumeDialog.open}
        onCompleted={() => {
          user.refetch();
        }}
        onClose={() => resumeDialog.toggle(false)}
      />
      <RemoveDialog
        value={user?.data?.getUserAccount}
        open={removeDialog.open}
        onCompleted={onRemove}
        onClose={() => removeDialog.toggle(false)}
      />
      <RoleChanger
        value={user?.data?.getUserAccount}
        open={authoritiesDialog.open}
        onClose={() => authoritiesDialog.toggle(false)}
        onCompleted={() => {
          user.refetch();
          authoritiesDialog.toggle(false);
        }}
      />
    </>
  );
};
