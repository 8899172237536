import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
import { renderFullText } from "./FullText";

/**
 * Material-ui XGridを表示コンテナプレゼンテーションコンポーネントです
 * @param {array} rows XGridのデータが表示する
 * @param {array} columns XGridのコラム名
 * @param {boolean} onToolbar ツールバーが隠れるようにする
 * @param {object} actionButton 別のボータンが表示　例：新しい事業所。事業場を登録
 * @param {boolean} onDownload ダウンロードのボータンを有効か無効に設定する
 * @param {boolean} onColumns カラムのボータンを有効か無効に設定する
 * @param {boolean} onFilter フリーターのボータンを有効か無効に設定する
 * @param {boolean} multipleSelection 複数行を選択できる
 * @param {boolean} checkboxSelection 行頭にチェックボックスを表示する
 * @param {func} onClose モーダルを閉じる
 * @param {func} onChangeSelection 選択されたアイテムを更新する
 * @param {object} value デフォルトデータ
 * @param {number} pageSizeDisplay 表示する項目数を表示する
 * @param {boolean} disableColumnMenu トゥルーの場合はコラムメニューが非表示になる
 */
export const XGridComponent = containerPresententer(Container, Presententer);

export {
    renderFullText
};
