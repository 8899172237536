import { Auth } from "aws-amplify";
import { selector } from "ducks/User";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ManagementAccount } from "../ManagementAccount";
import { PopupMenu } from "../PopupMenu/PopupMenu";
import { Button as UserButton } from "./Button";
import { useNavigate } from "react-router-dom";

/**
 * ユーザーのアカウントボタンを表示するコンポーネントです。
 * @returns {JSX.Element}
 */
export const Button = () => {
  const navigate = useNavigate();
  const [menuElement, setMenuElement] = useState(null);
  const [managementDialog, setManagementDialog] = useState(false);
  const user = useSelector(selector);

  const handleSignOut = async () => {
    await Auth.signOut();
    navigate(
      {
        pathname: "/",
      },
      {
        replace: true,
      }
    );
    navigate(0);
  };

  return (
    <>
      <UserButton
        value={user.data}
        loading={user.isLoading}
        onClick={(event) => {
          setMenuElement(event.currentTarget);
        }}
      />
      <PopupMenu
        value={user.data}
        loading={user.isLoading}
        anchorEl={menuElement}
        open={Boolean(menuElement)}
        onClose={() => setMenuElement(null)}
        onClickManagementAcount={() => setManagementDialog(true)}
        onClickSignOut={handleSignOut}
      />
      <ManagementAccount
        open={managementDialog}
        onClose={() => setManagementDialog(false)}
      />
    </>
  );
};
