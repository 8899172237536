import { API, graphqlOperation } from "aws-amplify";
import { listPlans } from "api/graphql/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";

/**
 * 取得します。
 * @returns {object[]}
 */
const fetch = async () => {
  let result = [];
  try {
    const fetchedData = await API.graphql(graphqlOperation(listPlans));
    result = fetchedData.data.listPlans.items;
  } catch (error) {
    throw error;
  }
  return result;
};

/**
 * プラン一覧を取得します。
 */
export const fetchPlans = createAsyncThunk(
  "plans/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);
