import yup from "utils/yup";

export const basic = yup.object().shape({
  basePlanId: yup.string().nullable().required(),
  name: yup.string().required(),
  basicCharge: yup.number().required(),
  trialDays: yup.number(),
  description: yup.string().required(),
  meteredBilling: yup.array().of(yup.object().shape({
    charge: yup.number().required(),
    freeTier: yup.number().required()
  })).min(1),
});
