import * as yup from "yup";

export const Subscribers = yup.object().shape({
  company: yup.string().required(),
  email: yup.string().email().required(),
  personInCharge: yup.string().required(),
  plan: yup
    .array()
    .nullable()
    .required()
    .test("plan", (value) => {
      if (value.length > 0) {
        return true;
      }
      return false;
    }),
});
