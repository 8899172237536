import { useState } from "react";
import { useStyles } from "./styles";

/**
 * 引数を受けて、JSX.Elementを返します。
 * @param {object} props
 * @param {object} value お問い合わせ情報
 * @callback render
 * @returns JSX 要素
 */
export const Container = ({
  render,
  value,
  onCanceled = () => {},
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return render({
    classes: classes,
    value: value,
    onCanceled: onCanceled,
    open: open,
    onOpenResendEmailModal: () => setOpen(true),
    onCloseResenEmailModal: () => setOpen(false),
    ...props,
  });
};
