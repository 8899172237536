/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onStartCreatingLogisticsWorkWithSettings = /* GraphQL */ `
  subscription OnStartCreatingLogisticsWorkWithSettings {
    onStartCreatingLogisticsWorkWithSettings {
      message
      ownerCompanyId
    }
  }
`;
export const onCreateSystemNotification = /* GraphQL */ `
  subscription OnCreateSystemNotification {
    onCreateSystemNotification {
      id
      validityStartAt
      validityEndAt
      message
    }
  }
`;
export const onDeleteSystemNotification = /* GraphQL */ `
  subscription OnDeleteSystemNotification {
    onDeleteSystemNotification {
      id
    }
  }
`;
