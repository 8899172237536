import { Button } from "@mui/material";
import React from "react";
import { XGridComponent } from "views/molecules";

/**
 * 利用開始手続きを表示するプレゼンテーションコンポーネントです。
 * @param {oject} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div>
      <XGridComponent
        rows={props.rows}
        columns={props.columns}
        onChangeSelection={props.handleClick}
        onColumns={false}
        onFilter={true}
        onDownload={false}
        disableColumnMenu={true}
        actionButton={
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={props.onOpenDialog}
          >
            新しい販売代理店に利用手続きを送付
          </Button>
        }
      />
    </div>
  );
};
