import { Box, Paper, Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { RenderPDF } from "views/atoms";
import { StepHeader } from "../StepHeader";
import { Steppers } from "../Steppers";
import PrivacyPolicy from "./files/PrivacyPolicy.pdf";
import TermsOfService from "./files/TermsOfService.pdf";

/**
 * 新約同意のステップ1の表示を行うプレゼンテーションコンポーネントです。
 * @returns JSX Element
 */
export const Presententer = (props) => (
  <>
    <StepHeader
      isValid={props.isValid}
      onClickButton={props.isValid ? props.onClickButton : null}
    />
    <Steppers activeStep={props.activeStep}>
      <Box mt={4}>
        <Paper elevation={4}>
          <Box p={3} pl={5}>
            プライバシーポリシー
          </Box>
          <RenderPDF
            filePDF={PrivacyPolicy}
            labelCheckbox="プライバシーポリシーに同意します。"
            onChecked={props.onChecked}
            checkedName="privacyPolicy"
          />
        </Paper>
      </Box>
      <Box mt={4} mb={5}>
        <Paper elevation={4}>
          <Box p={3} pl={5}>
            利用規約
          </Box>
          <RenderPDF
            filePDF={TermsOfService}
            labelCheckbox="利用規約に同意します。"
            onChecked={props.onChecked}
            checkedName="termsOfService"
          />
        </Paper>
      </Box>
      <Box>
        <Snackbar
          open={!props.isValid}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          autoHideDuration={6000}
        >
          <Alert
            variant="filled"
            severity="error"
            className={props.classes.alert_message}
          >
            プライバシーポリシーと利用規約に同意してください。
          </Alert>
        </Snackbar>
      </Box>
    </Steppers>
  </>
);
