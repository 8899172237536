export const PlansMeteredBilling = [];

export const billingUnit = [
  {
    code: "account",
    name: "ユーザー",
  },
  {
    code: "workplace",
    name: "事業場",
  },
];

/**
 * お金表記に変換します。
 * @param {string} value 値
 * @returns {string}
 */
export const toCurrency = (value) => {
  if (!value) {
    return "";
  }
  return new Intl.NumberFormat().format(value);
};

/**
 * オブジェクトからテキストに変換します。
 * @param {object} obj オブジェクト
 * @returns {string}
 */
export const toText = (obj) => {
  if (!obj) {
    return "";
  }

  switch (obj.billingUnit) {
    case "account":
      return `毎月${
        obj?.charge ? toCurrency(obj?.charge) + "円/ユーザー" : ""
      }${obj?.freeTier ? "（" + obj?.freeTier + "ユーザー以降から課金）" : ""}`;
    case "workplace":
      return `毎月${obj?.charge ? toCurrency(obj?.charge) + "円/事業場" : ""}${
        obj?.freeTier ? "（" + obj?.freeTier + "事業場以降から課金）" : ""
      }`;
    default:
      return "";
  }
};
