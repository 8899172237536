import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "utils/useMutation";
import { RoleSelector } from "../RoleSelector";
import { basicSchema } from "./basicSchema";
import { updateUserRole } from "api/graphql/mutations";
import { useSelector } from "react-redux";
import { rolesSelector } from "ducks/Roles";

/**
 * 権限を変更するコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @fires RoleChanger#onClose 閉じる時
 * @fires RoleChanger#onCompleted 完了時
 * @fires RoleChanger#onError エラー時
 * @returns {JSX.Element}
 */
export const RoleChanger = ({
  value = null,
  open = false,
  onClose = () => {},
  onCompleted = () => {},
  onError = () => {},
}) => {
  const roles = useSelector(rolesSelector);
  const [updateUserRoleMutation, updateUserRoleStatus] = useMutation(
    updateUserRole,
    {
      onCompleted: () => {
        onCompleted();
      },
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新できませんでした。",
    }
  );

  const { control, getValues, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      role: !!value
        ? roles.find((role) => role.name === value?.roleName)
        : null,
    },
    resolver: yupResolver(basicSchema),
  });

  const handleUpdate = async () => {
    const result = await trigger();

    if (result) {
      const { role } = getValues();
      updateUserRoleMutation({
        input: {
          id: value?.id,
          roleId: role?.id,
        },
      });
    } else {
      onError && onError(formState.errors);
    }
  };

  useEffect(() => {
    if (open === true) {
      reset({
        role: !!value
          ? roles.find((role) => role.name === value?.roleName)
          : null,
      });
    }
  }, [reset, value, open]);

  return (
    <Dialog
      open={open}
      onClose={updateUserRoleStatus.loading === true ? null : onClose}
    >
      <DialogTitle>権限変更</DialogTitle>
      <DialogContent>
        <DialogContentText>変更する権限を選択してください。</DialogContentText>
        <Controller
          name={`role`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <RoleSelector
                value={value ?? null}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
                disabled={updateUserRoleStatus.loading}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={updateUserRoleStatus.loading}
          color="error"
          onClick={onClose}
        >
          いいえ
        </Button>
        <Button disabled={updateUserRoleStatus.loading} onClick={handleUpdate}>
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};
