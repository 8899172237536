import {
  Box,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import {
  Check,
  PersonOutlined,
  PlaylistAddCheckOutlined,
  VerticalSplitOutlined,
} from "@mui/icons-material";
import clsx from "clsx";
import { RenderTitleInPopupOrModal } from "views/atoms";
import { ColorlibConnector, useColorlibStepIconStyles } from "./styles";

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PlaylistAddCheckOutlined />,
    2: <PersonOutlined />,
    3: <VerticalSplitOutlined />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        icons[String(props.icon)]
      )}
    </div>
  );
}
/**
 * 手続きフローを確認する画面の表示を行うプレゼンテーションコンポーネントです。
 * @returns JSX Element
 */
export const Presententer = (props) => (
  <Container maxWidth="md">
    <Box mt={4}>
      {props.activeStep === props.steps.length ? null : (
        <Paper elevation={4}>
          <Box p={4} pb={0}>
            <RenderTitleInPopupOrModal
              mainTitle="利用手続き"
              subTitle={[
                "システムの利用手続きを行います。3ステップで完了します",
              ]}
            />
          </Box>
          <Stepper
            alternativeLabel
            activeStep={props.activeStep}
            connector={<ColorlibConnector />}
          >
            {props.steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  className={props.classes.step_label}
                  StepIconComponent={ColorlibStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
      )}
      <div>{props.children}</div>
    </Box>
  </Container>
);
