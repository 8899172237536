import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { DateTimePicker, InputField, SelectPopup } from "../../../molecules";

/**
 * 対応状況の更新モーダル内容の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props  ポロパティ
 * @returns
 */
export const Presententer = (props) => {
  return (
    <form id={props.id} onSubmit={props.submit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="contactTime"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DateTimePicker
                label="対応日時"
                required={true}
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="supportState"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectPopup
                label="対応状況"
                placeholder="未選択"
                required={true}
                options={props.contactProgressStatus}
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="details"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="対応内容"
                placeholder="対応内容を入力します。"
                multiline
                rows={4}
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};
