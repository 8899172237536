import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * プラン詳細を選択するコンポーネントです。
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return (
    <Box display="flex" flexDirection="column" fontSize="14px">
      <div className={props.classes.label}>
        {props.label}{" "}
        {props.required && <span className={props.classes.required}>必須</span>}
        <FormControl
          fullWidth
          variant="outlined"
          className={props.classes.style_inputField}
          size="small"
          error={Boolean(props?.error)}
        >
          <Select
            labelId="planSelect"
            id="planSelect"
            multiple
            fullWidth
            disabled={props.disabled}
            displayEmpty
            value={props.value}
            onChange={props.onChange}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <em className={props.classes.placeholder_text}>
                    {props?.placeholder ?? "未選択"}
                  </em>
                );
              }
              return selected
                .map((id) => props.options.find((i) => id === i.code)?.name)
                .join("、");
            }}
            MenuProps={MenuProps}
          >
            {props.options.map((option, index) => (
              <MenuItem key={index} value={option.code}>
                <Checkbox
                  checked={props.value.some((i) => i === option.code)}
                />
                <ListItemText
                  primary={`${option.name}　${new Intl.NumberFormat().format(
                    option.price
                  )}円/月`}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Box>
  );
};
