import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { useXGridComponents } from "utils/TemporaryModules";
import { columns } from "views/templates/Plans/Container";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { add } from "ducks/Alert";
import { planServiceSelector } from "ducks/PlanServices";
import { debugLog } from "utils/log";

/**
 * 販売プラン管理の表示を行うコンテナコンポーネントです
 * @param {object} props プロパティ
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [basePlans, setBasePlans] = useState([]);
  const dispatch = useDispatch();
  const planXGrid = useXGridComponents(columns, "searchPlansByOffset", {
    filter: { and: [{ basePlanId: { exists: true } }] },
  });
  const services = useSelector(planServiceSelector);

  useEffect(() => {
    // API.graphql(
    //   graphqlOperation(searchPlansByOffset, {
    //     filter: { and: [{ basePlanId: { exists: false } }] },
    //   })
    // )
    //   .then((res) => {
    //     setBasePlans(res.data.searchPlansByOffset.items);
    //   })
    //   .catch((err) => {});
  }, []);

  const submit = (data) => {
    // return API.graphql(
    //   graphqlOperation(createPlan, {
    //     input: {
    //       basePlanId: data.basePlanId,
    //       basicCharge: data.basicCharge,
    //       currency: data.currency,
    //       interval: data.interval,
    //       description: data.description,
    //       name: data.name,
    //       trialDays: data.trialDays,
    //       meteredBilling: data.meteredBilling,
    //       servicesProvided: data.servicesProvided,
    //       target: "endUser",
    //     },
    //   })
    // );
  };

  const handleSubmitted = () => {
    debugLog("pages.plans.handleSubmitted");
    planXGrid.functions.refetch();
    dispatch(
      add({
        title: null,
        value: "登録しました",
        severity: "success",
      })
    );
  };

  const handleError = (err) => {
    debugLog("pages.plans.handleError:", err);
    dispatch(
      add({
        title: null,
        value: "エラーが発生したため、登録できませんでした",
        severity: "error",
      })
    );
  };

  const handleTransiteDetails = (data) => {
    navigate(`${location.pathname}/${data}`);
  };

  return render({
    onTransiteDetails: handleTransiteDetails,
    basePlans: basePlans,
    services: services,
    onSubmitted: handleSubmitted,
    onError: handleError,
    submit: submit,
    planXGridParams: planXGrid.params,
    ...props,
  });
};
