import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Chip } from "@mui/material";

/**
 * 解約済Chipを表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Canceled = () => {
  return (
    <Chip icon={<CancelOutlinedIcon />} label={"解約済"} color="default" />
  );
};
