import { Button, Typography } from "@mui/material";
import React from "react";
import { DetailsPageFrame } from "views/organisms/DetailsPageFrame";
import { TabsDetails } from "views/molecules";
import { TabInformation, TabSubscriberList } from "views/organisms/PlanDetails";
import { MessageBox } from "views/molecules";

/**
 * 販売プランの詳細の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <DetailsPageFrame
    information={
      <div>
        <Typography variant="h6">{props.value?.context?.name ?? ""}</Typography>
      </div>
    }
    action={
      (props.value?.subscribers?.length ?? 0) <= 0 ? (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={props.dialog.handleOpen}
          disabled={props.isLoading}
        >
          プランを削除する
        </Button>
      ) : (
        <></>
      )
    }
  >
    <TabsDetails
      tabs={[
        {
          name: "内容",
          tabContent: (
            <TabInformation
              basePlans={props.basePlans}
              value={props.value?.context}
              services={props.services}
            />
          ),
        },
        {
          name: "加入者一覧",
          tabContent: <TabSubscriberList value={props.value?.subscribers} />,
        },
      ]}
    />
    <MessageBox
      open={props.dialog.isOpen}
      title={"確認"}
      description={"削除します、よろしいですか？"}
      action={
        <>
          <Button color="primary" size="large" onClick={props.onDelete}>
            {"はい"}
          </Button>
          <Button color="primary" size="large" onClick={props.dialog.handleClose}>{"いいえ"}</Button>
        </>
      }
    />
  </DetailsPageFrame>
);
