import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  information_area: {
    display: "flex",
    alignItems: "center",
  },
  action_area: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
}));
