import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
// import {
//   searchContactsByOffset,
//   listContactProgress,
// } from "api/graphql/queries";
// import { updateContactResponseStatus } from "api/graphql/mutations";
import { DateTime } from "luxon";
import { add } from "ducks/Alert";
import { toggle, selector } from "ducks/Loading";
import { debugLog } from "utils/log";

/**
 * お問い合わせ詳細の表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(selector).status;
  const [value, setValue] = useState({
    details: null,
    histories: [],
  });

  const getContact = () => {
    // API.graphql(
    //   graphqlOperation(searchContactsByOffset, { filter: { id: { eq: id } } })
    // )
    //   .then((res) => {
    //     setValue((prevState) => {
    //       return {
    //         ...prevState,
    //         details: res.data.searchContactsByOffset.items[0],
    //       };
    //     });
    //   })
    //   .catch((err) => {
    //     dispatch(
    //       add({
    //         title: null,
    //         value: "エラーが発生したため、検索できませんでした",
    //         severity: "error",
    //       })
    //     );
    //     debugLog(err);
    //   });
  };

  const getListContactProgress = () => {
    // API.graphql(
    //   graphqlOperation(listContactProgress, {
    //     contactId: id,
    //   })
    // )
    //   .then((res) => {
    //     setValue((prevState) => {
    //       return {
    //         ...prevState,
    //         histories: res.data.listContactProgress.items,
    //       };
    //     });
    //   })
    //   .catch((err) => {
    //     dispatch(
    //       add({
    //         title: null,
    //         value: "エラーが発生したため、検索できませんでした",
    //         severity: "error",
    //       })
    //     );
    //   });
  };

  useEffect(() => {
    dispatch(toggle(true));

    getContact();
    getListContactProgress();

    dispatch(toggle(false));
  }, [id]);

  const submit = (params) => {
    // return API.graphql(
    //   graphqlOperation(updateContactResponseStatus, {
    //     input: {
    //       contactId: id,
    //       processingState: params.supportState,
    //       details: params.details,
    //       progressedAt: DateTime.fromJSDate(new Date(params.contactTime)),
    //     },
    //   })
    // );
  };

  const handleSubmitted = () => {
    dispatch(
      add({
        title: null,
        value: "登録しました",
        severity: "success",
      })
    );

    getContact();
    getListContactProgress();

    dispatch(toggle(false));
  };

  const handleError = (err) => {
    dispatch(
      add({
        title: null,
        value: "エラーが発生したため、登録できませんでした",
        severity: "error",
      })
    );

    dispatch(toggle(false));
  };

  return render({
    isLoading: isLoading,
    value: value,
    submit: submit,
    onSubmit: handleSubmitted,
    onError: handleError,
    ...props,
  });
};
