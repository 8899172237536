import { Unopened, Opened, Completed, Withdrawal, Expired } from "./index";

/**
 * 手続き状態を表すChipを表示するコンポーネント。
 * @param {string} value 値
 * @returns {JSX.Element}
 */
export const StatusChips = ({ value }) => {
  switch (value) {
    case "unconfirmed":
      return <Unopened />;
    case "untreated":
      return <Opened />;
    case "completed":
      return <Completed />;
    case "cancel":
      return <Withdrawal />;
    case "expired":
      return <Expired />;
    default:
      return <></>;
  }
};
