import { DateTime } from "luxon";
import { debugLog } from "./log";

/**
 * ISODateをテキストに変換します。
 * @param {object} object オブジェクト
 * @param {string} object.value ISODate
 * @param {string} object.formatString フォーマット文字列(デフォルト：yyyy/MM/dd HH:MM:ss)
 * @returns {string} テキスト(formatStringに従う)
 */
export const formatISODateToText = ({
  value = null,
  formatString = "yyyy/MM/dd HH:MM:ss",
}) => {
  if (!value) {
    return "";
  }

  const result = DateTime.fromISO(value, { setZone: "Asia/Tokyo" });

  if (result.isValid) {
    return result.toFormat(formatString);
  } else {
    debugLog("フォーマットエラー: ", value);
  }

  return "";
};

/**
 * 値をAWSDateTimeのフォーマットに変換します。
 * @param {Object} object
 * @param {string} object.value 日付文字列
 * @param {string} object.formatString valueプロパティに対するフォーマット文字列
 * @returns {string}
 */
export const formatAWSDateTime = ({ value, formatString = "yyyy-MM-dd" }) => {
  if (!value) {
    return null;
  }

  const result = DateTime.fromFormat(value, formatString);

  if (result.isValid) {
    return result.toISO();
  } else {
    debugLog("フォーマットエラー: ", value);
  }

  new Error("不正な値が指定されました。");
};

export const formatAPIDateTimeToText = ({ value }) => {
  if (!value) {
    return "";
  }

  return value.substring(0, 19).replace("T", " ").replaceAll("-", "/");
}
