import { usetyles } from "./styles";
import { renderFullText } from "views/molecules/XGridComponent";

const columns = [
  {
    field: "progressedAt",
    headerName: "対応日時",
    width: 180,
  },
  {
    field: "state",
    headerName: "対応状況",
    width: 120,
  },
  {
    field: "content",
    headerName: "対応内容",
    width: 300,
    renderCell: renderFullText,
  },
  {
    field: "registerTime",
    headerName: "登録日時",
    width: 180,
  },
];

/**
 * 加入者一覧を表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {array} value 加入者一覧
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = usetyles();

  return render({
    classes: classes,
    rows: value,
    columns: columns,
    ...props,
  });
};
