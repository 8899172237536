import React from "react";
import { Button, Typography, CircularProgress } from "@mui/material";
import { Tag } from "views/atoms";
import { TabsDetails, ModalPopup } from "views/molecules";
import {
  TabHistory,
  TabInformation,
  SupportForm,
} from "views/organisms/ContactDetails";
import { DetailsPageFrame } from "views/organisms/DetailsPageFrame";
import { toTag } from "utils/contexts/ContactProgressStatus";

/**
 * お問い合わせの詳細の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 */
export const Presententer = (props) => (
  <DetailsPageFrame
    information={
      <div>
        {toTag(props.value?.details?.processingState)}
        <Typography variant="h6">
          {props.value?.details?.companyName}
        </Typography>
      </div>
    }
    action={
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={props.onOpenDialog}
      >
        対応状況を更新する
      </Button>
    }
  >
    <TabsDetails
      tabs={[
        {
          name: "内容",
          tabContent: <TabInformation value={props.value?.details} />,
        },
        {
          name: "対応履歴",
          tabContent: (
            <TabHistory
              value={props.value?.histories ?? []}
              sortModel={props.sortModel}
              onSortModelChange={props.setSortModel}
            />
          ),
        },
      ]}
    />
    <ModalPopup
      title="対応状況の更新"
      open={props.open}
      onClose={props.onCloseDialog}
      disabled={props.submitting}
      action={
        <Button
          disabled={props.submitting}
          form="support-form"
          type="submit"
          variant="contained"
          color="primary"
          size="large"
        >
          {props?.submitting ? (
            <CircularProgress color="inherit" size={24} />
          ) : null}
          更新
        </Button>
      }
    >
      <SupportForm
        id="support-form"
        value={{
          supportState: props.value?.details?.processingState,
        }}
        onSubmit={props.handleSubmit}
        onError={props.handleError}
      />
    </ModalPopup>
  </DetailsPageFrame>
);
