import { Avatar, Button, Typography, Menu, MenuItem } from "@mui/material";
import { ManagementAccount } from "../../../features/User/Account/ManagementAccount";

/**
 * ユーザーアカウントボタンを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  classes,
  anchorEl,
  value,
  onOpenMenu,
  onCloseMenu,
  signOut,
  setManagementAccount,
  managementAccount,
  ...props
}) => (
  <>
    <Button onClick={onOpenMenu} className={classes.box_menu_profile}>
      <Avatar src={value?.icon} className={classes.avatar_text} />
      <Typography>{value?.name}</Typography>
    </Button>
    <Menu
      keepMounted
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onCloseMenu}
    >
      <MenuItem onClick={() => setManagementAccount(true)}>
        アカウント管理
      </MenuItem>
      <MenuItem onClick={signOut}>ログアウト</MenuItem>
    </Menu>
    <ManagementAccount
      open={managementAccount}
      onClose={() => setManagementAccount(false)}
    />
  </>
);
