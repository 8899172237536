import { Box, Button, Grid, Paper } from "@mui/material";
import { RenderTitleInPopupOrModal } from "views/atoms";
import { InputField } from "views/molecules";
import { StepHeader } from "../StepHeader";
import { Steppers } from "../Steppers";
import { InputPlan } from "./Child";


/**
 * 新約同意のステップ1の表示を行うプレゼンテーションコンポーネントです。
 * @returns JSX Element
 */
export const Presententer = (props) => (
  <>
    <StepHeader showButton={false} />
    <Steppers activeStep={props.activeStep}>
      <Paper elevation={4}>
        <Box p={4} pl={5}>
          <Box mb={2}>
            <RenderTitleInPopupOrModal
              mainTitle="利用手続きが完了しました。"
              subTitle={[
                "※　有料プランへの申込が完了すると、その時点でトライアル期間が終了します。",
                "※　トライアル期間に残り日があった場合も無効となり、すぐに有料でのが利用が開始しますのでご注意くだいさい。",
              ]}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <InputPlan
                label="加入プラン"
                disabled={true}
                value={props.plan}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={9}>
              <InputField
                value={props?.startDay}
                label="トライアル終了日"
                disabled={true}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={props.onClickStart}
              >
                開始する
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Steppers>
  </>
);
