import { Box } from "@mui/material";
import React from "react";

const servicelists = [
  {
    id: "S02",
    name: "受注",
    content: ["スポット回収", "定期回収"],
  },
  {
    id: "S03",
    name: "配車",
    content: ["配車表", "配車計画"],
  },
  {
    id: "S04",
    name: "伝票",
    content: ["オリジナル伝票"],
  },
  {
    id: "S05",
    name: "見積",
    content: ["見積書"],
  },
  {
    id: "S06",
    name: "請求",
    content: ["請求書"],
  },
];

/**
 * プランの一目の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const { classes, planDetails } = props;

  const currency = (price) => new Intl.NumberFormat().format(price);

  const services = (servicesProvide) => {
    const list = servicelists.filter((s) =>
      servicesProvide?.some((p) => p.id === s.id)
    );
    return (
      <>
        {list?.map((service, index) => (
          <Box key={index} className={classes.service}>
            <span> {service.name}</span>
            {service.content.map((v) => v).join("・")}
          </Box>
        ))}
      </>
    );
  };
  if (planDetails?.length === 0) {
    return <Box></Box>;
  }
  return (
    <Box className={classes.plans}>
      {planDetails?.map((plan, index) => (
        <Box key={index} boxShadow={20} className={classes.plan_details}>
          {plan?.trialDay && (
            <span className={classes.plan_trial}>
              無料トライアル {plan?.trialDay} 日
            </span>
          )}
          <Box className={classes.plan_name_price}>
            <Box className={classes.plan_name}>{plan?.name}</Box>
            <Box className={classes.plan_price}>
              月額<span>{currency(plan?.basicCharge)}</span>円～
            </Box>
          </Box>

          <Box className={classes.plan_service}>
            <Box fontSize="13px">{plan?.overview}</Box>
            {services(plan?.servicesProvide)}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
