import { createSlice } from "@reduxjs/toolkit";
const defaultValue = {
  step01: {
    value: {},
    checkTime: null,
  },
  step02: {
    users: {
      email: "",
    },
    registered: null,
  },
  step03: {},
};
const signup = createSlice({
  name: "Singup",
  initialState: defaultValue,
  reducers: {
    FirstStepCheckedAll: (state, action) => {
      state.step01.value = { ...state.step01.value, ...action.payload.value };
      state.step01.checkTime = action.payload.checkTime;
    },

    SecondStepEmailConfirm: (state, action) => {
      const { email } = action.payload;
      const originalEmail = "info@greener.co.jp";
      if (email === originalEmail) {
        state.step02.users.email = email;
        state.step02.registered = true;
      } else {
        state.step02.users.email = email;
        state.step02.registered = false;
      }
    },

    SecondStepLogin: (state, action) => {
      state.step02 = { ...state.step02, logined: action.payload };
    },

    SecondStepRegister: (state, action) => {
      const users = action.payload;
      state.step02.users = { ...state.step02.users, ...users };
      state.step02.registered = true;
    },

    ConfirmStepGetData: (state, action) => {
      const data = action.payload;
      state.step03 = { ...state.step03, ...data };
    },
  },
});

export const {
  FirstStepCheckedAll,
  SecondStepEmailConfirm,
  SecondStepRegister,
  SecondStepLogin,
  SecondStepGetData,
  ConfirmStepGetData,
} = signup.actions;
export default signup.reducer;
