// import { searchPlansByOffset } from "api/graphql/queries";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { add } from "ducks/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { debugLog } from "utils/log";
import { planServiceSelector } from "ducks/PlanServices";

/**
 * 販売プランの詳細を表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState({
    context: null,
    subscribers: null,
    basePlans: null,
  });

  const services = useSelector(planServiceSelector);

  useEffect(() => {
    // API.graphql(graphqlOperation(searchPlansByOffset))
    //   .then((res) => {
    //     setValue((prevState) => {
    //       return {
    //         ...prevState,
    //         context: res.data.searchPlansByOffset.items.find(
    //           (item) => item.id === id
    //         ),
    //         basePlans: res.data.searchPlansByOffset.items.filter(
    //           (item) => item.basePlanId === null
    //         ),
    //       };
    //     });
    //   })
    //   .catch((err) => {
    //     debugLog(err);
    //   });
  }, [id]);

  const handleDeletePlan = (value) => {
    // return API.graphql(graphqlOperation(deletePlan, { input: { id: id } }));
  };

  const handleDeleted = () => {
    dispatch(
      add({
        title: null,
        value: "削除しました",
        severity: "success",
      })
    );

    navigate(`/plan`, { replace: true });
  };

  return render({
    value: value,
    basePlans: value.basePlans,
    services:
      value.context?.servicesProvided?.map((service, index) => {
        return {
          ...services.find((item) => item.id === service.serviceId),
          ...service,
        };
      }) ?? null,
    deletePlan: handleDeletePlan,
    onDeleted: handleDeleted,
    ...props,
  });
};
