import { useEffect, useState } from "react";

/**
 * 単体のダイアログの開閉を行うHookです。
 * @param {bool} defaultValue 初期値
 * @returns {object} 開閉状態・開く処理のハンドラ・閉じる処理のハンドラ
 */
export const useSingleDialog = (defaultValue = false) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  useEffect(() => {
    setIsOpen(defaultValue);
  }, [defaultValue]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    handleOpen,
    handleClose,
  };
};
