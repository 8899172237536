import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * システムアラートを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div>
    {props.isLoading && <CircularProgress />}
    {props.value.slice(0, props.maxCount).map((value, index) => (
      <Alert
        onClose={props.onClose(value.id)}
        severity={value?.severity ?? "info"}
        key={index}
      >
        {value?.title ?? <AlertTitle>{value?.title}</AlertTitle>}
        {value?.message}
      </Alert>
    ))}
  </div>
);
