import { useLocation } from "react-router-dom";

// todo: apiコール

/**
 * 加入者詳細を表示するコンテナコンポーネントです
 * @param {func} render 
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { state } = useLocation();
  const value = state?.formValue;
  const submitPlan = (data) => {
  };

  return render({
    value: value,
    onSubmitPlan: submitPlan,
    ...props,
  });
};
