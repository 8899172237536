import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

export const useStyles = makeStyles((theme) => ({
  plans: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: "16px",
  },
  plan_details: {
    padding: "32px 24px",
    position: "relative",
    marginTop: theme.spacing(2),
  },
  plan_trial: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    fontSize: "12px",
    padding: theme.spacing(1),
    position: "absolute",
    top: "-15px",
    left: "8px",
    right: "auto",
  },
  plan_name_price: {
    fontWeight: "bold",
    textAlign: "center",
    padding: "0 24px",
    marginBottom: theme.spacing(3),
  },
  plan_name: {
    fontSize: "18px",
  },
  plan_price: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    borderBottom: "1px solid",
    "& span": { fontSize: "30px" },
  },
  plan_service: {
    color: grey[700],
  },
  service: {
    fontSize: "13px",
    marginTop: theme.spacing(1),
    "& span": {
      fontWeight: "bold",
      color: grey[500],
      marginRight: theme.spacing(3),
    },
  },
}));
