import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import { requiredStyle } from "views/atoms/Tag/styles"

export const useStyles = makeStyles((theme) => ({
  style_inputField: {
    marginTop: theme.spacing(1),
    "& .MuiOutlinedInput-input": {
      color: grey[700],
    },
    "& .MuiOutlinedInput-adornedEnd": {
      //paddingRight: 0,
    },
    "& button ": {
      padding: 0,
    },
  },
  label: {
    color: grey[700],
    fontSize: "14px",
  },
  required: {
    ...requiredStyle(theme),
  },
}));
