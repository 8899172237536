import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import { requiredStyle } from "views/atoms/Tag/styles";

export const useStyles = makeStyles((theme) => ({
  style_inputField: {
    marginTop: theme.spacing(1),
    "& .MuiOutlinedInput-input": {
      color: grey[700],
    },
    "& textarea": {
      ...placeholderTextSize(),
    },

    "& input": {
      ...placeholderTextSize(),
    },
    "& button ": {
      padding: 0,
    },
  },
  label: {
    color: grey[700],
  },
  required: {
    ...requiredStyle(theme),
  },
}));

const placeholderTextSize = () => ({
  boxSizing: "content-box",
  "&::-webkit-input-placeholder": {
    /* Edge */ fontSize: "14px",
  },
  "&:-ms-input-placeholder": {
    /* Internet Explorer 10-11 */ fontSize: "14px",
  },
  "&::placeholder": {
    fontSize: "14px",
  },
});
