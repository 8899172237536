import { Stack, Skeleton } from "@mui/material";
import { InformationItem } from "views/atoms/InformationItem";

/**
 * ユーザー情報の詳細を表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.loading 読み込み中
 * @returns {JSX.Element}
 */
export const Details = ({ value = null, loading = false }) => {
  if (loading === true) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={45} />
        <Skeleton variant="rectangular" height={45} />
        <Skeleton variant="rectangular" height={45} />
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <InformationItem label="ユーザー名">
        {value?.name ?? ""}
      </InformationItem>
      <InformationItem label="権限">
        {value?.roleName ? value?.roleName : ""}
      </InformationItem>
      <InformationItem label="メールアドレス">
        {value?.email ?? ""}
      </InformationItem>
    </Stack>
  );
};
