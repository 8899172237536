import { styles } from "./styles";

/**
 * プラン詳細を選択するコンポーネントです。
 * @param {func} 引数をうけて、JSX.Elementを返すメソッド
 * @param {string} label ラベル
 * @param {string} placeholder プレースホルダー
 * @param {boolean} required 必須かどうかを設定する
 * @param {object} value 選択されたアイテムです
 * @param {func} onChange 選択するアイテムが更新するイベントです
 * @param {array} options アイテム配列
 */
export const Container = ({
  render,
  label,
  required = true,
  disabled = false,
  value,
  onChange,
  options = [],
  placeholder,
  error,
  ...props
}) => {
  const classes = styles();
  const handleChange = (event) => {
    onChange && onChange(event.target.value);
  };

  return render({
    classes: classes,
    label: label,
    placeholder: placeholder,
    required: required,
    value: value,
    onChange: handleChange,
    options: options,
    error: error,
    disabled: disabled,
    ...props,
  });
};
