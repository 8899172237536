import { IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

/**
 * フリーワードの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <TextField
    fullWidth
    placeholder="フリーワード検索"
    type="text"
    variant="outlined"
    value={props.value}
    size="medium"
    onChange={(e) => props.onChange(e)}
    onKeyDown={props.handleKeyDown}
    className={props.classes.box_TextSearcher}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="search"
            size="medium"
            onClick={props.onClickSearch}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);
