import { containerPresententer, withForwardRef } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 日付の選択をテキストで表示するコンポーネントです。
 */
export const DatePicker = withForwardRef(
  containerPresententer(Container, Presententer)
);

DatePicker.defaultProps = {
  value: null,
  label: "年月日",
};
