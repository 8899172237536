import { styles } from "./styles";

/**
 * モーダルでテーブルを表示するコンテナコンポーネントです
 * @param {boolean} open 有効か無効を設定
 * @param {func} onClose 閉じるイベントです
 * @param {object} children モーダルの内容
 * @param {JSX.Element} avatar アバターコンポーネント
 * @param {JSX.Element} headerAction ヘッダーで使用したいアクション要素
 * @param {string} title タイトル
 * @param {string} subheader サブタイトル
 * @param {JSX.Element} action アクション要素
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  open,
  onClose,
  children,
  avatar,
  headerAction,
  title,
  subheader,
  action,
  disabled = false,
  ...props
}) => {
  const classes = styles();

  const handleClose = (event, reason) => {
    if (disabled === true && reason === "backdropClick") {
      return false;
    }

    if (disabled === true && reason === "escapeKeyDown") {
      return false;
    }

    onClose && onClose();
  };

  return render({
    classes: classes,
    children: children,
    open: open,
    onClose: handleClose,
    avatar: avatar,
    headerAction: headerAction,
    title: title,
    subheader: subheader,
    action: action,
    disabled: disabled,
    ...props,
  });
};
