import { useEffect } from "react";

/**
 * useEffect内で非同期処理を実行します。
 * @param {async func} callback 非同期処理
 * @param {func} cleanup 後処理
 * @param {array} dependences 依存関係
 */
export default function useAsyncEffect(callback, cleanup, dependences) {
  useEffect(() => {
    callback && callback();
    return () => {
      cleanup && cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependences);
}
