import { StepConnector } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  signup_appbar: {
    backgroundColor: theme.palette.background.paper,
  },
  toobar_override: { justifyContent: "space-between" },

  step_label: {
    "& .MuiStepLabel-label": {
      fontWeight: "bold",
    },
  },
  toolbar_button: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));
export const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);
