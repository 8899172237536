import { Tag } from "views/atoms";
import { orange, green, blue } from "@mui/material/colors";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CheckIcon from "@mui/icons-material/Check";

/**
 * 未開封のタグを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const UntreatedTag = (props) => (
  <Tag {...props}>
    <span
      style={{
        verticalAlign: "middle",
      }}
    >
      <MailOutlineIcon />
    </span>
    <span>{"未開封"}</span>
  </Tag>
);

/**
 * 未対応のタグを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const PendingTag = (props) => (
  <Tag {...props}>
    <span
      style={{
        verticalAlign: "middle",
      }}
    >
      <BookmarkBorderIcon />
    </span>
    <span>{"未対応"}</span>
  </Tag>
);

/**
 * 対応中のタグを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const InProgressTag = (props) => (
  <Tag {...props}>
    <span
      style={{
        verticalAlign: "middle",
      }}
    >
      <SupervisorAccountIcon />
    </span>
    <span>{"対応中"}</span>
  </Tag>
);

/**
 * 完了のタグを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const CompletedTag = (props) => (
  <Tag {...props}>
    <span
      style={{
        verticalAlign: "middle",
      }}
    >
      <CheckIcon />
    </span>
    <span>{"完了"}</span>
  </Tag>
);

/**
 * お問い合わせの進捗状態を表します。
 */
export const ContactProgressStatus = [
  {
    code: "untreated",
    name: "未開封",
  },
  {
    code: "pending",
    name: "未対応",
  },
  {
    code: "inProgress",
    name: "対応中",
  },
  {
    code: "completed",
    name: "完了",
  },
];

/**
 * コードから名前を取得します。
 * @param {string} code コード
 * @returns {string}
 */
export const toName = (code) => {
  if (!code) {
    return "";
  }
  return ContactProgressStatus.find((item) => item.code === code)?.name ?? "";
};

/**
 * コードからタグコンポーネントを取得します。
 * @param {string} code コード
 * @returns {JSX.Element}
 */
export const toTag = (code) => {
  switch (code) {
    case "untreated":
      return <UntreatedTag backgroundColor={orange[500]} />;
    case "pending":
      return <PendingTag backgroundColor={orange[500]} />;
    case "inProgress":
      return <InProgressTag backgroundColor={blue[500]} />;
    case "completed":
      return <CompletedTag backgroundColor={green[500]} />;
    default:
      return <></>;
  }
};

/**
 * XGridで使用するカラムのフォーマッターです。
 * @param {object} params 引数
 * @returns {string}
 */
export const columnValueFormatter = (params) => {
  return ContactProgressStatus.find((item) => item.code === params.value)?.name;
};

/**
 * XGridで使用するカラムの値のオプションです。
 * @param {object} params 引数
 * @returns {array}
 */
export const columnValueOptions = ContactProgressStatus.map((value) => {
  return {
    label: value.name,
    value: value.code,
  };
});

/**
 * XGridで使用するカラムオブジェクトです。
 * @returns {object}
 */
export const contactProgressStatusColumn = {
  headerName: "対応状況",
  width: 115,
  type: "singleSelect",
  valueFormatter: columnValueFormatter,
  valueOptions: columnValueOptions,
};
