import { useStyles } from "./styles";

/**
 * テキストボックスの表示を行うコンテナコンポーネントです
 * @param {string} label ラベル
 * @param {string} value 値
 * @returns {JSX Element}
 */
export const Container = ({ render, label = "", value = "", ...props }) => {
  const classes = useStyles();
  return render({
    classes: classes,
    label: label,
    value: value,
    ...props,
  });
};
