import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 手続きフローを確認するヘッダーを表示するコンポーネントです。
 *@param {boolean} isValid　ヘッダーボタンを有効か無効にする
 *@param {func} onClickButton ヘッダーボタンのイベント
 *@param {string} buttonText ヘッダーボタンの文字
 *@param {boolean} showButton ボタンの表示をする
 */
export const StepHeader = containerPresententer(Container, Presententer);
