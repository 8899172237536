// import { createFeedback } from "api/graphql/mutations";
import { selector } from "ducks/Loading";
import { userSelector } from "ducks/User";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { stylesCreator } from "./styles";

/**
 * 認証後のページのテンプレートを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {JSX.Element} children コンテンツとなる要素
 * @returns {JSX.Element}
 */
export function Container({ render, children }) {
  const classes = stylesCreator();
  const [open, setOpen] = useState(true);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const user = useSelector(userSelector);
  const loading = useSelector(selector).status;
  const navigate = useNavigate();

  const submitFeedBack = (data) => {
    // dispatch(toggle(true));
    // let result = {
    //   image: data.screenshot
    //     ? {
    //         name: `${DateTime.now().toFormat("yyyyMMddhhmmss")}.png`,
    //         base64: data.screenshot,
    //       }
    //     : null,
    //   message: data.contents,
    // };
    // API.graphql(
    //   graphqlOperation(createFeedback, {
    //     input: result,
    //   })
    // )
    //   .then((res) => {
    //     dispatch(toggle(false));
    //     dispatch(
    //       add({
    //         title: null,
    //         value: "フィードバックをお寄せいただきありがとうございます",
    //         severity: "success",
    //       })
    //     );
    //     setFeedbackOpen(false);
    //   })
    //   .catch((err) => {
    //     dispatch(toggle(false));
    //     dispatch(
    //       add({
    //         title: null,
    //         value: "エラーが発生したため、送信できませんでした",
    //         severity: "error",
    //       })
    //     );
    //     debugLog("FeedbackAPI.submit.error", err);
    //   });
  };

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handleClickHomePage = () => {
    navigate("/");
  };

  return render({
    classes: classes,
    systemName: process.env.REACT_APP_SYSTEM_NAME,
    children: children,
    open: open,
    onToggleDrawer: handleToggleDrawer,
    onClickHomePage: handleClickHomePage,
    isOpenFeedback: feedbackOpen,
    onOpenFeedback: () => setFeedbackOpen(true),
    onCloseFeedback: () => setFeedbackOpen(false),
    user: user,
    loading: loading,
    submitFeedBack: submitFeedBack,
  });
}
