import { debugLog } from "utils/log";
import { useStyles } from "./styles";

/**
 * フィードバックボタンの表示を行うコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @fires Container#onClick クリック時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  onClick = () => debugLog("FeedbackButton onClick"),
  ...props
}) => {
  const classes = useStyles();

  return render({
    classes: classes,
    onClick: onClick,
    ...props,
  });
};
