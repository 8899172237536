import { Box, Paper } from "@mui/material";
import { StepHeader } from "../StepHeader";
import { Steppers } from "../Steppers";

/**
 * アカウント登録のステップの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} classes クラス名
 * @param {boolean} isValid 次へのボタンを有効か無効にする
 * @param {func} onClickButton 次へのボタンのイベント
 * @param {number} activeStep 現在のステップナンバー
 *
 * @param {boolean} registerEmail メールがあるかどうか
 *
 * @param {object} methods useFormのmethods
 * @param {object} control useFormのcontrol
 * @param {object} errors useFormのerrors
 *
 * @param {func} onConfirmEmail 確認ボタンをクリックする時のイベント
 * @param {object} onResendMailRegsiter 再送メールのボタンをクリックする時のイベント
 * @returns JSX Element
 */
export const Presententer = (props) => (
  <>
    <StepHeader
      isValid={props.isValid}
      onClickButton={props.isValid ? props.onClickButton : null}
    />
    <Steppers activeStep={props.activeStep}>
      <Box mt={4} mb={4}>
        <Paper elevation={4}>
          <Box p={4} pl={5}>
            {props.getStepContent(props.activeStepChild)}
          </Box>
        </Paper>
      </Box>
    </Steppers>
  </>
);
