import { onClose, selector } from "ducks/GuiderSteps/slice";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
/**
 * @returns　JSX Element
 */
export const Container = ({ render, value = [], ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enabled, initialStep, visibility } = useSelector(selector);

  const handleExitBeginner = () => {
    dispatch(onClose());
  };

  return render({
    classes: classes,
    stepsEnabled: enabled,
    steps: value,
    initialStep: initialStep,
    onExit: handleExitBeginner,
    visibility: visibility,
    ...props,
  });
};
