import { makeStyles } from "@mui/styles";
import { deepPurple } from "@mui/material/colors";

export const useStyles = makeStyles((theme) => ({
  box_menu_profile: {
    "& .MuiTypography-root ": {
      marginLeft: theme.spacing(2),
    },
  },
  avatar_text: {
    fontSize: "12px",
    backgroundColor: deepPurple[300],
  },
}));
