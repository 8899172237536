import reducer, {
  selector,
  plansSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";

import { fetchPlans } from "./api";

export default reducer;

export {
  fetchPlans,
  selector,
  plansSelector,
  isLoadingSelector,
  errorSelector,
};
