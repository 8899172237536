import { Button } from "@mui/material";
import { useXGridComponents } from "utils/useXGridComponents";
import { useOpener } from "utils/useOpener";
import { XGridComponent } from "views/molecules";
import {
  Completed,
  Expired,
  Opened,
  Unopened,
  Withdrawal,
} from "../../Invites/StatusChips";
import { InvitationForm } from "../InvitationForm";
import { formatISODateToText, formatAPIDateTimeToText } from "utils/formats";
import { searchPlanSubscribeProceduresByOffset } from "api/graphql/queries";

const renderStateCell = (params) => {
  switch (params.value.id) {
    case "unconfirmed":
      return <Unopened />;
    case "untreated":
      return <Opened />;
    case "completed":
      return <Completed />;
    case "cancel":
      return <Withdrawal />;
    case "expired":
      return <Expired />;
    default:
      return <></>;
  }
};

const columns = [
  {
    field: "state",
    headerName: "手続き状況",
    flex: 1,
    renderCell: renderStateCell,
  },
  {
    field: "createdAt",
    headerName: "作成日時",
    flex: 1,
    renderCell: (params) => formatAPIDateTimeToText({ value: params.value }),
  },
  {
    field: "expireAt",
    headerName: "URL期限",
    flex: 1,
    renderCell: (params) => formatAPIDateTimeToText({ value: params.value }),
  },
  {
    field: "companyName",
    headerName: "会社名",
    flex: 1,
  },
  {
    field: "planName",
    flex: 1,
    headerName: "プラン",
    renderCell: (params) => params.row.plan.name,
  },
  {
    field: "contactPersonnel",
    headerName: "担当者名",
    flex: 1,
  },
  {
    field: "email",
    headerName: "メールアドレス",
    flex: 1,
  },
];

/**
 * 利用手続き状況一覧を表示するコンポーネント。
 * @param {object} porps プロパティ
 * @fires InvitationForm#onSelected 選択時
 * @returns {JSX.Element}
 */
export const InvitationStatusList = ({ onSelected = () => {} }) => {
  const form = useOpener();
  const xGridObject = useXGridComponents(
    columns,
    searchPlanSubscribeProceduresByOffset
  );

  const handleSelect = (items) => {
    onSelected &&
      onSelected({
        value: xGridObject.params.rows.filter((row) => items.includes(row.id)),
        info: {
          search: xGridObject.search,
        },
      });
  };

  return (
    <>
      <XGridComponent
        {...xGridObject.params}
        onChangeSelection={handleSelect}
        onColumns={false}
        onFilter={true}
        onDownload={false}
        disableColumnMenu={true}
        actionButton={
          <Button
            variant="contained"
            color="primary"
            onClick={() => form.toggle(true)}
            disabled={xGridObject.params.loading}
          >
            新しい加入者向けの利用手続きURLを作成
          </Button>
        }
      />
      <InvitationForm
        open={form.open}
        onClose={() => form.toggle(false)}
        onCompleted={() => {
          xGridObject.functions.refetch();
          form.toggle(false);
        }}
      />
    </>
  );
};
