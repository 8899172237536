import { makeStyles } from "@mui/styles";
import { overideStyleButton } from "utils/styles";
export const useStyles = makeStyles((theme) => ({
  button: {
    ...overideStyleButton(theme),
  },
  button_login: {
    marginLeft: theme.spacing(2),
  },
}));
