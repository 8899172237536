import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({ render, value, activeStep, ...props }) => {
  const classes = useStyles();
  const {
    step03: { plan },
  } = useSelector((state) => state.Signup);
  const stateSignup = useSelector((state) => state.Signup);
  console.log("stateSignup: ", stateSignup)
  const startDay = format(new Date(), "yyyy年mm月d日");

  const handleClickStart = () => {};
  // const plans = value.filter((row)=> plan.includes(row.id))
  return render({
    classes: classes,
    plan: value,
    startDay: startDay,
    onClickStart: handleClickStart,
    activeStep: activeStep,
    ...props,
  });
};
