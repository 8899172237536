import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { errorSelector, isLoadingSelector, rolesSelector } from "ducks/Roles";
import { userSelector } from "ducks/User";
import { useSelector } from "react-redux";
import { fetchRemoveRoleNames } from "../utils";

/**
 * 権限を選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.value 値
 * @fires RoleSelector#onChange 変更時
 * @param {boolean} props.error エラーであるか
 * @param {string} props.helperText 補助的テキスト
 * @param {string} props.label ラベル
 * @param {boolean} props.disabled 無効設定
 * @returns {JSX.Element}
 */
export const RoleSelector = ({
  value = null,
  onChange = () => {},
  error = false,
  helperText = "",
  label = "",
  disabled = false,
}) => {
  const roles = useSelector(rolesSelector);
  const currentUser = useSelector(userSelector);
  const removeRoleNames = fetchRemoveRoleNames(currentUser?.roleName);
  const isLoading = useSelector(isLoadingSelector);
  const errors = useSelector(errorSelector);

  if (isLoading === true) {
    return <Skeleton />;
  }

  if (!!errors) {
    return <Typography color="error.main">エラーが発生しました。</Typography>;
  }

  const handleChange = (event) => {
    onChange(roles.find((role) => role.id === event.target.value) ?? null);
  };

  const options = roles.filter((role) =>
    removeRoleNames.length <= 0
      ? true
      : !removeRoleNames.some((name) => name === role.name)
  );

  return (
    <FormControl
      variant="standard"
      sx={{ m: 1, minWidth: 120 }}
      error={error}
      disabled={disabled}
    >
      <InputLabel shrink id="label">
        {label}
      </InputLabel>
      <Select
        labelId="label"
        id="role-selector"
        value={value?.id}
        label={label}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>未選択</em>
        </MenuItem>
        {options.map((role, index) => (
          <MenuItem key={index} value={role.id}>
            {role.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
