import { useStyles } from "./styles";

/**
 * お知らせの表示を行うコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {array} value お知らせ情報
 * @fires Container#onClick クリック時
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, onClick }) => {
  const classes = useStyles();

  return render({
    classes: classes,
    value: value,
    onClick: onClick,
  });
};
