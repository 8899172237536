import { useEffect } from "react";

const url = `${process.env.PUBLIC_URL}/maintenance-schedule.json`;
export const MaintenanceMonitor = () => {
  useEffect(() => {
    if (window.location.pathname === "/maintenance") {
      window.location.href = "/maintenance.html";
    }
    fetch(url)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result.maintenaceActive) {
          window.location.href = "/maintenance.html";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return null;
};
