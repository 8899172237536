import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

export const useStyles = makeStyles((theme) => ({
  box_icon_notifications: {
    marginRight: theme.spacing(4),
    position: "relative",
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      right: "0",
      width: "1px",
      height: "30px",
      backgroundColor: grey[300],
    },
  },
  icon_notifications: {
    color: grey[400],
    "& .MuiBadge-badge": {
      fontSize: "11px",
      height: "17px",
      minWidth: "17px",
      border: " 2px solid #fff",
      padding: "0 3px",
    },
  },
}));
