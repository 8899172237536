import { Box, TextField } from "@mui/material";
import { Tag } from "views/atoms";

/**
 * テキストフィールドの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 */
export const Presententer = ({ 
  classes,
  label,
  required,
  value,
  error,
  helperText,
  onChange,
  placeholder,
  type,
  ...other
}) => (
  <Box display="flex" flexDirection="column" fontSize="14px">
    <div className={classes.label}>
      {label} {required && <Tag margin={"0px 0px 0px 8px"}>{"必須"}</Tag>}
    </div>
    <TextField
      size="small"
      variant="outlined"
      value={value ?? ""}
      onChange={onChange}
      className={classes.style_inputField}
      required={required}
      error={Boolean(error)}
      helperText={helperText}
      type={type}
      placeholder={placeholder}
      {...other}
      label={""}
    />
  </Box>
);
