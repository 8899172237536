import { useEffect } from "react";

/**
 * setIntervalのカスタムフックです。
 * @param {function} callback コールバック関数
 * @param {number} delay どれくらいの間隔で実行するか(ms)
 */
export const useInterval = (callback, delay) => {
  useEffect(() => {
    if (!!delay) {
      const interval = setInterval(() => callback(), delay || 0);

      return () => clearInterval(interval);
    }
  }, [callback, delay]);
};

/**
 * 秒をミリ秒に変換します。
 * @param {number} seconds ミリ秒
 * @returns {number}
 */
export const secondsToMilliseconds = (seconds = 0) => {
  return seconds * 1000;
};

/**
 * 分をミリ秒に変換します。
 * @param {number} minutes 分
 * @returns {number}
 */
export const minutesToMilliseconds = (minutes = 0) => {
  return secondsToMilliseconds(minutes * 60);
};

/**
 * 時をミリ秒に変換します。
 * @param {number} hour 時
 * @returns {number}
 */
export const hoursToMilliseconds = (hour = 0) => {
  return minutesToMilliseconds(hour * 60);
};
