import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * メールあドレスの確認を表示するコンポーネントです。
 * @param {func} onNext 確認ボタンを押す時のイベント
 * @param {object} value メールアドレス
 */
export const EmailConfim = containerPresententer(Container, Presententer);
