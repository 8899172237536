import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import { requiredStyle } from "views/atoms/Tag/styles"

export const useStyles = makeStyles((theme) => ({
  label: {
    color: grey[700],
    marginBottom: theme.spacing(1),
  },
  required: {
    ...requiredStyle(theme),
  },
}));
