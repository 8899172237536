import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  helperLayerOverride: {
    boxShadow:
      "rgb(33 33 33 / 80%) 0px 0px 0px 0px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important",
  },
  beginnerOverride: {
    "& .introjs-tooltip-header": {
      display: "none",
    },
    "& .introjs-tooltiptext": {
      padding: theme.spacing(2),
    },
    "& .introjs-tooltipbuttons": {
      "& a": {
        borderColor: theme.palette.primary.main,
        boxShadow: "none",
        textShadow: "none",
        padding: "4px 16px",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        transition: "0.4s all",
      },
      "& a:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
      },
      "& a.introjs-disabled": {
        borderColor: "#bdbdbd",
        boxShadow: "none",
        backgroundColor: "#f4f4f4",
        color: "#9e9e9e",
      },
      "& a.introjs-disabled:hover": {
        color: "#9e9e9e",
      },
    },
  },
}));
