/**
 * 内容タブを表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 内容の値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value = {}, ...props }) => {
  return render({
    value: value,
    ...props,
  });
};
