import { Auth } from "aws-amplify";
import { isEmpty, ConsoleLogger as Logger } from "@aws-amplify/core";
import { useState } from "react";

const logger = new Logger("SignIn");

/**
 * 有効な認証状態
 */
const VALID_AUTO_STATES = ["signIn", "signedOut", "signedUp"];

/**
 * ログイン画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @returns {func}
 */
export const Container = ({ render, ...props }) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const signIn = async (data) => {
    setError("");
    setLoading(true);

    const { username, password } = data;
    try {
      const user = await Auth.signIn(username, password);
      logger.debug(user);
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        // 二要素認証が指定されているなら確認を行う
        logger.debug("confirm user with " + user.challengeName);
        props.onStateChange("confirmSignIn", user);
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // FORCE_CHANGE_PASSWORDによるパスワード変更を行う
        logger.debug("require new password", user.challengeParam);
        props.onStateChange("requireNewPassword", user);
      } else {
        Auth.verifiedContact(user).then((data) => {
          if (!isEmpty(data.verified)) {
            props.onStateChange("signedIn", user);
          } else {
            const newUser = Object.assign(user, data);
            props.onStateChange("verifyContact", newUser);
          }
        });
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        logger.debug("the user is not confirmed");
        props.onStateChange("confirmSignUp", { username });
      } else if (err.code === "PasswordResetRequiredException") {
        logger.debug("the user requires a new password");
        props.onStateChange("forgotPassword", { username });
      } else if (
        err.code === "NotAuthorizedException" ||
        err.code === "UserNotFoundException"
      ) {
        setError("ログインに失敗しました。");
      } else {
        logger.debug(err);
        setError("ログイン処理中にエラーが発生しました。");
      }
    } finally {
      setLoading(false);
    }
  };

  return render({
    onSubmit: signIn,
    onClickForgotPassword: () => props.onStateChange("forgotPassword"),
    isLoading: isLoading,
    error: error,
    isValid: VALID_AUTO_STATES.includes(props.authState),
    ...props,
  });
};
