import React from "react";
import { DateTime } from "luxon";
import { RenderText } from "views/atoms";

/**
 * 内容の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const { value } = props;

  return (
    <div>
      <RenderText label="お問い合わせ方法" value={value?.method} />
      <RenderText
        label="お問い合わせ日時"
        value={
          value?.receivedAt &&
          DateTime.fromISO(value?.receivedAt).toFormat("yyyy/MM/dd hh:mm:ss a")
        }
      />
      <RenderText label="お問い合わせ種類" value={value?.category} />
      <RenderText label="会社名" value={value?.companyName} />
      <RenderText label="担当者名" value={value?.nameOfInquirer} />
      <RenderText label="電話番号" value={value?.phone} />
      <RenderText label="メールアドレス" value={value?.email} />
      <RenderText label="内容" value={value?.message} />
    </div>
  );
};
