import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BusinessType, Prefectures } from "utils/Context";
import { useStyles } from "./styles";
/**
 * プランのリスト
 */

/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 * @returns
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useStyles();
  const {
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [listPlan, setListPlan] = useState([]);
  const handleGetFullAddress = (address) => {
    const prefectures = Prefectures.find((v) => v.name === address?.pref)?.code;
    setValue("prefectures", prefectures ?? "");
    setValue("city", address.city ?? "");
    setValue("streetAddress", address.town ?? "");
  };

  useEffect(() => {
    /*
    const plansType = rows
      .filter((row) => getValues("plan").includes(row.id))
      .map(({ id, name, basicCharge }) => ({
        code: id,
        name: name,
        price: basicCharge,
      }));
    setListPlan(plansType);
       */
  }, []);
  //const planDetails = rows.filter((row) => watch("plan").includes(row.id));
  const disabledSelectPlan = Boolean(listPlan.length < 2);
  return render({
    classes: classes,
    control: control,
    errors: errors,
    Prefectures: Prefectures,
    BusinessType: BusinessType,
    plansType: listPlan,
    disabledSelectPlan: disabledSelectPlan,
    planDetails: value,
    onGetFullAddress: handleGetFullAddress,
    ...props,
  });
};
