import { containerPresententer } from "utils/HoC";
import Container from "./Container";
import Presententer from "./Presententer";

/**
 * コピーライトを表示するコンポーネントです。
 * @param {object} props プロパティ
 */
const Copyright = containerPresententer(Container, Presententer);

/**
 * @namespace Copyright
 * @property {string} providerName システム名
 * @property {string} systemUrl システムのURL
 */
Copyright.defaultProps = {
  providerName: process.env.REACT_APP_SYSTEM_PROVIDER_NAME,
  systemUrl: process.env.REACT_APP_SYSTEM_PROVIDER_URL,
};

export default Copyright;
