import { debugLog } from "utils/log";
import { useStyles } from "./styles";
import { setVisilityBeginnersMark } from "ducks/GuiderSteps/slice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggle, selector } from "ducks/Loading";
import { useSingleDialog } from "utils/useSingleDialog";

const defaultValues = {
  context: {
    basePlan: "",
    basicCharge: "",
    trialDays: "",
    description: "",
    servicesProvided: "",
  },
  subscribers: null,
};

/**
 * 販売プランの詳細を表示するコンテナコンポーネントです
 * @param {object} value 販売プラン
 * @fires Container#onDelete 削除時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  basePlans = [],
  value,
  services,
  deletePlan = Promise.resolve(),
  onDeleted = () => debugLog("削除"),
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(selector).status;
  const dialog = useSingleDialog();

  useEffect(() => {
    dispatch(setVisilityBeginnersMark());
  }, [dispatch]);

  const handleDeletePlan = () => {
    dialog.handleClose();
    dispatch(toggle(true));
    deletePlan()
      .then((res) => {
        setTimeout(() => {
          dispatch(toggle(false));
          onDeleted();
        }, 5000);
      })
      .catch((err) => {
        dispatch(toggle(false));
      });
  };

  return render({
    classes: classes,
    onDelete: handleDeletePlan,
    value: {
      ...defaultValues,
      ...value,
    },
    isLoading: isLoading,
    basePlans: basePlans,
    services: services,
    dialog: dialog,
    ...props,
  });
};
