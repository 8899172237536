import {
  ContactSupportOutlined,
  DashboardOutlined,
  MonetizationOnOutlined,
  PeopleAltOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import {
  Contact,
  Dashboard,
  Plans,
  Subscribers,
  Agent,
  ContactDetails,
  PlanDetails,
  SubscriberDetails,
  InviteDetails,
  Members,
  Member,
} from "views/pages";
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';

/**
 * ページの全ルート情報を表します
 */

/**
 * ダッシュボードのルート情報
 */
export const routes = [
  // {
  //   title: "ダッシュボード",
  //   shortTitle: "ダッシュボード",
  //   icon: <DashboardOutlined />,
  //   exact: true,
  //   path: "/dashboard",
  //   element: <Dashboard />,
  // },
  // {
  //   title: "販売プラン管理",
  //   shortTitle: "販売",
  //   icon: <MonetizationOnOutlined />,
  //   exact: true,
  //   path: "/plan",
  //   element: <Plans />,
  // },
  // {
  //   title: "販売プラン詳細",
  //   shortTitle: "販売",
  //   icon: <MonetizationOnOutlined />,
  //   exact: true,
  //   path: "/plan/:id",
  //   element: <PlanDetails />,
  // },
  // {
  //   title: "お問い合わせ管理",
  //   shortTitle: "お問い合わせ",
  //   icon: <ContactSupportOutlined />,
  //   exact: true,
  //   path: "/contact",
  //   element: <Contact />,
  // },
  // {
  //   title: "お問い合わせ詳細",
  //   shortTitle: "お問い合わせ",
  //   icon: <ContactSupportOutlined />,
  //   exact: true,
  //   path: "/contact/:id",
  //   element: <ContactDetails />,
  // },
  {
    title: "加入者管理",
    icon: <PeopleAltOutlined />,
    exact: true,
    showBreadcrumbs: true,
    path: "/subscribers",
    element: <Subscribers />,
  },
  {
    title: "加入者詳細",
    icon: <PeopleAltOutlined />,
    exact: true,
    showBreadcrumbs: true,
    path: "/subscribers/subscribe/:id",
    element: <SubscriberDetails />,
  },
  {
    title: "利用開始手続き詳細",
    icon: <PeopleAltOutlined />,
    exact: true,
    showBreadcrumbs: true,
    path: "/subscribers/invites/:inviteId",
    element: <InviteDetails />,
  },
  {
    title: "メンバー",
    icon: <Groups2OutlinedIcon />,
    exact: true,
    path: "/members",
    showBreadcrumbs: true,
    element: <Members />,
  },
  {
    title: "メンバー詳細",
    icon: <></>,
    exact: true,
    showBreadcrumbs: true,
    path: "/members/:id",
    element: <Member />,
  },
  /* 販売代理店は現状保留のためコメントアウト */
  /*{
    title: "販売代理店管理",
    shortTitle: "ホーム",
    icon: <SupervisorAccountOutlined />,
    exact: true,
    path: "/admin",
    component: () => <Agent />,
  },*/
];
/**
 * サインメニューに表示するルート情報を取得します。
 * @param {array} routes 元ルート情報配列
 * @returns {array} ルート情報配列
 */
export const toSideMenu = (routes) => {
  return routes
    .filter((route) => route.title !== "ページが見つかりませんでした")
    .filter((route) => route.path.match(/\//g).length === 1);
};
export default routes;
