import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { InputField } from "views/molecules";

/**
 * フィードバックの送信を行うプレゼンテーションコンポーネントです。
 * @param {object} props  プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <form id={props.id} className={props.classes.form} onSubmit={props.submit}>
      <Stack spacing={2}>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            className={props.classes.box_feedback}
            control={
              <Switch
                color="primary"
                checked={props.includeScreenshot}
                onChange={props.onChangeIncludeScreenshot}
              />
            }
            label="スクリーンショットを含める"
            labelPlacement="start"
          />
        </FormGroup>
        {props.isLoadingScreenshot && (
          <CircularProgress color="primary" size={30} />
        )}
        {props.image && (
          <img
            className={props.classes.box_screenshot_img}
            src={props.image}
            alt="screenshot"
          />
        )}
        <Controller
          name="contents"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="内容"
              placeholder="フィードバックの内容を入力します。"
              multiline
              rows={4}
              value={value}
              onChange={onChange}
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      </Stack>
    </form>
  );
};
