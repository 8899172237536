import { createTheme } from '@mui/material';
import { blue } from "@mui/material/colors";
import { overideStyleScrollbarVertical } from "utils/styles";

export const theme = createTheme({
    palette: {
      primary: { main: blue[700] },
    },
    typography: {
      fontFamily: `'Avenir','Helvetica Neue','Helvetica','Arial', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', sans-serif`,
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            ...overideStyleScrollbarVertical(),
          },
        },
      },
    },
});