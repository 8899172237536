import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  alert_message: {
    fontSize: "11px",
    alignItems: "center",
    "& .MuiAlert-icon": {
      fontSize: "18px",
    },
  },
}));
