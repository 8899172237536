import { useStyles } from "./styles";

/**
 * コンボボックスで選択項目を表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} label ラベル
 * @param {string} value 選択された値
 * @fires Container#onChange 変更時
 * @param {boolean} required 必須かどうか
 * @param {boolean} error エラーかどうか
 * @param {array} options 選択する値
 * @param {string} placeholder プレースホルダー
 * @param {string} helperText ヘルパーテキスト
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  label,
  value,
  onChange,
  required = false,
  error,
  options,
  placeholder = "未選択",
  helperText,
  ...props
}) => {
  const classes = useStyles();
  return render({
    classes: classes,
    label: label,
    value: value,
    onChange: onChange,
    required: required,
    error: error,
    options: options,
    placeholder: placeholder,
    helperText: helperText,
    ...props,
  });
};
