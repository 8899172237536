import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useStyles } from "./styles";
const defalultValue = {
  email: "",
  username: "",
};
/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useStyles();
  const methods = useForm({
    defaultValues: defalultValue,
  });
  const { control, reset } = methods;
  useEffect(() => {
    if (value) {
      reset({ ...defalultValue, ...value });
    }
  }, [reset, value]);
  return render({
    classes: classes,
    methods: methods,
    control: control,
    ...props,
  });
};
