import { Button, ThemeProvider, Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";
import { jaJP as jaJPCore } from "@mui/material/locale";
import {
  CloudDownloadOutlined,
  FilterListOutlined,
  ViewColumnOutlined,
} from "@mui/icons-material";
import {
  GridOverlay,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  jaJP,
  DataGridPro,
} from "@mui/x-data-grid-pro";
import clsx from "clsx";
import React from "react";
import { RenderSVG } from "views/atoms";
import { TextSearcher } from "views/molecules";
import CustomGridPanel from "./CustomGridPanel";

const defaultTheme = createTheme(
  {
    palette: {
      primary: { main: blue[700] },
    },
    typography: {
      fontFamily: `'Avenir','Helvetica Neue','Helvetica','Arial', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', sans-serif`,
    },
  },
  jaJP,
  jaJPCore
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
  },
  label: {
    //marginTop: theme.spacing(1),
  },
}));

const CustomToolbar = (props) => {
  return (
    <div className={props.classes.header}>
      <Typography variant="h6">{props?.titleTable}</Typography>
      <GridToolbarContainer className={props.classes.toolbar_container}>
        <div className={props.classes.header_left}>
          {/* <div
            className={clsx(props.classes.header_search, "guide-searcher-area")}
          >
            <TextSearcher onSearch={props.onSearchByText} />
          </div> */}
          {props.onColumns && (
            <GridToolbarColumnsButton
              variant="outlined"
              color="default"
              startIcon={<ViewColumnOutlined />}
              className={clsx(
                props.classes.header_button,
                "guide-columns-button"
              )}
            />
          )}

          {props.onFilter && (
            <GridToolbarFilterButton
              variant="outlined"
              color="default"
              startIcon={<FilterListOutlined />}
              className={clsx(
                props.classes.header_button,
                props.classes.header_button_filter,
                "guide-filter-button"
              )}
            />
          )}

          {props.onDownload && (
            <GridToolbarExport
              variant="outlined"
              csvOptions={{
                utf8WithBom: true,
              }}
              printOptions={{
                disableToolbarButton: true,
              }}
              startIcon={<CloudDownloadOutlined />}
              className={clsx(
                props.classes.header_button,
                props.classes.header_button_filter,
                "guiderXGridExport"
              )}
            />
          )}
        </div>
        <div className={props.classes.header_right}>{props?.actionButton}</div>
      </GridToolbarContainer>
    </div>
  );
};

const CustomNoRowsOverlay = () => {
  const classes = useStyles();
  return (
    <GridOverlay className={classes.root}>
      <RenderSVG />
      <div className={classes.label}>行がありません</div>
    </GridOverlay>
  );
};

const CustomFooterButton = (props) => {
  return (
    <div className={props.classes.footer_button}>
      <Button
        variant="contained"
        color="secondary"
        onClick={props.onCloseModal}
      >
        キャンセル
      </Button>
      <Button variant="contained" color="primary" onClick={props.onSubmit}>
        変更
      </Button>
    </div>
  );
};

/**
 * 事業場の選択を表示するコンポーネントです。
 * @param {object} classes クラス名
 * @param {array} columns テーブル列
 * @param {array} rows　テーブル行
 * @param {boolean} onToolbar ツールバーが隠れるようにする
 * @param {object} actionButton 別のボタンが表示　例：新しい事業所。事業場を登録
 * @param {func} onClickOpenDialog ダイヤログを開くイベントです。
 * @param {boolean} multipleSelection 複数行を選択する可能性が無効化有効にする
 * @param {func} onCloseModal モーダルを閉じるイベントです
 * @param {func} onSubmit 変更ボタンを押す時のイベントです
 * @param {array} selectionModel 選択された行
 * @param {number} pageSize ページに表示する行数を設定する
 * @param {number} onPageSizeChange ページに表示する行が変更された時のイベントです
 * @param {number} onChangeSelectionModel　選択された行を取得する
 * @param {boolean} checkboxSelection 行頭にチェックボックスを表示する
 * @param {string} titleTable テーブルのタイトル
 * @param {boolean} onDownload ダウンロードのボタンを有無に設定する
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div>
      <DataGridPro
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        className={clsx(
          props.classes.content_table,
          props.multipleSelection === false
            ? props.classes.hidenCheckBoxHeader
            : ""
        )}
        //カラム
        columns={props.columns}
        rows={props.rows}
        //行の高さを自動的に調整する
        autoHeight={true}
        //XGridのコンポーネントです
        components={{
          Toolbar: props.onToolbar && CustomToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
          Panel: CustomGridPanel,
        }}
        onFilterModelChange={props.onFilterModelChange}
        //XGridのコンポーネントに引数を渡す
        componentsProps={{
          toolbar: {
            classes: props.classes,
            onClickOpenDialog: props.onClickOpenDialog,
            actionButton: props.actionButton,
            titleTable: props.titleTable,
            onColumns: props.onColumns,
            onFilter: props.onFilter,
            onDownload: props.onDownload,
            onSearchByText: props.onSearchByText,
          },
        }}
        //ヘッダーの高さ
        headerHeight={48}
        //ページに表示する行数を設定する
        pageSize={props.pageSize}
        //ページに表示する行数が変更された時のイベントです
        onPageSizeChange={props.onPageSizeChange}
        onPageChange={props.onPageChange}
        page={props.page}
        rowCount={props.rowCount}
        //配列行数
        rowsPerPageOptions={[10, 20, 50, 100]}
        //ページ付け
        pagination
        //行頭にチェックボックスを表示する
        checkboxSelection={props.checkboxSelection}
        //選択された行
        selectionModel={props.selectionModel}
        //選択された行数を非表示に設定する
        hideFooterSelectedRowCount
        //行を選択する時のイベントです
        onSelectionModelChange={(selection) => {
          // const newSelectionModel = selection.selectionModel;
          const newSelectionModel = selection;

          if (
            newSelectionModel?.length > 1 &&
            props.multipleSelection === false
          ) {
            const selectionSet = new Set(props.selectionModel);
            const result = newSelectionModel.filter(
              (s) => !selectionSet.has(s)
            );

            props.onChangeSelectionModel(result);
          } else {
            props.onChangeSelectionModel(newSelectionModel);
          }
        }}
        //複数行選択を無効にする
        disableMultipleSelection={true}
        //マウスポインターを変える
        getCellClassName={() =>
          `${props.disableSelectionOnClick ? "" : props.classes.onCursor}`
        }
        //カラムの幅の調整を無効にする
        disableColumnResize={true}
        paginationMode={props.paginationMode}
        sortModel={props.sortModel}
        onSortModelChange={props.onSortModelChange}
        {...props}
      />
      {props.multipleSelection === true ? (
        <CustomFooterButton
          classes={props.classes}
          onSubmit={props.onSubmit}
          onCloseModal={props.onCloseModal}
        />
      ) : (
        ""
      )}
    </div>
  );
};
