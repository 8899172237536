import { makeStyles } from "@mui/styles";
import { blue, green, grey } from "@mui/material/colors";

export const useStyles = makeStyles((theme) => ({
  box_data_infor: {
    display: "flex",
    alignItems: "stretch",
    borderBottom: "1px solid",
    borderBottomColor: grey[300],
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  box_data_label: {
    minWidth: "230px",
    backgroundColor: grey[300],
    textAlign: "center",
    padding: "10px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  group_avatar: {
    display: "flex",
    padding: "4px 40px",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    "& .blue": {
      backgroundColor: blue[500],
    },
    "& .greener": {
      backgroundColor: green[600],
    },
  },
  item_avatar: {
    fontSize: "13px",
    border: "none",
    marginLeft: "-4px",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));
