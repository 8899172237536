import { grey } from "@mui/material/colors";

//スクロールのスタイルが置き換えスタイルです。
export const overideStyleScrollbarVertical = (theme) => ({
  "&::-webkit-scrollbar": {
    width: "8px",
  },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:vertical ": {
    backgroundColor: "rgba(0, 0, 0, 0.18)",
    borderRadius: "4px",
  },

  "&::-webkit-scrollbar-thumb:horizontal ": {
    display: "none",
  },
  "&::-webkit-scrollbar-thumb:vertical": {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
  },
});
export const overideStyleScrollbarHorizontal = (theme) => ({
  "&::-webkit-scrollbar": {
    height: "8px",
  },

  "&::-webkit-scrollbar-track": {
    //"-webkit-box-shadow": " inset 0 0 6px rgba(0,0,0,0.3)",
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:horizontal ": {
    backgroundColor: "rgba(0, 0, 0, 0.18)",
    borderRadius: "4px",
  },

  "&::-webkit-scrollbar-thumb:vertical ": {
    display: "none",
  },
  "&::-webkit-scrollbar-thumb:horizontal": {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
  },
});

export const overideStyleButton = (theme) => ({
  "&.MuiButtonBase-root": {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
});
