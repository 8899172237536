import { yupResolver } from "@hookform/resolvers/yup";
import { ConfirmStepGetData } from "ducks/Signup/slice";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { ConfirmSchema } from "./validations";
const defaultStep3 = {
  plan: ["plan01"],
  businessName: "",
  industry: "",
  postCode: "",
  prefectures: "",
  city: "",
  streetAddress: "",
  otherAddress: "",
  phone: "",
  fax: "",
  email: "",
};
/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */

export const Container = ({ render, activeStep, onNextStep, ...props }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onChange",
    defaultValues: defaultStep3,
    resolver: yupResolver(ConfirmSchema),
  });

  const { trigger, getValues, watch } = methods;
  const handleClickButtonConfirm = async () => {
    const result = await trigger();
    if (result) {
      const data = getValues();
      dispatch(ConfirmStepGetData(data));
      onNextStep && onNextStep();
    }
  };

  const isValid =
    watch("plan").length >= 1 &&
    watch("businessName").length > 1 &&
    watch("industry").length > 1 &&
    watch("postCode").length > 1 &&
    watch("prefectures").length > 1 &&
    watch("city").length > 1 &&
    watch("streetAddress").length > 1 &&
    watch("otherAddress").length > 1;

  return render({
    classes: classes,
    isValid: isValid,
    activeStep: activeStep,
    onClickButton: handleClickButtonConfirm,
    methods: methods,
    ...props,
  });
};
