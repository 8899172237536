import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { toQueryString } from "utils/useXGridComponents";

// todo: apiのコール

/**
 * 加入者管理画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  console.log(`/subscribe/`);

  const onTransiteSubscriberDetails = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: `${createSearchParams({
          ...Object.fromEntries(new URLSearchParams(location.search)),
          ...Object.fromEntries(
            new URLSearchParams(toQueryString(params.info.search))
          ),
        }).toString()}`,
      },
      {
        replace: true,
      }
    );
    console.log(params.value[0].id)
    navigate(`subscribe/${params.value[0].id}`);
  };

  const onTransiteInviteDetails = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(params?.info?.search),
      },
      {
        replace: true,
      }
    );
    navigate(`${location.pathname}/invites/${params.value[0].id}`);
  };

  return render({
    onTransiteSubscriberDetails: onTransiteSubscriberDetails,
    onTransiteInviteDetails: onTransiteInviteDetails,
    ...props,
  });
};
