import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import clsx from "clsx";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  workerSrc: "/pdf.worker.js",
};

/**
 * PDFファイルの表示を行うプレゼンテーションコンポーネントです。
 * @returns {JSX Element}
 */
export const Presententer = (props) => {
  const {
    classes,
    checked,
    hideCheck,
    checkedName,
    onChangeChecked,
    labelCheckbox,
    files,
    numPages,
    onDocumentLoadSuccess,
    onScroll,
    listInnerRef,
  } = props;

  return (
    <div>
      <div
        className={classes.pdf_view_page}
        onScroll={onScroll}
        ref={listInnerRef}
      >
        <Document
          file={files}
          options={options}
          loading={
            <div className={clsx(classes.loading_progress, classes.onLoading)}>
              <CircularProgress thickness={4} size={60} />
            </div>
          }
          noData={
            <div className={clsx(classes.loading_progress, classes.noData)}>
              <CircularProgress thickness={4} size={60} />
            </div>
          }
          onLoadSuccess={onDocumentLoadSuccess}
          className={classes.view_file}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={900}
            />
          ))}
        </Document>
      </div>

      <div className={classes.pdf_checkbox}>
        <FormControlLabel
          label={labelCheckbox}
          control={
            <Checkbox
              name={checkedName}
              value={checked}
              onChange={onChangeChecked}
              disabled={hideCheck}
            />
          }
        />
      </div>
    </div>
  );
};
