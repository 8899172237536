import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Snackbar,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { If } from "views/atoms";

/**
 * URLを表示するダイアログコンポーネント。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @param {boolean} props.loading 読み込み中
 * @param {string} props.value url
 * @fires URLDialog#onClose 閉じる時
 * @returns {JSX.Element}
 */
export const URLDialog = ({
  open = false,
  loading = false,
  value = null,
  onClose = () => {},
}) => {
  const [alert, setAlert] = useState(false);

  const copy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value);
      setAlert(true);
    } else if (window.clipboardData) {
      window.clipboardData.setData("text/plain", value);
      setAlert(true);
    } else if (document.execCommand) {
      let area = document.createElement("textarea");
      area.textContent = value;
      area.select();
      document.body.appendChild(area);
      document.execCommand("copy");
      document.body.removeChild(area);
      setAlert(true);
    }
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>利用手続きURL</DialogTitle>
        <If condition={loading === true}>
          <LinearProgress />
        </If>
        <If condition={loading === false}>
          <DialogContent>
            <Stack spacing={2}>
              <Stack>{value}</Stack>
              <Stack>
                <Button onClick={copy} variant="outlined" color="success">
                  コピー
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>閉じる</Button>
          </DialogActions>
        </If>
      </Dialog>
      <Snackbar
        open={alert}
        autoHideDuration={6000}
        onClose={() => setAlert(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setAlert(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          URLをコピーしました。
        </Alert>
      </Snackbar>
    </>
  );
};
