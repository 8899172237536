import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * テキストボックスを表示するコンポーネントです
 * @param {string} label ラベル
 * @param {string} value 値
 * @returns {JSX Element}
 */
export const RenderText = containerPresententer(Container, Presententer);
