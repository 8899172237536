import {
  AppBar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { forwardRef } from "react";

export const TRANSITION = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * フルスクリーンダイアログを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Dialog
    fullScreen
    open={props?.open}
    TransitionComponent={TRANSITION}
    PaperProps={{
      style: {
        backgroundColor: "#eaeaf0",
      },
    }}
  >
    <AppBar
      position="fixed"
      color="primary"
      className={props.classes.appBar}
      elevation={0}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props?.onClose}
          aria-label="close"
          disabled={props?.disabled || props?.isSubmit}
        >
          <Close />
        </IconButton>
        <Typography variant="h6" className={props?.classes.title}>
          {props?.title}
        </Typography>
        {props?.action}
        <Button
          form={props.formId}
          variant="outlined"
          autoFocus
          color="inherit"
          type="submit"
          onClick={props.onSubmit}
          disabled={props?.disabled || props?.isSubmit}
          size="large"
        >
          {props?.isSubmit ? (
            <CircularProgress color="inherit" size={24} />
          ) : null}
          {props?.textConfirm}
        </Button>
      </Toolbar>
    </AppBar>
    <div className={props.classes.appBarSpacer} />
    <Container maxWidth="md" className={props.classes.container}>
      <Paper elevation={3}>
        <div className={props.classes.form}>{props.children}</div>
      </Paper>
    </Container>
  </Dialog>
);
