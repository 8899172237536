import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

export const useStyles = makeStyles((theme) => ({
  title_service: {
    color: grey[700],
    fontSize: "14px",
    marginBottom: theme.spacing(1),
  },
  switch: {
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    margin: "0",
    marginBottom: theme.spacing(2),
    "&.Mui-disabled .MuiSwitch-root": {
      opacity: "0.4"
    },
    "& .MuiSwitch-colorPrimary.Mui-checked": {
      color: theme.palette.primary.main,
    },
    "& .MuiSwitch-colorPrimary.Mui-disabled": {
      color: theme.palette.primary.main,
    },
    "& .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      opacity: "0.5 !important ",
    },
  }
}));
