import { styles } from "./styles";

/**
 * テキストフィルドの表示を行うコンテナコンポーネントです。
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const classes = styles();
  return render({
    classes: classes,
    ...props,
  });
};
