const defaultValue = {
  sendingStatusList: [],
  subscribers: [],
};

/**
 * 加入者管理の表示を行うコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onTransiteInviteDetails 送付状況詳細画面遷移
 * @fires Container#onTransiteSubscriberDetails 加入者詳細画面遷移
 * @param {object} props その他プロパティ
 * @callback render
 */
export const Container = ({
  render,
  value,
  onSended,
  onTransiteInviteDetails,
  onTransiteSubscriberDetails,
  ...props
}) => {
  return render({
    value: {
      ...defaultValue,
      ...value,
    },
    onSended: onSended,
    onTransiteInviteDetails: onTransiteInviteDetails,
    onTransiteSubscriberDetails: onTransiteSubscriberDetails,
    ...props,
  });
};
