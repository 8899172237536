import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 新しいユーザーの登録を表示するコンポーネントです。
 * @param {object}　value ユーザーのメールアドレス
 * @param {func}　onBack 確認段階に戻る
 */
export const EmailRegister = containerPresententer(Container, Presententer);
