import React from "react";
import { Link as LinkMateial, Typography } from "@mui/material";

const Presententer = ({ providerName, systemUrl }) => (
  <Typography variant="caption" align="center">
    {"© "}
    <LinkMateial color="inherit" href={systemUrl}>
      {` ${providerName} `}
    </LinkMateial>
    {"All Right Reserved."}
  </Typography>
);

export default Presententer;
