import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  header_tabs: {
    [theme.breakpoints.up("sm")]: {
      "& .MuiTab-root": {
        minWidth: "100px",
      },
    },
  },

  tab_main_content: {
    backgroundColor: theme.palette.background.paper,
    padding: "24px 40px",
  },
  button_tab: {
    fontSize : "16px"
  },
}));
