import { Box, Button, Grid } from "@mui/material";
import { Controller, FormProvider } from "react-hook-form";
import { RenderTitleInPopupOrModal } from "views/atoms";
import { InputField } from "views/molecules";

/**
 * アメールあドレスの確認の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} classes クラス名
 *
 * @param {object} methods useFormのmethods
 * @param {object} control useFormのcontrol
 * @param {object} errors useFormのerrors
 *
 * @param {func} onConfirmEmail 確認ボタンをクリックする時のイベント
 * @returns JSX Element
 */
export const Presententer = (props) => (
  <>
    <RenderTitleInPopupOrModal
      mainTitle="アカウント登録"
      subTitle={["メールアドレスを入力して確認ボタンをクリックしてください。"]}
    />
    <Box mt={3}>
      <FormProvider {...props.methods}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Controller
              control={props.control}
              name="email"
              render={({ field: { value, onChange } }) => (
                <InputField
                  label="メールアドレス"
                  placeholder="例）info@ecope-pro.co.jp"
                  required={true}
                  value={value}
                  onChange={onChange}
                  error={props.errors?.email}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onConfirmEmail}
              className={props.classes.button_sendmail}
            >
              確認
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  </>
);
