/**
 * メッセージボックスの表示を行うコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} title タイトル
 * @param {string} description 説明
 * @param {JSX.Element} action 行動要素
 * @param {bool} open 開閉状態
 * @fires Container#onClose 閉じるときの処理
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  title = "",
  description = "",
  action,
  open = false,
  onClose,
  ...props
}) => {
  return render({
    title: title,
    description: description,
    action: action,
    open: open,
    onClose: onClose,
    ...props,
  });
};
