import { useReducer } from "react";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import { debugLog } from "utils/log";

const initialState = {
  loading: false,
  delivery: false,
  error: null,
};

const actions = {
  VERIFY_SUCCEECED: "VerifyContact/verify/succeeded",
  VERIFY_FAILED: "VerifyContact/verify/failed",
  SUBMIT_BEGIN: "VerifyContact/submit/begin",
  SUBMIT_SUCCEEDED: "VerifyContact/submit/succeeded",
  SUBMIT_FAILED: "VerifyContact/submit/failed",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.VERIFY_SUCCEECED:
      return {
        ...state,
        delivery: true,
      };
    case actions.VERIFY_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case actions.SUBMIT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case actions.SUBMIT_SUCCEEDED:
      return {
        ...state,
        delivery: false,
        loading: false,
      };
    case actions.SUBMIT_FAILED:
      return {
        ...state,
        loading: false,
        locked: action.payload?.locked ?? false,
        error: action.payload.error,
      };
    default:
      break;
  }
};

const logger = new Logger("VerifyContact");

/**
 * 有効な認証状態
 */
const VALID_AUTO_STATES = ["verifyContact"];

/**
 * 連絡先確認画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const verify = () => {
    // 電話番号を確認に使用しないのでメール固定
    Auth.verifyCurrentUserAttribute("email")
      .then((data) => {
        logger.debug(data);
        dispatch({
          type: actions.VERIFY_SUCCEECED,
        });
      })
      .catch((err) => {
        logger.debug(err);
        dispatch({
          type: actions.VERIFY_FAILED,
          payload: "エラーが発生しました。",
        });
      });
  };

  const submit = (code) => {
    dispatch({ type: actions.SUBMIT_BEGIN });
    const user = props.authData;
    // 電話番号を確認に使用しないのでメール固定
    Auth.verifyCurrentUserAttributeSubmit("email", code)
      .then((data) => {
        logger.debug(data);
        dispatch({
          type: actions.SUBMIT_SUCCEEDED,
        });
        props.onStateChange("signedIn", user);
      })
      .catch((err) => {
        switch (err.code) {
          case "CodeMismatchException":
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "入力されたコードに誤りがあります。",
              },
            });
            break;
          case "LimitExceededException":
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "入力規定回数を超えました。",
              },
            });
            break;
          case "ExpiredCodeException":
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "入力されたコードの有効期限が切れています。",
                delivery: false,
              },
            });
            break;
          default:
            logger.debug(err);
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "エラーが発生しました。",
              },
            });
            break;
        }
      });
  };

  return render({
    onTransiteLogin: () => props.onStateChange("signIn"),
    onVerify: verify,
    onSubmit: submit,
    onError: debugLog,
    delivery: state.delivery,
    loading: state.loading,
    isValid: VALID_AUTO_STATES.includes(props.authState),
    ...props,
  });
};
