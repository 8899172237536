/**
 * 利用開始手続き詳細の表示を行うコンテナコンポーネントです
 * @param {object} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  return render({
    ...props,
  });
};
