import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { NameForm } from "./NameForm";
import { EMailForm } from "./EMailForm";
import { PhoneForm } from "./PhoneForm";
import { BelongForm } from "./BelongForm";
import { PositionForm } from "./PositionForm";
import { CompanyNameForm } from "./CompanyNameForm";
import { useMutation } from "utils/TemporaryModules";

/**
 * ユーザープロフィールを設定する画面を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const UserProfile = ({ value, loading = false }) => {
  const [nameForm, setNameForm] = useState(false);
  const [companyNameForm, setCompanyNameForm] = useState(false);
  const [emailForm, setEMailForm] = useState(false);
  const [belongForm, setBelongForm] = useState(false);
  const [phoneForm, setPhoneForm] = useState(false);
  const [positionForm, setPositionForm] = useState(false);

  const [updateNameMutation, updateNameMutationStatus] = useMutation(
    "ミューテーション",
    {
      onCompleted: (params) => {
        setNameForm(false);
      },
      onError: (error) => {},
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新に失敗しました。",
    }
  );

  const [updateCompanyNameMutation, updateCompanyNameMutationStatus] =
    useMutation("ミューテーション", {
      onCompleted: (params) => {
        setCompanyNameForm(false);
      },
      onError: (error) => {},
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新に失敗しました。",
    });

  const [updateEmailMutation, updateEmailMutationStatus] = useMutation(
    "ミューテーション",
    {
      onCompleted: (params) => {
        setEMailForm(false);
      },
      onError: (error) => {},
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新に失敗しました。",
    }
  );

  const [updateBelongMutation, updateBelongMutationStatus] = useMutation(
    "ミューテーション",
    {
      onCompleted: (params) => {
        setBelongForm(false);
      },
      onError: (error) => {},
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新に失敗しました。",
    }
  );

  const [updatePhoneMutation, updatePhoneMutationStatus] = useMutation(
    "ミューテーション",
    {
      onCompleted: (params) => {
        setPhoneForm(false);
      },
      onError: (error) => {},
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新に失敗しました。",
    }
  );

  const [updatePositionMutation, updatePositionMutationStatus] = useMutation(
    "ミューテーション",
    {
      onCompleted: (params) => {
        setPositionForm(false);
      },
      onError: (error) => {},
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新に失敗しました。",
    }
  );

  if (loading === true) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={270} />
        <Skeleton variant="rectangular" height={190} />
      </Stack>
    );
  }

  return (
    <>
      <Stack spacing={2}>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {"基本情報"}
          </Typography>
          <Typography
            sx={{
              color: "gray",
              fontSize: "14px",
            }}
          >
            {
              "一部の情報はサービスを利用する他のユーザーに表示される場合があります。"
            }
          </Typography>
          <List>
            <ListItem
              onClick={() => setNameForm(true)}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <Box display={"flex"} flexDirection="row">
                  <ListItemText
                    primary={`名前`}
                    sx={{
                      width: 300,
                    }}
                  />
                  <ListItemText primary={`${value?.name ?? ""}`} />
                </Box>
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => setCompanyNameForm(true)}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <Box display={"flex"} flexDirection="row">
                  <ListItemText
                    primary={`組織名`}
                    sx={{
                      width: 300,
                    }}
                  />
                  <ListItemText primary={`${value?.companyName ?? ""}`} />
                </Box>
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => setBelongForm(true)}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <Box display={"flex"} flexDirection="row">
                  <ListItemText
                    primary={`所属`}
                    sx={{
                      width: 300,
                    }}
                  />
                  <ListItemText primary={`${value?.belongName ?? ""}`} />
                </Box>
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => setPositionForm(true)}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <Box display={"flex"} flexDirection="row">
                  <ListItemText
                    primary={`役職`}
                    sx={{
                      width: 300,
                    }}
                  />
                  <ListItemText primary={`${value?.position ?? ""}`} />
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {"連絡先情報"}
          </Typography>
          <List>
            <ListItem
              onClick={() => setEMailForm(true)}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <Box display={"flex"} flexDirection="row">
                  <ListItemText
                    primary={`メール`}
                    sx={{
                      width: 300,
                    }}
                  />
                  <ListItemText primary={`${value?.email ?? ""}`} />
                </Box>
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => setPhoneForm(true)}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <Box display={"flex"} flexDirection="row">
                  <ListItemText
                    primary={`電話`}
                    sx={{
                      width: 300,
                    }}
                  />
                  <ListItemText primary={`${value?.phone ?? ""}`} />
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
      </Stack>
      <NameForm
        open={nameForm}
        onSubmit={(response) => {
          updateNameMutation({
            ...response,
          });
        }}
        disabled={updateNameMutationStatus.loading}
        onClose={() => setNameForm(false)}
        value={value?.name}
      />
      <CompanyNameForm
        open={companyNameForm}
        onSubmit={(response) => {
          updateCompanyNameMutation({
            ...response,
          });
        }}
        disabled={updateCompanyNameMutationStatus.loading}
        onClose={() => setCompanyNameForm(false)}
        value={value?.companyName}
      />
      <BelongForm
        open={belongForm}
        onSubmit={(response) => {
          updateBelongMutation({
            ...response,
          });
        }}
        disabled={updateBelongMutationStatus.loading}
        onClose={() => setBelongForm(false)}
        value={value?.belongName}
      />
      <PositionForm
        open={positionForm}
        onSubmit={(response) => {
          updatePositionMutation({
            ...response,
          });
        }}
        disabled={updatePositionMutationStatus.loading}
        onClose={() => setPositionForm(false)}
        value={value?.position}
      />
      <EMailForm
        open={emailForm}
        onSubmit={(response) => {
          updateEmailMutation({
            ...response,
          });
        }}
        disabled={updateEmailMutationStatus.loading}
        onClose={() => setEMailForm(false)}
        value={value?.email}
      />
      <PhoneForm
        open={phoneForm}
        onSubmit={(response) => {
          updatePhoneMutation({
            ...response,
          });
        }}
        disabled={updatePhoneMutationStatus.loading}
        onClose={() => setPhoneForm(false)}
        value={value?.phone}
      />
    </>
  );
};
