import { useState } from "react";
import {
  ConfirmStep,
  FirstStep,
  SecondStep,
  SuccessStep,
} from "views/organisms/Signup";

/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return <FirstStep activeStep={activeStep} onNextStep={handleNext} />;
      case 1:
        return <SecondStep activeStep={activeStep} onNextStep={handleNext} />;
      case 2:
        return <ConfirmStep activeStep={activeStep} onNextStep={handleNext} />;
      case 3:
        return <SuccessStep activeStep={activeStep} />;
      default:
        break;
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  return render({
    activeStep: activeStep,
    handleSteps: handleSteps,
    ...props,
  });
};
