import { styles } from "./styles";

/**
 * プランを選択するコンテナコンポーネントです。
 * @param {func} render 引数をうけてJSX Elemntを返します
 * @param {string} label セレクションのラベル
 * @param {string} placeholder プレースホルダー
 * @param {boolean} required 必須かどうかを設定する
 * @param {boolean} disabled 有効無効設定
 * @param {object} value 選択されたアイテムです
 * @fires onChange 選択するアイテムが更新するイベントです
 * @param {array} options 選択項目
 * @param {boolean} error エラー表示
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  label,
  required = true,
  disabled = false,
  value,
  onChange,
  options = [],
  placeholder,
  error,
  ...props
}) => {
  const classes = styles();
  const handleChange = (event) => {
    onChange && onChange(event.target.value);
  };

  return render({
    classes: classes,
    label: label,
    placeholder: placeholder,
    required: required,
    value: value,
    onChange: handleChange,
    options: options,
    error: error,
    disabled: disabled,
    ...props,
  });
};
