import { useForm } from "react-hook-form";
import { useStyles } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { DateTime } from "luxon";
import { basic } from "./Validations/basic";
import { debugLog } from "utils/log";
import { ContactProgressStatus } from "utils/contexts/ContactProgressStatus";

const defaultValues = {
  company: "",
  contactMethods: "",
  contactTime: DateTime.fromJSDate(new Date()).toFormat("yyyy/MM/dd hh:mm a"),
  contactType: "",
  email: "",
  phoneNumber: "",
  remarks: "",
  representativeName: "",
  supportState: "",
};

const supportMethodsType = ["電話", "Web申込", "Webお問い合わせ"];

const supportType = ["加入申請", "資料請求", "その他"];

/**
 * お問い合わせを追加するフォームを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} id フォームのid
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  id = "contactForm",
  value,
  onSubmit = (e) => debugLog(e),
  onError = (e) => debugLog(e),
  ...props
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basic),
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
    });
  }, [reset, value]);

  return render({
    id: id,
    classes: classes,
    control: control,
    errors: errors,
    supportMethodsType: supportMethodsType,
    contactProgressStatus: ContactProgressStatus,
    supportType: supportType,
    submit: handleSubmit(onSubmit, onError),
    ...props,
  });
};
