import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useScreenshot } from "use-screenshot-hook";
import { debugLog } from "utils/log";
import useAsyncEffect from "utils/useAsyncEffect";
import { useStyles } from "./styles";
import { basic } from "./Validations/basic";

const defaultValues = {
  screenshot: null,
  contents: '',
};

/**
 * フィードバックの送信を行うコンテナコンポーネントです。
 * @param {JSX.Element} render 引数を受けて、JSX.Elementを返すメソッド
 * @fires Container#onCancel キャンセル時
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  id = "feedback-form",
  onSubmit = (data) => debugLog(data),
  onError = (error) => debugLog(error),
  onCancel = () => {},
  ...props
}) => {
  const classes = useStyles();
  const [includeScreenshot, setIncludeScreenshot] = useState(true);
  const { control, handleSubmit, setValue } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
    resolver: yupResolver(basic),
  });
  const { image, takeScreenshot, clear, isLoading } = useScreenshot();

  useAsyncEffect(
    async () => {
      if (includeScreenshot) {
        setValue("screenshot", await takeScreenshot());
      } else {
        clear();
        setValue("screenshot", null);
      }
    },
    null,
    [includeScreenshot]
  );

  return render({
    id: id,
    submit: handleSubmit(onSubmit, onError),
    classes: classes,
    control: control,
    onCancel: onCancel,
    image: image,
    isLoadingScreenshot: isLoading,
    includeScreenshot: includeScreenshot,
    onChangeIncludeScreenshot: (e) => setIncludeScreenshot(e.target.checked),
    ...props,
  });
};
