import { useState } from "react";
import { debugLog } from "utils/log";
import { useStyles } from "./styles";

/**
 * 加入者詳細を表示するプレゼンテーションコンポーネントです。
 * @param {func} 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSubmitPlan 変更プランの送信時処理
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmitPlan = (data) => debugLog(data),
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return render({
    classes: classes,
    value: value,
    open: open,
    onOpenModal: () => setOpen(true),
    onCloseModal: () => setOpen(false),
    onSubmitPlan: onSubmitPlan,
    ...props,
  });
};
