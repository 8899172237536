import { DatePicker } from "@mui/x-date-pickers-pro";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import jaLocale from "date-fns/locale/ja";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

/**
 * 日付の選択の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 */
export const Presententer = (props) => (
  <LocalizationProvider utils={DateFnsUtils} locale={jaLocale} {...props}>
    <div className={props.classes.label}>
      {props.label}
      {props.required ? (
        <span className={props.classes.required}>必須</span>
      ) : (
        ""
      )}
    </div>
    <DatePicker
      size="small"
      autoOk
      InputLabelProps={{ shrink: true }}
      inputVariant="outlined"
      variant="inline"
      format="yyyy/MM/dd"
      placeholder={props.placeholder}
      fullWidth={props.fullWidth}
      value={props.date}
      InputAdornmentProps={{ position: "end" }}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
      className={props.classes.style_inputField}
      error={Boolean(props.error)}
      onChange={props.onChange}
      helperText={props.helperText}
    />
  </LocalizationProvider>
);
