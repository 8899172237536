import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Chip } from "@mui/material";

/**
 * 加入中Chipを表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Subscribed = () => {
  return (
    <Chip
      icon={<AddCircleOutlineOutlinedIcon />}
      label={"加入中"}
      color="info"
    />
  );
};
