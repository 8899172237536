import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Tag } from "views/atoms";
import { DetailsPageFrame } from "views/organisms/DetailsPageFrame";
import { Details } from "features/Invites/Details";
// import { Details, ResendEmailModal } from "views/organisms/InviteDetails";
// import { ProcedureStatus } from "views/organisms/Subscribers/TabInvites/Container";

/**
 * 加入者の詳細の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return <Details />;
};
