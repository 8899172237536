import { AppBar, Box, Tab, Tabs } from "@mui/material";
import React from "react";
//import { TabGallery, TabSimpleInformation } from "./ChildComponents";
const TabPanel = (props) => {
  const { children, value, index, classes, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tab_main_content}>{children}</Box>
      )}
    </div>
  );
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
/**
 * 車輛情報タブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @param {object} value 事業場情報
 *
 * @param {object} classes クラス名
 * @param {number} tabNumber タブ
 * @param {func} onChangeTab タブを更新する時のイベント
 * @returns
 */
export const Presententer = (props) => (
  <div>
    <AppBar position="static" color="transparent" elevation={0}>
      <Tabs
        value={props.tabNumber}
        onChange={props.onChangeTab}
        aria-label="workplace tab details"
        indicatorColor="primary"
        textColor="primary"
        className={props.classes.header_tabs}
      >
        {props.tabs.map((tab, index) => (
          <Tab
            className={props.classes.button_tab}
            key={index}
            label={tab.name}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </AppBar>
    {props.tabs.map((tab, index) => (
      <TabPanel
        key={index}
        value={props.tabNumber}
        index={index}
        classes={props.classes}
      >
        {tab.tabContent}
      </TabPanel>
    ))}
  </div>
);
