/**
 * テキストボックスの表示を行うプレゼンテーションコンポーネントです
 * @param {object} classes クラス名
 * @param {string} label ラベル
 * @param {string} value 値
 * @returns {JSX Element}
 */
export const Presententer = (props) => {
  return (
    <div className={props.classes.box_data_infor}>
      <div className={props.classes.box_data_label}>{props.label}</div>
      <div className={props.classes.box_data_value}>{props.value}</div>
    </div>
  );
};
