import { debugLog } from "utils/log";
import { stylesCreator } from "./styles";

/**
 * フルスクリーンダイアログを表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {boolean} open 開閉状態
 * @param {string} textConfirm submitボタンのテキスト
 * @param {string} title ダイアログのタイトル
 * @fires Container#onSubmit submitボタンをクリックする時のイベントです。
 * @fires Container#onClose 閉じる時
 * @param {boolean} disabled 無効有効設定
 * @param {JSX.Element} action 送信ボタン以外の要素
 * @param {boolean} isSubmit 送信状態であるか
 * @param {string} formId submitボタンと対応するformのid
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  open = false,
  textConfirm = "登録",
  title = "",
  onSubmit = (data) => debugLog(data),
  onClose = () => {},
  children,
  disabled = false,
  action,
  isSubmit = false,
  formId = null,
}) => {
  const classes = stylesCreator();

  return render({
    formId: formId,
    classes: classes,
    open: open,
    onClose: onClose,
    onSubmit: onSubmit,
    textConfirm: textConfirm,
    title: title,
    action: action,
    isSubmit: isSubmit,
    disabled: disabled,
    children: children,
  });
};
