import { useStyles } from "./styles";

/**
 * プラン詳細を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value プラン詳細 
 * @returns {JSX.Element}
 */
export const Container = ({
    render,
    value,
}) => {
    const classes = useStyles();

    return render({
        value: value,
        classes: classes
    });
};