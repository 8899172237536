import * as yup from "yup";

export const RegisterSchema = yup.object().shape({
  passcode: yup.string().required().min(6),
  //.matches(/[a-zA-Z]/),
  username: yup.string().required().min(1),
  //.matches(/[a-zA-Z]/),
  password: yup.string().required().min(6),
  //.matches(/[a-zA-Z]/),
});
