import { useStyles } from "./styles";

/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({
  render,
  isValid = true,
  onClickButton,
  buttonText = "次へ",
  showButton = true,
  ...props
}) => {
  const classes = useStyles();

  return render({
    classes: classes,
    isValid: isValid,
    onClickButton: onClickButton,
    buttonText: buttonText,
    showButton: showButton,
    ...props,
  });
};
