import { deepOrange } from "@mui/material/colors";

/**
 * タグ表示を行うコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} fontSize フォントサイズ(デフォルト12px)
 * @param {string} margin マージン(デフォルト0 0 3px 0)
 * @param {string} color 文字色(デフォルト白)
 * @param {string} backgroundColor 背景色(デフォルトオレンジ)
 * @param {string} borderRadius 角の丸み(デフォルト4px)
 * @param {string} padding パディング(3px 6px)
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  fontSize = "12px",
  margin = "0 0 3px 0",
  color = "#fff",
  backgroundColor = deepOrange[500],
  borderRadius = "4px",
  padding = "3px 6px",
  style,
  ...props
}) => {
  return render({
    fontSize: fontSize,
    margin: margin,
    color: color,
    borderradius: borderRadius,
    backgroundcolor: backgroundColor,
    padding: padding,
    otherstyle: style,
    ...props,
  });
};
