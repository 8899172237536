import React from "react";
import { makeStyles } from "@mui/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { GridApiContext } from "@mui/x-data-grid-pro";

// This helper method will be exported by @material-ui/data-grid in the next version
export const isEscapeKey = (key) => key === "Escape";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: theme.zIndex.modal,
      "& input, & select": {
        boxSizing: "content-box",
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      minWidth: 300,
      minHeight: 64,
      maxHeight: 450,
      display: "flex",
    },
  }),
  { name: "CustomGridPanel" }
);

export default function CustomGridPanel(props) {
  const classes = useStyles();
  const { children, open } = props;
  const apiRef = React.useContext(GridApiContext);

  const handleClickAway = React.useCallback(() => {
    apiRef.current.hidePreferences();
  }, [apiRef]);

  const handleKeyDown = React.useCallback(
    (event) => {
      if (isEscapeKey(event.key)) {
        event.stopPropagation();
        apiRef.current.hidePreferences();
      }
    },
    [apiRef]
  );

  let anchorEl;
  if (apiRef.current && apiRef.current.columnHeadersElementRef?.current) {
    anchorEl = apiRef?.current.columnHeadersElementRef?.current;
  }

  if (!anchorEl) {
    return null;
  }

  return (
    <Popper
      placement="bottom-start"
      className={classes.root}
      open={open}
      anchorEl={anchorEl}
      modifiers={[{ name: "flip", enabled: false }]}
      disablePortal
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper
          className={classes.paper}
          elevation={8}
          onKeyDown={handleKeyDown}
        >
          {children ?? <></>}
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}
