import { FirstStepCheckedAll } from "ducks/Signup/slice";
import { DateTime } from "luxon";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({ render, activeStep, onNextStep, ...props }) => {
  const classes = useStyles();
  const [firstStepValue, setFirstStepValue] = useState({});
  const timeNow = DateTime.fromJSDate(new Date()).toFormat(
    "yyyy/MM/dd hh:mm a"
  );

  const dispatch = useDispatch();
  const handleChangeChecks = (value) => {
    setFirstStepValue({ ...firstStepValue, ...value });
    dispatch(
      FirstStepCheckedAll({
        value: value,
        checkTime: timeNow,
      })
    );
  };
  const handleClickButton = () => {
    onNextStep && onNextStep();
  };

  const isValid =
    firstStepValue?.privacyPolicy === true &&
    firstStepValue?.termsOfService === true;
  return render({
    classes: classes,
    isValid: isValid,
    onChecked: handleChangeChecks,
    activeStep: activeStep,
    onClickButton: handleClickButton,
    ...props,
  });
};
