import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  AppBar,
  Box,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { grey, indigo, amber } from "@mui/material/colors";
import React, { useState } from "react";
import { Security } from "./Security";
import { UserProfile } from "./UserProfile";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 300;

/**
 * アカウント管理を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @fires ManagementAccount#onClose 閉じる時
 * @returns {JSX.Element}
 */
export const ManagementAccount = ({ open, onClose }) => {
  const [current, setCurrent] = useState("userprofile");

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <AppBar
        color="inherit"
        elevation={1}
        sx={{
          position: "fixed",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {"アカウント管理"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        open={true}
        variant="persistent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
      >
        <Toolbar />
        <List>
          <ListItem
            button
            onClick={() => setCurrent("userprofile")}
            selected={current === "userprofile"}
            sx={{
              "&.Mui-selected": {
                backgroundColor: grey[200],
                borderLeft: `${amber[500]} solid 3px`,
              },
            }}
          >
            <ListItemIcon>
              <BadgeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="ユーザープロフィール" />
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => setCurrent("security")}
            selected={current === "security"}
            sx={{
              "&.Mui-selected": {
                backgroundColor: grey[200],
                borderLeft: `${amber[500]} solid 3px`,
              },
            }}
          >
            <ListItemIcon>
              <LockOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="セキュリティ" />
          </ListItem>
          <Divider />
        </List>
      </Drawer>
      <Box
        sx={{
          marginLeft: `${drawerWidth}px`,
          backgroundColor: indigo[50],
          minHeight: "100vh",
        }}
      >
        <Toolbar />
        <Box
          sx={{
            p: 2,
          }}
        >
          {current === "userprofile" && <UserProfile />}
          {current === "security" && <Security />}
        </Box>
      </Box>
    </Dialog>
  );
};
