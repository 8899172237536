import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { InputField } from "views/molecules";
import { PlanSelect, PlanDetails } from "../index";

/**
 * 利用者に招待を送付するフォームのプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box mb={1} fontWeight="fontWeightBold" fontSize="15px">
          新しい販売代理店の登録
        </Box>
        <Box fontSize="13px">
          システムの販売代理店を登録します。登録後、メールアドレス宛に利用開始手続きを行うページへのリングが送付されます。
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Controller
          name="company"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <InputField
              label="会社名"
              placeholder="会社名を入力します。"
              required={true}
              value={value}
              onChange={onChange}
              error={props.errors?.company}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Controller
          name="personInCharge"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <InputField
              label="担当者名"
              placeholder="担当者名を入力します。"
              required={true}
              value={value}
              onChange={onChange}
              error={props.errors?.personInCharge}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={9}>
        <Controller
          name="email"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <InputField
              label="メールアドレス"
              placeholder="例）info@ecope-pro.co.jp"
              required={true}
              value={value}
              onChange={onChange}
              error={props.errors?.email}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={9}>
        <Controller
          name="plan"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <PlanSelect
              label="提供プラン"
              required={true}
              value={value}
              options={props.plansType}
              onChange={onChange}
              error={props.errors?.plan}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={9}>
        <PlanDetails planDetails={props.planDetails} />
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};
