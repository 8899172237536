import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

/**
 * メッセージボックスの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  title = "",
  description = "",
  action = <></>,
  open,
  onClose,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="title"
    aria-describedby="description"
  >
    <DialogTitle id="title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="description">{description}</DialogContentText>
    </DialogContent>
    <DialogActions>{action}</DialogActions>
  </Dialog>
);
