import { useNavigate, useLocation } from "react-router-dom";
import { v4 } from "uuid";

const rows = [
  {
    id: v4(),
    useStatus: "トライアル中",
    useStartTime: "2021/07/01 12:23:05",
    company: "株式会社00000000",
    subscriberPlan: "(無料トライアル)　一般廃棄物ジャンりプラン　80,000円/月",
  },
  {
    id: v4(),
    useStatus: "加入中",
    useStartTime: "2021/07/01 12:23:05",
    company: "株式会社00000000",
    subscriberPlan: "一般廃棄物管理プラン　80,000円/月",
  },
  {
    id: v4(),
    useStatus: "加入中",
    useStartTime: "2021/07/01 12:23:05",
    company: "株式会社00000000",
    subscriberPlan: "一般廃棄物管理プラン　80,000円/月",
  },
  {
    id: v4(),
    useStatus: "加入中",
    useStartTime: "2021/07/01 12:23:05",
    company: "株式会社00000000",
    subscriberPlan: "一般廃棄物管理プラン　80,000円/月",
  },
];
const columns = [
  {
    field: "useStatus",
    headerName: "利用状況",
    width: 120,
  },
  {
    field: "useStartTime",
    headerName: "利用開始日時",
    width: 180,
  },

  {
    field: "company",
    headerName: "会社名",
    width: 250,
  },

  {
    field: "subscriberPlan",
    headerName: "加入プラン",
    width: 500,
  },
];

/**
 * 引数を受けて、JSXを返します。
 * @param {object} props プロパティ
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeSelection = (params) => {
    const selected = rows.find((row) => params.includes(row.id));
    navigate(`${location.pathname}/${selected.id}`, {
      formValue: selected,
    });
  };

  return render({
    rows: rows,
    columns: columns,
    onChangeSelection: handleChangeSelection,
    ...props,
  });
};
