import yup from "utils/yup";

export const basic = yup.object().shape({
  supportState: yup.string().required(),
  contactMethods: yup.string().required(),
  contactTime: yup.date().required(),
  contactType: yup.string().required(),
  company: yup.string().required().nullable(),
  representativeName: yup.string().nullable(),
  phoneNumber: yup.string().phone().nullable(),
  email: yup.string().email().nullable(),
  remarks: yup.string().required(),
});
