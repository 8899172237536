import { makeStyles } from "@mui/styles";

export const styles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: theme.zIndex.modal,
    "& input, & select": {
      boxSizing: "content-box"
    }
  },
  modal_container: {
    "&:focus-visible": {
      outline: "none",
    },
    backgroundColor:theme.palette.background.paper
  },
  content: {
    maxHeight: "75vh",
    overflowY: "auto"
  }
}));
