import { yupResolver } from "@hookform/resolvers/yup";
import { SecondStepRegister } from "ducks/Signup/slice";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { RegisterSchema } from "./validations";

const defalultValue = {
  email: "",
  passcode: "",
  username: "",
  password: "",
};
/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */

export const Container = ({ render, value, onBack, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onChange",
    defaultValues: defalultValue,
    resolver: yupResolver(RegisterSchema),
  });
  const {
    getValues,
    trigger,
    setValue,
    control,
    formState: { errors },
  } = methods;

  const handleBackToConfirm = () => {
    onBack && onBack();
  };

  const handleResendMailRegsiter = () => {};
  const handleRegister = async () => {
    const result = await trigger();
    if (result) {
      const data = getValues();
      dispatch(SecondStepRegister(data));
    }
  };
  useEffect(() => {
    if (value) {
      setValue("email", value?.email);
    }
  }, [setValue, value]);
  return render({
    classes: classes,
    methods: methods,
    control: control,
    errors: errors,
    onBackToConfirm: handleBackToConfirm,
    onResendMailRegsiter: handleResendMailRegsiter,
    onRegister: handleRegister,
    ...props,
  });
};
