import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

/**
 * 案内ポップアップの表示を行うプレゼンテーションコンポーネントです
 * @param {object} classes クラス名
 * @param {boolean} stepsEnabled 案内ポップアップを無効か有効にする
 * @param {array} steps 案内のステップ
 * @param {string} initialStep 始まるステップ
 * @param {string} onExit 案内ポップアップを閉じるイベント
 * @returns　JSX Element
 */
export const Presententer = (props) => {
  const options = {
    nextLabel: "次へ",
    prevLabel: "戻る",
    doneLabel: "終わり",
    showProgress: false,
    showBullets: false,
    tooltipClass: `${props.classes.beginnerOverride}`,
    highlightClass: `${props.classes.helperLayerOverride}`,
  };
  return props.visibility ? (
    <Steps
      enabled={props.stepsEnabled}
      steps={props.steps}
      initialStep={props.initialStep}
      onExit={props.onExit}
      options={options}
    />
  ) : <></>;
};
