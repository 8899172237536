import { createSlice } from "@reduxjs/toolkit";

const defaultValue = {
  enabled: false,
  initialStep: 0,
  visibility: true,
};

const guiderSteps = createSlice({
  name: "GuiderSteps",
  initialState: defaultValue,
  reducers: {
    onOpen: (state, action) => {
      state.enabled = !state.enabled;
    },
    onClose: (state, action) => {
      state.enabled = false;
    },
    setVisilityBeginnersMark: (state, action) => {
      state.visibility = action.payload ?? false;
    },
  },
});

/**
 * ストアからガイド情報を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.GuiderSteps;

export const {
  onOpen,
  onClose,
  setVisilityBeginnersMark,
} = guiderSteps.actions;

export default guiderSteps.reducer;
