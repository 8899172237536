import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * プランの詳細を表示するコンポーネントです。
 * @param {func} 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} planDetails プラン詳細情報
 */
export const PlanDetails = containerPresententer(Container, Presententer);
