import { Box, Button, TextField } from "@mui/material";

/**
 * 郵便番号の入力を行うコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */

export const Presententer = ({
  label,
  onChange,
  state,
  classes,
  required = false,
  onSearch,
  onSearchShow = false,
  error,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" fontSize="14px">
      <div className={classes.label}>
        {label} {required ? <span className={classes.required}>必須</span> : ""}
      </div>
      <TextField
        size="small"
        variant="outlined"
        value={state}
        onChange={onChange}
        className={classes.style_inputField}
        required={required}
        error={Boolean(error)}
        InputProps={{
          endAdornment: (
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={!onSearchShow}
              onClick={() => onSearch()}
              className={classes.button_search}
            >
              検索
            </Button>
          ),
        }}
        {...props}
      />
    </Box>
  );
};
