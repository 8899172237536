import { useState } from "react";
import { styles } from "./styles";

/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @param {array} rows XGridのデータが表示する
 * @param {array} columns XGridのコラム名
 * @param {boolean} onToolbar ツールバーが隠れるようにする
 * @param {object} actionButton 別のボータンが表示　例：新しい事業所。事業場を登録
 * @param {boolean} multipleSelection 複数行を選択できる
 * @param {func} onClose モーダルを閉じる
 * @param {func} onChangeSelection 選択されたアイテムを更新する
 * @param {array} value デフォルトデータ
 * @param {boolean} onDownload ダウンロードのボータンを有効か無効に設定する
 * @param {boolean} onColumns カラムのボータンを有効か無効に設定する
 * @param {boolean} onFilter フリーターのボータンを有効か無効に設定する
 * @param {number} pageSizeDisplay 表示する項目数を表示する
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  rows,
  columns,
  page,
  onToolbar = true,
  actionButton,
  multipleSelection = false,
  onClose,
  onPageSizeChange,
  onPageChange,
  checkboxSelection = false,
  onChangeSelection,
  value,
  onDownload = false,
  onColumns = true,
  onFilter = true,
  titleTable = "",
  rowCount,
  sortModel,
  paginationMode,
  ...props
}) => {
  const classes = styles();
  const selectionModel = Array.isArray(value)
    ? value.map((v) => v.id)
    : value
    ? [value.id]
    : [];

  const handleOnChangeSelectionModel = (params) => {
    if (
      multipleSelection ||
      (params?.length === 1 && params[0] !== value?.id)
    ) {
      onChangeSelection && onChangeSelection(params);
    }
  };

  const handleCloseModal = () => {
    onClose && onClose();
  };

  const handleSubmit = () => {
    onChangeSelection && onChangeSelection(selectionModel);
  };

  return render({
    classes: classes,
    columns: columns,
    rows: rows,

    actionButton: actionButton,
    titleTable: titleTable,
    selectionModel: selectionModel,
    multipleSelection: multipleSelection,
    onChangeSelectionModel: handleOnChangeSelectionModel,

    //複数行を選択できる
    onCloseModal: handleCloseModal,
    onSubmit: handleSubmit,

    onPageSizeChange: onPageSizeChange,
    onPageChange: onPageChange,
    checkboxSelection: checkboxSelection,
    onDownload: onDownload,
    onColumns: onColumns,
    onFilter: onFilter,
    onToolbar: onToolbar,
    page: page,
    rowCount: rowCount,
    paginationMode: paginationMode,
    sortModel: sortModel,
    ...props,
  });
};
