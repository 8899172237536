import { useStyles } from "./styles";
const steps = ["規約同意", "アカウント登録", "加入者情報入力"];

/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({ render, children, activeStep, ...props }) => {
  const classes = useStyles();

  return render({
    classes: classes,
    steps: steps,
    activeStep: activeStep,
    children: children,
    ...props,
  });
};
