import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Chip } from "@mui/material";

/**
 * 未対応Chipを表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Expired = () => {
  return <Chip icon={<LinkOffIcon />} label={"期限切れ"} color="error" />;
};
