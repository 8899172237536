import { useStyles } from "./styles";

/**
 * レンダーるのテキストの表示を行うコンテナコンポーネントです
 * @param {string} mainTitle 主なタイトル
 * @param {array} subTitle 字幕 例）["subtitle 01", "subtitle 02"]
 * @returns　JSX Element
 */
export const Container = ({
  render,
  mainTitle = "",
  subTitle = [],
  ...props
}) => {
  const classes = useStyles();
  return render({
    classes: classes,
    mainTitle: mainTitle,
    subTitle: subTitle,
    ...props,
  });
};
