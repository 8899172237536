import { fromQueryString, useXGridComponents } from "utils/useXGridComponents";
import { XGridComponent } from "views/molecules";
import { Canceled, Subscribed } from "../StatusChips";
import { searchPlanSubscribersByOffset } from "api/graphql/queries";
import { useLocation } from "react-router-dom";
import { formatISODateToText, formatAPIDateTimeToText } from "utils/formats";

const renderStateCell = (params) => {
  switch (params.value) {
    // 今使わない
    // case 1:
    //   return <InTrial />;
    case "subscribed":
      return <Subscribed />;
    case "unsubscribed":
      return <Canceled />;
    default:
      return <></>;
  }
};

const columns = [
  {
    field: "state",
    headerName: "利用状況",
    flex: 1,
    renderCell: renderStateCell,
  },
  {
    field: "startedUsingAt",
    headerName: "利用開始日時",
    flex: 1,
    renderCell: (params) => formatAPIDateTimeToText({ value: params.value }),
  },
  {
    field: "endedUsingAt",
    headerName: "終了日時",
    flex: 1,
    renderCell: (params) => formatAPIDateTimeToText({ value: params.value }),
  },
  {
    field: "companyName",
    headerName: "会社名",
    flex: 1,
  },
  {
    field: "planId",
    headerName: "プラン",
    flex: 1,
    renderCell: (params) => params.row.plan.name ?? "",
  },
  {
    field: "lastTotalWorkplaceCount",
    headerName: "請求対象の事業場数",
    flex: 1,
    type: "number",
    sortable: false,
    filterable: false,
  },
  {
    field: "lastTotalCalculatedOn",
    headerName: "算出日",
    flex: 1,
    renderCell: (params) => formatISODateToText({ value: params.value, formatString: "yyyy/MM/dd" }),
  },
  {
    field: "workplaceCount",
    headerName: "現在の有効事業場数",
    flex: 1,
    type: "number",
    sortable: false,
    filterable: false,
    renderCell: (params) => params.row.workplaceCount + params.row.clientWorkplaceCount,
    valueGetter: (params) => params.row.workplaceCount + params.row.clientWorkplaceCount,
  },
];

/**
 * 加入者一覧を表示するコンポーネント。
 * @param {object} props プロパティ
 * @fires List#onSelected 選択時
 * @returns {JSX.Element}
 */
export const List = ({ onSelected = () => {} }) => {
  const location = useLocation();
  const xGridObject = useXGridComponents(
    columns,
    searchPlanSubscribersByOffset,
    null,
    fromQueryString(location.search)
  );

  const handleSelect = (items) => {
    onSelected &&
      onSelected({
        value: xGridObject.params.rows.filter((row) => items.includes(row.id)),
        info: {
          search: xGridObject.search,
        },
      });
  };

  return (
    <XGridComponent
      {...xGridObject.params}
      onChangeSelection={handleSelect}
      onColumns={false}
      onFilter={true}
      onDownload={true}
      disableColumnMenu={true}
    />
  );
};
