import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Dialog, DialogActions, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { basicSchema } from "./Validations";

const defaultValues = {
  email: false,
};

/**
 * メールアドレスを入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.value 値
 * @param {boolean} props.open 開閉状態
 * @param {boolean} props.disabled 無効設定
 * @fires EMailForm#onSubmit 送信時
 * @fires EMailForm#onError エラー時
 * @fires EMailForm#onClose 閉じる時
 * @returns {JSX.Element}
 */
export const EMailForm = ({
  value,
  open = false,
  onSubmit = () => {},
  onError = () => {},
  onClose = () => {},
  disabled = false,
}) => {
  const { control, getValues, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      email: value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        email: value,
      });
    }
  }, [reset, open]);

  const onUpdate = async () => {
    const result = await trigger();
    if (result) {
      onSubmit(getValues());
    } else {
      onError(formState.errors);
    }
  };

  return (
    <Dialog open={open}>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Controller
          name={`email`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                required={true}
                value={value}
                onChange={onChange}
                placeholder="メールアドレスを入力"
                label="メールアドレス"
                error={Boolean(error?.message)}
                helperText={error?.message}
                disabled={disabled}
              />
            );
          }}
        />
      </Box>
      <DialogActions>
        <Button onClick={onClose} disabled={disabled} color="error">
          {"キャンセル"}
        </Button>
        <Button onClick={onUpdate} autoFocus disabled={disabled}>
          {"変更"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
