import { API, graphqlOperation } from "aws-amplify";
// import { listServices } from "api/graphql/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";

/**
 * 取得します。
 * @returns {object[]}
 */
const fetch = async () => {
  let result = [];
  try {
    //const fetchedData = await API.graphql(graphqlOperation(listServices));
    //result = fetchedData.data.listServices.items;
  } catch (error) {
    throw error;
  }
  return result;
};

/**
 * 販売プランの提供サービスを取得します。
 */
export const fetchPlanServices = createAsyncThunk(
  "planServices/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);
