import { Box, Button, Grid } from "@mui/material";
import clsx from "clsx";
import { Controller, FormProvider } from "react-hook-form";
import { RenderTitleInPopupOrModal } from "views/atoms";
import { InputField, InputFieldPassword } from "views/molecules";

/**
 * ログインの段階の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} classes クラス名
 *
 * @param {object} methods useFormのmethods
 * @param {object} control useFormのcontrol
 * @param {object} errors useFormのerrors
 *
 * @param {func} onBackToConfirm 確認段階に戻る
 * @param {func} onLogin ログイン
 *
 * @returns JSX Element
 */
export const Presententer = (props) => (
  <>
    <RenderTitleInPopupOrModal
      mainTitle="アカウント登録"
      subTitle={["パスワードを入力して既存のアカウントにログインします。"]}
    />
    <Box mt={3}>
      <FormProvider {...props.methods}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Controller
              control={props.control}
              name="email"
              render={({ field: { value, onChange } }) => (
                <InputField
                  label="メールアドレス"
                  required={false}
                  value={value}
                  disabled={true}
                  onChange={onChange}
                  error={props.errors?.email}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={9}>
            <Controller
              control={props.control}
              name="password"
              defaultValue=""
              render={({ field: { value, onChange } }) => (
                <InputFieldPassword
                  label="パスワード"
                  placeholder="パスワードを入力してください。"
                  required={true}
                  value={value}
                  onChange={onChange}
                  error={props.errors?.password}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={props.onBackToConfirm}
              className={props.classes.button}
            >
              戻る
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onLogin}
              className={clsx(props.classes.button, props.classes.button_login)}
            >
              ログイン
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  </>
);
