import { Button, Paper, Stack, Typography } from "@mui/material";
import {
  cancelPlanSubscribeProcedure,
  extendExpirationForPlanSubscribeProcedure,
} from "api/graphql/mutations";
import { getPlanSubscribeProcedure } from "api/graphql/queries";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { formatAWSDateTime, formatISODateToText } from "utils/formats";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { useQuery } from "utils/useQuery";
import { InformationItem, If } from "views/atoms";
import { ConfirmDialog } from "views/molecules";
import { DetailsPageFrame } from "views/organisms/DetailsPageFrame";
import { StatusChips } from "../StatusChips/StatusChips";

/**
 * 利用手続き詳細ページを表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Details = () => {
  const { inviteId } = useParams();
  const cancelConfirmDialog = useOpener();
  const extendConfirmDialog = useOpener();

  const invite = useQuery({
    query: getPlanSubscribeProcedure,
    variables: {
      id: inviteId,
    },
    onCompleted: (data) => {},
    skip: false,
  });

  const [cancelMutation, cancelMutationStatus] = useMutation(
    cancelPlanSubscribeProcedure,
    {
      onCompleted: (data) => {
        invite.refetch();
        cancelConfirmDialog.toggle(false);
      },
      onError: (error) => {},
      succeedMessage: "キャンセルしました。",
      errorMessage: "エラーが発生したため、キャンセルに失敗しました。",
    }
  );

  const [extendMutation, extendMutationStatus] = useMutation(
    extendExpirationForPlanSubscribeProcedure,
    {
      onCompleted: (data) => {
        invite.refetch();
        extendConfirmDialog.toggle(false);
      },
      onError: (error) => {},
      succeedMessage: "延長しました。",
      errorMessage: "エラーが発生したため、延長に失敗しました。",
    }
  );

  return (
    <DetailsPageFrame
      information={
        <Stack spacing={1}>
          <StatusChips
            value={invite?.data?.getPlanSubscribeProcedure?.state?.id}
          />
          <Typography variant="h6">
            {invite?.data?.getPlanSubscribeProcedure?.companyName ?? ""}
          </Typography>
        </Stack>
      }
      action={
        <If
          condition={
            !!invite?.data?.getPlanSubscribeProcedure?.state?.id &&
            (invite?.data?.getPlanSubscribeProcedure?.state?.id !== "completed")
          }
        >
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="warning"
              disabled={
                invite.loading ||
                cancelMutationStatus.loading ||
                extendMutationStatus.loading
              }
              onClick={() => cancelConfirmDialog.toggle(true)}
            >
              利用手続きをキャンセルする
            </Button>
            <Button
              variant="contained"
              color="info"
              disabled={
                invite.loading ||
                cancelMutationStatus.loading ||
                extendMutationStatus.loading
              }
              onClick={() => extendConfirmDialog.toggle(true)}
            >
              URL期限を延長する
            </Button>
          </Stack>
        </If>
      }
    >
      <Paper
        sx={{
          p: 2,
          mt: 1,
        }}
      >
        <Stack spacing={2}>
          <InformationItem label="URL期限">
            {formatISODateToText({
              value: invite?.data?.getPlanSubscribeProcedure?.expireAt,
              formatString: "yyyy/MM/dd",
            })}
          </InformationItem>
          <InformationItem label="会社名">
            {invite?.data?.getPlanSubscribeProcedure?.companyName ?? ""}
          </InformationItem>
          <InformationItem label="担当者名">
            {invite?.data?.getPlanSubscribeProcedure?.contactPersonnel ?? ""}
          </InformationItem>
          <InformationItem label="メールアドレス">
            {invite?.data?.getPlanSubscribeProcedure?.email ?? ""}
          </InformationItem>
          <InformationItem label="プラン">
            {invite?.data?.getPlanSubscribeProcedure?.plan?.name ?? ""}
          </InformationItem>
          <InformationItem label="URL">
            {invite?.data?.getPlanSubscribeProcedure?.url ?? ""}
          </InformationItem>
        </Stack>
      </Paper>
      <ConfirmDialog
        disabled={cancelMutationStatus.loading}
        open={cancelConfirmDialog.open}
        title="利用手続きキャンセル確認"
        message="利用手続きをキャンセルします。よろしいですか？"
        positiveText="はい"
        negativeText="いいえ"
        onPositive={() => {
          cancelMutation({
            input: {
              id: invite?.data?.getPlanSubscribeProcedure?.id,
            },
          });
        }}
        onNegative={() => cancelConfirmDialog.toggle(false)}
      />
      <ConfirmDialog
        disabled={extendMutationStatus.loading}
        open={extendConfirmDialog.open}
        title="URL延長確認"
        message="URL延長します。よろしいですか？"
        positiveText="はい"
        negativeText="いいえ"
        onPositive={() => {
          extendMutation({
            input: {
              id: invite?.data?.getPlanSubscribeProcedure?.id,
              extendedAt: formatAWSDateTime({
                value: DateTime.now().plus({ days: 30 }).toISODate(),
              }),
            },
          });
        }}
        onNegative={() => extendConfirmDialog.toggle(false)}
      />
    </DetailsPageFrame>
  );
};
