import { containerPresententer, withForwardRef } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 日時の選択を行うコンポーネントです。
 */
export const DateTimePicker = withForwardRef(
  containerPresententer(Container, Presententer)
);
