import { deepOrange } from "@mui/material/colors";

export const requiredStyle = (theme) => ({
  backgroundColor: deepOrange[500],
  padding: "3px 6px",
  color: "#fff",
  borderRadius: "4px",
  fontSize: "12px",
  marginLeft: theme.spacing(1),
});
