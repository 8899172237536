import { StepConnector } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import logo from "./logo.png";
export const useStyles = makeStyles((theme) => ({
  signup_appbar: {
    backgroundColor: theme.palette.background.paper,
  },
  toobar_override: { justifyContent: "space-between" },
  toolbar_logo: {
    width: "150px",
    height: "100%",
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "120px",
    backgroundPosition: "left center",
    cursor: "pointer",
    ...theme.mixins.toolbar,
  },
  main_title: {
    fontSize: "18px",
    fontWeight: "bold",
    color: grey[800],
    marginBottom: theme.spacing(1),
  },
  sub_title: { fontSize: "14px", color: grey[600] },
  step_label: {
    "& .MuiStepLabel-label": {
      fontWeight: "bold",
    },
  },
  toolbar_button: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));
export const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);
