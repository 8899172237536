import { createStyles } from "./styles";
import { renderFullText } from "views/molecules/XGridComponent/FullText";
import { contactProgressStatusColumn } from "utils/contexts/ContactProgressStatus";
import { DateTime } from "luxon";

const columns = [
  {
    field: "progressedAt",
    headerName: "対応日時",
    width: 180,
    valueFormatter: (params) => {
      return DateTime.fromISO(params.value).toFormat("yyyy/MM/dd hh:mm:ss a");
    },
    type: "date",
  },
  {
    field: "processingState",
    sortable: true,
    ...contactProgressStatusColumn,
  },
  {
    field: "details",
    headerName: "対応内容",
    width: 300,
    renderCell: renderFullText,
  },
  {
    field: "createdAt",
    headerName: "登録日時",
    width: 180,
    valueFormatter: (params) => {
      return DateTime.fromISO(params.value).toFormat("yyyy/MM/dd hh:mm:ss a");
    },
    type: "date",
  },
];

/**
 * 履歴タブを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {array} value 表示するデータ
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value = [], ...props }) => {
  const classes = createStyles();

  return render({
    classes: classes,
    rows: value,
    columns: columns,
    ...props,
  });
};
