import { Box } from "@mui/material";
import { setVisilityBeginnersMark } from "ducks/GuiderSteps/slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { debugLog } from "utils/log";
import { toggle, selector } from "ducks/Loading";
import { planServicesProvided } from "utils/contexts/PlanServicesProvided";
import { useState } from "react";

const renderCurrency = (params) => {
  if (!params?.value) {
    return "";
  }
  const currency = new Intl.NumberFormat().format(params?.value);
  return <Box ml={1}>{`${currency} 円/月`} </Box>;
};

const renderDays = (params) => {
  if (!params?.value) {
    return "";
  }

  return <Box ml={1}>{`${params?.value} 日`}</Box>;
};

export const columns = [
  {
    field: "name",
    headerName: "名称",
    width: 250,
  },
  {
    field: "basicCharge",
    headerName: "基本料金",
    width: 170,
    renderCell: renderCurrency,
    type: "number",
  },
  {
    field: "trialDays",
    headerName: "トライアル日数",
    width: 170,
    renderCell: renderDays,
    type: "number",
  },
  {
    field: "servicesProvided",
    ...planServicesProvided,
  },
  {
    field: "subscriberCount",
    headerName: "加入者数",
    flex: 1,
  },
];

const GuiderSteps = [
  {
    element: ".guide-add",
    position: "left",
    intro: "新しいプランを作成できます。",
  },
  {
    element: ".guide-searcher-area",
    position: "right",
    intro: "フリーワードでプランを検索できます。",
  },
  {
    element: ".MuiDataGrid-main",
    position: "top-middle-aligned",
    intro: "全てのプラン情報が表示します。",
  },
];

/**
 * 販売プラン管理の表示を行うコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {array} basePlan ベースプラン
 * @param {array} value 販売プラン
 * @fires Container#onSubmitPlan プラン送信時
 * @param {object} props プロパティ
 * @return {JSX.Elememt}
 */
export const Container = ({
  render,
  onTransiteDetails = () => {},
  basePlans = [],
  services = [],
  onSubmitted = () => {
    debugLog("templates.plans.onSubmitted");
  },
  onError = (err) => {
    debugLog("templates.plans.onError:", err);
  },
  submit = Promise.resolve(),
  planXGridParams,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setVisilityBeginnersMark(GuiderSteps?.length > 0));
  }, [dispatch]);

  const loading = useSelector(selector).status;

  const handleSubmit = (data) => {
    debugLog("templates.plans.handleSubmit:", data);
    dispatch(toggle(true));
    submit(data)
      .then((res) => {
        setTimeout(() => {
          debugLog("templates.plans.handleSubmit.setTimeout");
          dispatch(toggle(false));
          onSubmitted(res);
          setOpen(false);
        }, 5000);
      })
      .catch((err) => {
        debugLog("templates.plans.handleSubmit.err", err);
        dispatch(toggle(false));
        onError(err);
      });
  };

  return render({
    columns: columns,
    onChangeSelection: onTransiteDetails,
    open: open,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    GuiderSteps: GuiderSteps,
    basePlans: basePlans,
    planXGridParams: {
      ...planXGridParams,
      rows:
        planXGridParams.rows.map((item) => {
          return {
            ...item,
            servicesProvided: item?.servicesProvided?.map((plan) => {
              return {
                ...services?.find((item) => item.id === plan.serviceId),
                ...plan,
              };
            }),
          };
        }) ?? [],
    },
    services: services,
    onSubmit: handleSubmit,
    isLoading: loading,
    ...props,
  });
};
