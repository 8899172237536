import { Members as Template } from "views/templates/Members";
import { useNavigate, useLocation } from "react-router-dom";
import { toQueryString } from "utils/useXGridComponents";

export const Members = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (params) => {
    navigate(
      {
        pathname: `${location.pathname}`,
        search: toQueryString(params.info.search),
      },
      {
        replace: true,
      }
    );

    navigate(`${location.pathname}/${params.value[0].id}`);
  };

  return <Template onClick={handleClick} />;
};
