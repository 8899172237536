import { Authorities, RoleNames } from "../constants";

/**
 * 権限を絞り込みます。
 * @param {array} value 権限配列
 * @param {array} disabledAuthorities 無効にする権限配列
 * @returns {array}
 */
export const narrowDownAuthorities = (value = [], disabledAuthorities = []) => {
  if (!value || value.length <= 0) {
    return [];
  }

  if (!disabledAuthorities || disabledAuthorities.length <= 0) {
    return value;
  }

  return value.filter((authority) =>
    disabledAuthorities.length <= 0
      ? true
      : disabledAuthorities.some((a) => a.id !== authority.id)
  );
};

/**
 * 除外する権限名を取得します。
 * @param {string} roleName 権限名
 * @returns {array}
 */
export const fetchRemoveRoleNames = (roleName) => {
  switch (roleName) {
    // オーナー
    case "オーナー":
      return [];

    // 管理者権限 - オーナー権限は操作できない
    case "管理者":
      return RoleNames.filter((name) => name === "オーナー");

    // 異常値のため全て無効にする
    default:
      return RoleNames;
  }
};

/**
 * 権限から操作できない権限を取得します。
 * @param {string} roleName 権限
 * @returns {array}
 */
export const fetchUncontrolRoles = (roleName) => {
  switch (roleName) {
    // オーナー - 無効な権限は無い
    case "オーナー":
      return [];
    // 管理者権限 - オーナー権限は操作できない
    case "管理者":
      return ["オーナー"];
    // 異常値のため全て無効にする
    default:
      return ["オーナー", "管理者"];
  }
};

/**
 * オブジェクトが空であるか確認します。
 * @param {object} value オブジェクト
 * @returns {JSX.Element}
 */
export const isEmpty = (value) => {
  if (typeof value === "string" && value === "") {
    return true;
  }

  if (Array.isArray(value) && value.length <= 0) {
    return true;
  }

  return !value;
};

/**
 * コントロール可能であるか確認します。
 * @param {object} currentUser 現在のユーザー
 * @param {object} user 確認したいユーザー
 * @returns {boolean}
 */
export const canControl = (currentUser, user) => {
  // 指定なしの場合は"できない"とみなす
  if (!currentUser || !user) {
    return false;
  }

  // 同一ユーザの場合は操作できない
  if (currentUser.id === user.id) {
    return false;
  }

  const uncontrolRoles = fetchUncontrolRoles(currentUser?.roleName);

  // 操作できない権限が無い場合は操作できる
  if (isEmpty(uncontrolRoles)) {
    return true;
  }

  // 操作できない権限であるか確認
  return !uncontrolRoles.some((roleName) => roleName === user.roleName);
};
