import {
  Paper,
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Typography,
  Skeleton,
  Box,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PasswordForm } from "./PasswordForm";
import { VerificationForm } from "./VerificationForm";
import { EMailForm } from "./EMailForm";
import { PhoneForm } from "./PhoneForm";
import { useState } from "react";
import { useMutation } from "utils/TemporaryModules";

/**
 * セキュリティ設定を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.loading 読み込み中
 * @returns {JSX.Element}
 */
export const Security = ({ value, loading }) => {
  const [passwordForm, setPasswordForm] = useState(false);
  const [verificationForm, setVerificationForm] = useState(false);
  const [emailForm, setEMailForm] = useState(false);
  const [phoneForm, setPhoneForm] = useState(false);

  const [updateEmailMutation, updateEmailMutationStatus] = useMutation(
    "ミューテーション",
    {
      onCompleted: (params) => {
        setEMailForm(false);
      },
      onError: (error) => {},
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新に失敗しました。",
    }
  );

  const [updatePhoneMutation, updatePhoneMutationStatus] = useMutation(
    "ミューテーション",
    {
      onCompleted: (params) => {
        setPhoneForm(false);
      },
      onError: (error) => {},
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新に失敗しました。",
    }
  );

  const [updatePasswordMutation, updatePasswordMutationStatus] = useMutation(
    "ミューテーション",
    {
      onCompleted: (params) => {
        setPasswordForm(false);
      },
      onError: (error) => {},
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新に失敗しました。",
    }
  );

  const [updateVerificationMutation, updateVerificationMutationStatus] =
    useMutation("ミューテーション", {
      onCompleted: (params) => {
        setVerificationForm(false);
      },
      onError: (error) => {},
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新に失敗しました。",
    });

  if (loading === true) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={190} />
        <Skeleton variant="rectangular" height={190} />
      </Stack>
    );
  }

  return (
    <>
      <Stack spacing={2}>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {"ログイン"}
          </Typography>
          <List>
            <ListItem
              onClick={() => setPasswordForm(true)}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <Box display={"flex"} flexDirection="row">
                  <ListItemText
                    primary={`パスワード`}
                    sx={{
                      width: 300,
                    }}
                  />
                  <ListItemText
                    primary={`前回の変更：${value?.changedOn ?? ""}`}
                  />
                </Box>
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => setVerificationForm(true)}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <Box display={"flex"} flexDirection="row">
                  <ListItemText
                    primary={`2段階認証プロセス`}
                    sx={{
                      width: 300,
                    }}
                  />
                  <ListItemText
                    primary={`${value?.enabledVerification ? "オン" : "オフ"}`}
                  />
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {"本人確認の方法"}
          </Typography>
          <Typography
            sx={{
              color: "gray",
              fontSize: "14px",
            }}
          >
            {
              "ログインしているのが本人であることを確認する場合や、アカウントで不審なアクティビティがあった場合に使用します。"
            }
          </Typography>
          <List>
            <ListItem
              onClick={() => setEMailForm(true)}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <Box display={"flex"} flexDirection="row">
                  <ListItemText
                    primary={`再設定用のメールアドレス`}
                    sx={{
                      width: 300,
                    }}
                  />
                  <ListItemText primary={`${value?.email ?? ""}`} />
                </Box>
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => setPhoneForm(true)}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <Box display={"flex"} flexDirection="row">
                  <ListItemText
                    primary={`再設定用の電話番号`}
                    sx={{
                      width: 300,
                    }}
                  />
                  <ListItemText
                    primary={`${
                      value?.phone
                        ? value?.phone
                        : "携帯電話番号を追加してください"
                    }`}
                  />
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
      </Stack>
      <PasswordForm
        open={passwordForm}
        onSubmit={(response) => {
          updatePasswordMutation({
            ...response,
          });
        }}
        disabled={updatePasswordMutationStatus.loading}
        onClose={() => setPasswordForm(false)}
      />
      <VerificationForm
        open={verificationForm}
        onSubmit={(response) => {
          updateVerificationMutation({
            ...response,
          });
        }}
        disabled={updateVerificationMutationStatus.loading}
        onClose={() => setVerificationForm(false)}
        value={value?.enabledVerification}
      />
      <EMailForm
        open={emailForm}
        onSubmit={(response) => {
          updateEmailMutation({
            ...response,
          });
        }}
        disabled={updateEmailMutationStatus.loading}
        onClose={() => setEMailForm(false)}
        value={value?.email}
      />
      <PhoneForm
        open={phoneForm}
        onSubmit={(response) => {
          updatePhoneMutation({
            ...response,
          });
        }}
        disabled={updatePhoneMutationStatus.loading}
        onClose={() => setPhoneForm(false)}
        value={value?.phone}
      />
    </>
  );
};
