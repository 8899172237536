import { TextField, Typography, Autocomplete } from "@mui/material";
import React from "react";

/**
 * コンボボックスで選択項目を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Autocomplete
      freeSolo
      disableClearable={false}
      options={props.options}
      noOptionsText="選択肢がありません。"
      value={props.value}
      onChange={(_, data) => {
        props.onChange(data);
      }}
      renderInput={(params) => (
        <>
          <div className={props.classes.label}>
            {props.label}
            {props.required ? (
              <span className={props.classes.required}>必須</span>
            ) : (
              ""
            )}
          </div>
          <TextField
            {...params}
            size="small"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            placeholder={props.placeholder}
            error={Boolean(props.error)}
            helperText={props.helperText}
            onChange={props.onChange}
          />
        </>
      )}
    />
  );
};
