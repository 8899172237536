import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Chip } from "@mui/material";

/**
 * 未開封Chipを表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Unopened = () => {
  return <Chip icon={<MailOutlineIcon />} label={"未開封"} color="warning" />;
};
