import { useFormContext } from "react-hook-form";
import { useStyles } from "./styles";

/**
 * プランのリスト
 */
/*
const plansType = rows.map(({ id, name, basicCharge }) => ({
  code: id,
  name: name,
  price: basicCharge,
}));
*/

/**
 * 利用者に招待を送付するフォームのコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返します。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, plansType = [], ...props }) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  // const planDetails = value.filter((row) => watch("plan").includes(row.id));

  return render({
    classes: classes,
    control: control,
    errors: errors,
    plansType: plansType,
    planDetails: value,
    ...props,
  });
};
