/**
 * チェックボックスを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} label ラベル
 * @param {boolean} disabled 有効無効設定
 * @param {object} classes クラスオブジェクト
 * @param {string} labelPlacement ラベルの位置
 * @param {boolean} checked チェック状態
 * @fires COntainer#onChange 変更時
 * @param {object} inputRef 入力要素の参照
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  label,
  disabled,
  classes,
  labelPlacement,
  checked,
  onChange,
  inputRef,
  ...props
}) => {
  return render({
    label: label,
    disabled: disabled,
    classes: classes,
    labelPlacement: labelPlacement,
    checked: checked,
    onChange: onChange,
    inputRef: inputRef,
    ...props,
  });
};
