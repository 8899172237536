import React from "react";
import { XGridComponent } from "views/molecules";

/**
 * 履歴の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return (
    <div className={props.classes.support_table}>
      <XGridComponent
        onToolbar={false}
        rows={props.rows}
        columns={props.columns}
        disableColumnMenu={true}
        disableSelectionOnClick={true}
        onSortModelChange={props.onSortModelChange}
        sortModel={props.sortModel}
      />
    </div>
  );
};
