import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * PDFファイルのレンダーを表示するコンポーネントです。
 * @param {} filePDF PDF ファイル
 * @param {string} labelCheckbox チェックボックスのラベル
 * @param {boolean} onChecked チェックボックスを更新するイベント
 */
export const RenderPDF = containerPresententer(Container, Presententer);
