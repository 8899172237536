import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 郵便番号の入力を行うコンテナコンポーネントです。
 */
export const PostCodeInputField = containerPresententer(
  Container,
  Presententer
);
