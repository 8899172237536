import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Controller } from "react-hook-form";
import { Switch } from "views/atoms";
import { InputField, SelectPopup } from "views/molecules";
import { Function } from "..";

/**
 * 従量課金を入力するコンポーネントです、
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const MeteredBillingField = ({
  value,
  name,
  placeholders,
  endAdorments,
  onChange,
  errors,
}) => {
  return (
    <FormControl>
      <FormGroup row>
        <InputField
          placeholder={placeholders?.[0]}
          value={value?.charge}
          type="number"
          onChange={(e) => {
            onChange({
              ...value,
              billingUnit: name,
              charge: e.target.value,
            });
          }}
          error={Boolean(errors?.charge)}
          helperText={errors?.charge}
          InputProps={{
            inputProps: {
              min: 0,
            },
            endAdornment: (
              <InputAdornment position="end">
                {endAdorments?.[0]}
              </InputAdornment>
            ),
          }}
        />
        <Box pl={2}>
          <InputField
            placeholder={placeholders?.[1]}
            value={value?.freeTier}
            type="number"
            onChange={(e) => {
              onChange({
                ...value,
                billingUnit: name,
                freeTier: e.target.value,
              });
            }}
            error={Boolean(errors?.freeTier)}
            helperText={errors?.freeTier}
            InputProps={{
              inputProps: {
                min: 0,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {endAdorments?.[1]}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </FormGroup>
    </FormControl>
  );
};

/**
 * プランを追加するフォームの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <form id={props.id} onSubmit={props.submit} noValidate>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12}>
          <Box mb={1} fontWeight="fontWeightBold" fontSize="15px">
            新しいプランの登録
          </Box>
          <Box fontSize="13px">提供するプランを作成します。</Box>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="basePlanId"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <SelectPopup
                  label="べースプラン"
                  required={true}
                  placeholder="未選択"
                  options={props.basePlans.map((plan) => {
                    return {
                      name: plan.name,
                      code: plan.id,
                    };
                  })}
                  value={value}
                  onChange={(e) => {
                    props.toggleBasePlanServices(e.target.value);
                    onChange(e);
                  }}
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="名称"
                placeholder="プランの名称を入力します。"
                required={true}
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="basicCharge"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="基本料金"
                placeholder="例）8000"
                required={true}
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  endAdornment: (
                    <InputAdornment position="end">円/月</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="meteredBilling"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              const handleChange = (data) => {
                let result = [...value];

                if (
                  result.find((item) => item.billingUnit === data.billingUnit)
                ) {
                  result = result.map((item) => {
                    return item.billingUnit === data.billingUnit
                      ? {
                          ...item,
                          ...data,
                        }
                      : item;
                  });
                } else {
                  result.push(data);
                }

                onChange(result);
              };
              return (
                <FormControl error={Boolean(error?.message)}>
                  <Typography
                    style={{
                      color: grey[700],
                    }}
                  >
                    {"従量課金"}
                  </Typography>
                  <FormGroup>
                    <MeteredBillingField
                      value={value?.find(
                        (item) => item.billingUnit === "workplace"
                      )}
                      onChange={handleChange}
                      name="workplace"
                      endAdorments={["円/事業場", "円/事業場以降から課金"]}
                      placeholders={["例）1900", "例）5"]}
                      errors={error?.[0]}
                    />
                    <MeteredBillingField
                      value={value?.find(
                        (item) => item.billingUnit === "account"
                      )}
                      onChange={handleChange}
                      name="account"
                      endAdorments={["円/ユーザー", "ユーザー以降から課金"]}
                      placeholders={["例）1900", "例）5"]}
                      errors={error?.[1]}
                    />
                  </FormGroup>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="trialDays"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="トライアル日数"
                placeholder="例）30"
                type="number"
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  endAdornment: (
                    <InputAdornment position="end">日</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="description"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="概要"
                multiline
                required={true}
                rows={4}
                placeholder="概要を入力します。"
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              className={props.classes.title_service}
            >
              提供サービス
            </FormLabel>
            <FormGroup>
              <Controller
                control={props.control}
                name={"servicesProvided"}
                render={({ field: { value, onChange } }) => {
                  return (
                    props.services?.map((item, index) => (
                      <Switch
                        id={item.serviceId}
                        key={index}
                        classes={{
                          root: props.classes.switch,
                        }}
                        disabled={!item.changeable}
                        checked={
                          value?.find((i) => i.serviceId === item.serviceId)
                            .available ?? false
                        }
                        onChange={(e) => {
                          let result = [...value];

                          if (
                            result.find((i) => i.serviceId === item.serviceId)
                          ) {
                            result = result.map((j) => {
                              return j.serviceId === item.serviceId
                                ? {
                                    ...j,
                                    available: e.target.checked,
                                  }
                                : j;
                            });
                          } else {
                            result.push({
                              available: e.target.checked,
                              changeable: item.changeable,
                              serviceId: item.serviceId,
                            });
                          }

                          onChange(result);
                        }}
                        labelPlacement="start"
                        label={
                          <Function
                            mark={item.name.substring(0, 2)}
                            name={item.name}
                            description={item.description}
                            functionName={item.functionName}
                          />
                        }
                      />
                    )) ?? <></>
                  );
                }}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};
