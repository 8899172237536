import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { DateTimePicker, InputField, SelectPopup } from "views/molecules";
import { AutoCompleteComboBox } from "views/molecules/AutoCompleteComboBox";

/**
 * お問い合わせを追加するフォームの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <form id={props.id} onSubmit={props.submit} noValidate>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box mb={1} fontWeight="fontWeightBold" fontSize="15px">
          新しい問い合わせの登録
        </Box>
        <Box fontSize="13px">システム外でのお問い合わせを登録します</Box>
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="supportState"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectPopup
              label="対応状況"
              placeholder="未選択"
              required={true}
              options={props.contactProgressStatus}
              value={value}
              onChange={onChange}
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="contactMethods"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <AutoCompleteComboBox
                label="お問い合わせ方法"
                placeholder="例）電話"
                required={true}
                options={props.supportMethodsType}
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="contactTime"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DateTimePicker
              label="お問い合わせ日時"
              required={true}
              value={value}
              onChange={onChange}
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="contactType"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AutoCompleteComboBox
              label="お問い合わせの種類"
              placeholder="例）加入申請"
              required={true}
              value={value}
              options={props.supportType}
              onChange={onChange}
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="company"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              required={true}
              label="会社名"
              placeholder="会社名を入力します。"
              value={value}
              onChange={onChange}
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="representativeName"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="代表者名"
              placeholder="担当者名を入力します。"
              value={value}
              onChange={onChange}
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="phoneNumber"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="電話番号"
              placeholder="例）000-0000-0000"
              value={value}
              type="tel"
              onChange={onChange}
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="email"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="メールアドレス"
              placeholder="例）info@ecope-pro.co.jp"
              value={value}
              onChange={onChange}
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="remarks"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="内容"
              placeholder="お問い合わせ内容を入力します。"
              multiline
              required={true}
              rows={4}
              value={value}
              onChange={onChange}
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  </form>
);
