import { makeStyles } from "@mui/styles";
import { green, grey } from "@mui/material/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginLeft: theme.spacing(1),
    "& > span": {
      display: "block",
    },
  },
  mark: {
    fontSize: "14px",
    backgroundColor: theme.palette.primary.main,
  },
  name: {
    color: grey[800],
    fontWeight: "bold",
  },
  description: {
    color: grey[400],
    fontSize: "12px",
  },
  tags: {
    "& > span": {
      backgroundColor: green[500],
      color: "#fff",
      fontSize: "12px",
      marginRight: theme.spacing(2),
      padding: "2px 12px 1px",
      borderRadius: "4px",
      letterSpacing: "2px",
    },
  },
}));
