import { useStyles } from "./styles";

/**
 * 詳細ページの枠組みを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {JSX.Element} information ヘッダーに情報を表示する要素
 * @param {JSX.Element} action ヘッダーに表示する行動を促す要素 
 * @returns {JSX.Element}
 */
export const Container = ({ render, information, action, ...props }) => {
  const classes = useStyles();

  return render({
    classes: classes,
    information: information,
    action: action,
    ...props,
  });
};
