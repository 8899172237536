import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useStyles } from "./styles";
import { Subscribers } from "./Validations";

const DefaultValues = {
  company: "",
  email: "",
  personInCharge: "",
  plan: ["plan01"],
};

/**
 * 新しい販売代理店に利用手続きを送付するダイアログを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {boolean} open 開閉状態
 * @fires Container#onCloseDialog ダイアログを閉じるとき
 * @fires Container#onCHangeDataSubmit 送信時
 * @param {object} value 値
 * @param {object} props その他プロパティ
 */
export const Container = ({
  render,
  open = false,
  onCloseDialog,
  onChangeDataSubmit,
  value,
  ...props
}) => {
  const classes = useStyles();

  const methods = useForm({
    mode: "onChange",
    defaultValues: DefaultValues,
    resolver: yupResolver(Subscribers),
  });

  const {
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [reset, value]);

  const handleSubmitForm = async () => {
    const result = await trigger();
    if (result && Object.keys(errors).length === 0) {
      const data = getValues();
      console.log("data.submit.Subscribers: ", data);
      onCloseDialog && onCloseDialog();
      reset();
    }
  };

  return render({
    classes: classes,
    open: open,
    onCloseDialog: onCloseDialog,
    onSubmit: handleSubmitForm,
    methods: methods,
    ...props,
  });
};
