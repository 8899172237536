import { styles } from "./styles";

/**
 * ポップアップセレクションでデータを選択するコンポーネントです。
 * @param {func} render 引数をうけて、JSX Elemntを返します
 * @param {string} label ラベル
 * @param {string} placeholder プレースホルダー
 * @param {boolean} required 必須かどうか
 * @param {object} value 選択された項目
 * @fires Container#onChange 選択状態変更時
 * @param {array} options 選択する項目
 * @param {boolean} error エラーかどうか
 * @param {string} helperText ヘルパーテキスト
 * @returns {JSX.Element}
 */
export const Container = ({
  id,
  render,
  label,
  required = true,
  value,
  onChange,
  options = [],
  placeholder,
  error,
  helperText,
  fullWidth = false,
  ...props
}) => {
  const classes = styles();

  return render({
    id: id,
    classes: classes,
    label: label,
    placeholder: placeholder,
    required: required,
    value: value,
    onChange: onChange,
    options: options,
    error: error,
    helperText: helperText,
    fullWidth: fullWidth,
    ...props,
  });
};
