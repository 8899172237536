import { AppBar, Button, Toolbar } from "@mui/material";

/**
 * 手続きフローを確認する画面の表示を行うプレゼンテーションコンポーネントです。
 * @returns JSX Element
 */
export const Presententer = (props) => (
  <>
    <AppBar
      position="fixed"
      color="transparent"
      className={props.classes.signup_appbar}
    >
      <Toolbar className={props.classes.toobar_override}>
        <div className={props.classes.toolbar_logo} />
        {props.showButton && (
          <Button
            disabled={!props.isValid}
            color="primary"
            variant="contained"
            className={props.classes.toolbar_button}
            onClick={props.onClickButton}
          >
            {props.buttonText}
          </Button>
        )}
      </Toolbar>
    </AppBar>
    <Toolbar />
  </>
);
