import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  Box,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { basicSchema } from "./Validations";

const defaultValues = {
  verification: false,
};

/**
 * 認証プロセスを更新するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.disabled 無効設定
 * @fires VerificationForm#onSubmit 送信時
 * @fires VerificationForm#onError エラー時
 * @fires VerificationForm#onClose 閉じる時
 * @returns {JSX.Element}
 */
export const VerificationForm = ({
  open,
  value = false,
  onSubmit = () => {},
  onError = () => {},
  onClose = () => {},
  disabled = false,
}) => {
  const { control, getValues, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      verification: value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        verification: value,
      });
    }
  }, [reset, open]);

  const onUpdate = async () => {
    const result = await trigger();
    if (result) {
      onSubmit(getValues());
    } else {
      onError(formState.errors);
    }
  };

  return (
    <Dialog open={open}>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Controller
          name={`verification`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <FormControl component="fieldset" variant="standard">
                <FormControlLabel
                  control={<Switch checked={value} onChange={onChange} />}
                  label="2段階認証プロセス"
                  disabled={disabled}
                />
                <FormHelperText error={error?.message}>
                  {error?.message}
                </FormHelperText>
              </FormControl>
            );
          }}
        />
      </Box>
      <DialogActions>
        <Button onClick={onClose} disabled={disabled} color="error">
          {"キャンセル"}
        </Button>
        <Button onClick={onUpdate} autoFocus disabled={disabled}>
          {"変更"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
