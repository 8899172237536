import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Chip } from "@mui/material";

/**
 * 手続き完了Chipを表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Completed = () => {
  return (
    <Chip
      icon={<CheckCircleOutlineOutlinedIcon />}
      label={"手続き完了"}
      color="info"
    />
  );
};
