import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * プランを選択するコンポーネントです。
 * @param {func} render 引数をうけてJSX Elemntを返します
 * @param {string} label セレクションのラベル
 * @param {string} placeholder プレースホルダー
 * @param {boolean} required 必須かどうかを設定する
 * @param {boolean} disabled 有効無効設定
 * @param {object} value 選択されたアイテムです
 * @fires onChange 選択するアイテムが更新するイベントです
 * @param {array} options 選択項目
 * @param {boolean} error エラー表示
 */
export const PlanSelect = containerPresententer(Container, Presententer);
