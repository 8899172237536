import { Button, Typography } from "@mui/material";
import { SmsOutlined } from "@mui/icons-material";

/**
 * フィードバックボタンの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({ classes, onClick }) => (
  <Button
    className={classes.button_feedback}
    startIcon={<SmsOutlined />}
    size="large"
    fullWidth
    onClick={onClick}
  >
    <Typography
      sx={{
        color: "#616161",
        fontSize: "14px"
      }}
    >
      フィードバックを送信
    </Typography>
  </Button>
);
