import { useForm } from "react-hook-form";
import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { basic } from "./Validations/basic";
import { debugLog } from "utils/log";

const defaultValues = {
  basePlanId: null,
  name: null,
  basicCharge: null,
  interval: "month",
  currency: "jpy",
  meteredBilling: [],
  trialDays: null,
  description: null,
  servicesProvided: [],
};

/**
 * プランを追加するフォームを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {array} basePlans ベースプランの選択項目
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} props その他プロパティ
 */
export const Container = ({
  render,
  id = "planForm",
  basePlans = [],
  services = [],
  value,
  onSubmit = (data) => debugLog("organisms.planform.onSubmit", data),
  onError = (e) => debugLog("organisms.planform.onError", e),
  ...props
}) => {
  const classes = useStyles();
  const [basePlanServices, setBasePlanServices] = useState(null);
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basic),
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
    });
  }, [reset, value]);

  const getBasePlanServicesProvided = (basePlanId) => {
    if (basePlanId) {
      return basePlans.find((basePlan) => basePlan.id === basePlanId)
        .servicesProvided;
    } else {
      return null;
    }
  };

  const margedServices = (basePlanServicesProvided) => {
    if (basePlanServicesProvided) {
      return basePlanServicesProvided.map((basePlanService) => {
        return {
          ...basePlanService,
          ...services.find(
            (service) => service.id === basePlanService.serviceId
          ),
        };
      });
    } else {
      return null;
    }
  };

  const toggleBasePlanServices = (basePlanId) => {
    const basePlanServicesProvided = getBasePlanServicesProvided(basePlanId);

    setBasePlanServices(basePlanServicesProvided);

    const marged = margedServices(basePlanServicesProvided);

    setValue(
      "servicesProvided",
      marged?.map((item) => {
        return {
          available: item.available,
          changeable: item.changeable,
          serviceId: item.id,
        };
      })
    );
  };

  return render({
    id: id,
    classes: classes,
    control: control,
    errors: errors,
    basePlans: basePlans,
    submit: handleSubmit(onSubmit, onError),
    services: margedServices(basePlanServices),
    toggleBasePlanServices: toggleBasePlanServices,
    ...props,
  });
};
