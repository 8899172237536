import { Button } from "@mui/material";
import React from "react";
import { XGridComponent, FullScreenDialog } from "views/molecules";
import { ContactForm } from "views/organisms/Contact";
import { StepGuider } from "views/atoms";

/**
 * お問い合わせ管理の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div>
      <XGridComponent
        {...props.contactXGridParams}
        onChangeSelection={props.onChangeSelection}
        columns={props.columns}
        onColumns={false}
        onFilter={true}
        onDownload={false}
        disableColumnMenu={true}
        actionButton={
          <Button
            className="guide-add"
            variant="contained"
            color="primary"
            size="large"
            onClick={props.onOpenDialog}
          >
            新しいお問い合わせを追加する
          </Button>
        }
      />
      <FullScreenDialog
        formId="ContactForm"
        title="新しいお問い合わせの登録"
        textConfirm="登録"
        open={props.open}
        onClose={props.onCloseDialog}
        isSubmit={props.isLoading}
      >
        <ContactForm id="ContactForm" onSubmit={props.onChangeDataSubmit} />
      </FullScreenDialog>
      <StepGuider value={props.GuiderSteps} />
    </div>
  );
};
