import { API, graphqlOperation } from "aws-amplify";
import { listRoles } from "api/graphql/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";

/**
 * 権限を取得します。
 * @returns {object}
 */
const fetch = async () => {
  try {
    const fetchedData = await API.graphql(graphqlOperation(listRoles));
    return fetchedData.data.listRoles.items;
  } catch (error) {
    throw error;
  }
};

export const fetchRoles = createAsyncThunk(
  "roles/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);
