import React from "react";
import { XGridComponent } from "views/molecules";

/**
 * 加入者タブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @param {array} rows テーブルの内容
 * @param {array} columns テーブルのヘダータイトル
 * @param {func} onChangeSelection 選択された行を取得する
 */
export const Presententer = (props) => {
  return (
    <div>
      <XGridComponent
        rows={props.rows}
        columns={props.columns}
        onChangeSelection={props.onChangeSelection}
        onColumns={false}
        onFilter={true}
        onDownload={false}
        disableColumnMenu={true}
      />
    </div>
  );
};
