import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, TextField } from "@mui/material";
import { Selector as PlanSelector } from "features/Plan/Selector";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { Labeling } from "views/atoms/Labeling";
import { FullScreenDialog } from "views/molecules";
import { URLDialog } from "../URLDialog";
import { basic } from "./basic";
import { createPlanSubscribeProcedure } from "api/graphql/mutations";
import { DateTime } from "luxon";

const defaultValues = {
  companyName: null,
  contactPersonnel: null,
  email: null,
  plans: null,
};

/**
 * 招待するコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @fires InvitationForm#onClose 閉じる時
 * @fires InvitationForm#onCompleted 完了時
 * @fires InvitationForm#onError エラー時
 * @returns {JSX.Element}
 */
export const InvitationForm = ({
  open = false,
  onClose = () => console.log("閉じられた"),
  onCompleted = () => console.log("送付完了"),
  onError = () => console.log("エラー"),
}) => {
  const urlDialog = useOpener();
  const [url, setUrl] = useState(null);
  const [createMutation, createMutationStatus] = useMutation(
    createPlanSubscribeProcedure,
    {
      onCompleted: (data) => {
        setUrl(
          `${data.createPlanSubscribeProcedure.url}`,
        );
        urlDialog.toggle(true);
      },
      succeedMessage: "登録しました。",
      errorMessage: "エラーが発生したため、登録に失敗しました。",
    },
    false,
    null,
    null
  );
  const { control, trigger, getValues, formState, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(basic),
  });

  useEffect(() => {
    if (open === true) {
      reset({
        ...defaultValues,
      });
    }
  }, [reset, open]);

  const handleRegist = async () => {
    const result = await trigger();

    if (result) {
      const {
        companyName,
        contactPersonnel,
        email,
        plans
      } = getValues();
      createMutation({
        input: {
          companyName: companyName,
          contactPersonnel: contactPersonnel,
          email: email,
          planId: plans.id,
          expireAt: DateTime.now().plus({ days: 30 }).toISO()
        },
      });
    } else {
      onError && onError(formState.errors);
    }
  };

  return (
    <>
      <FullScreenDialog
        open={open}
        onClose={onClose}
        title="新しい加入者向けの利用手続きURLを作成"
        textConfirm="登録"
        onSubmit={handleRegist}
        disabled={createMutationStatus.loading}
      >
        <Stack spacing={2}>
          <Stack>
            <Box mb={1} fontWeight="fontWeightBold" fontSize="15px">
              加入者向けの利用手続きURLの作成
            </Box>
            <Box fontSize="13px">
              <Stack>
                <Stack>
                  システム加入者が利用開始手続きを行うためのURLを作成します。
                </Stack>
                <Stack>
                  作成後にURLが表示されますので、システム加入者に送付してください。URLは詳細画面からもご確認いただけます。
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Controller
            name="companyName"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Labeling label="会社名" required={true}>
                <TextField
                  placeholder="会社名を入力します。"
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                  disabled={createMutationStatus.loading}
                />
              </Labeling>
            )}
          />

          <Controller
            name="contactPersonnel"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Labeling label="担当者名" required={true}>
                <TextField
                  placeholder="担当者名を入力します。"
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                  disabled={createMutationStatus.loading}
                />
              </Labeling>
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Labeling label="メールアドレス" required={true}>
                <TextField
                  placeholder="例）info@ecope-pro.co.jp"
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                  disabled={createMutationStatus.loading}
                />
              </Labeling>
            )}
          />

          <Controller
            name="plans"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Labeling label="プラン" required={true}>
                <PlanSelector
                  required={true}
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                  disabled={createMutationStatus.loading}
                />
              </Labeling>
            )}
          />
        </Stack>
      </FullScreenDialog>
      <URLDialog
        open={urlDialog.open}
        loading={createMutationStatus.loading}
        value={url}
        onClose={() => {
          urlDialog.toggle(false);
          onCompleted && onCompleted();
          onClose && onClose();
        }}
      />
    </>
  );
};
