import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogActions, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { basicSchema } from "./Validations";

const defaultValues = {
  currentPassword: null,
  newPassword: null,
};

/**
 * パスワードを変更するコンポーネントです。
 * @param {object} porps プロパティ
 * @fires PasswordForm#onError エラー時
 * @fires PasswordForm#onClose 閉じる時
 * @returns {JSX.Element}
 */
export const PasswordForm = ({
  open,
  onClose,
  onSubmit = () => {},
  onError = () => {},
  disabled = false,
}) => {
  const { control, getValues, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [reset, open]);

  const onUpdate = async () => {
    const result = await trigger();
    if (result) {
      onSubmit(getValues());
    } else {
      onError(formState.errors);
    }
  };

  return (
    <Dialog open={open}>
      <Stack
        spacing={2}
        sx={{
          p: 2,
        }}
      >
        <Controller
          name={`currentPassword`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                required={true}
                value={value}
                onChange={onChange}
                type="password"
                placeholder="現在のパスワード"
                label="現在のパスワード"
                error={Boolean(error?.message)}
                helperText={error?.message}
                disabled={disabled}
              />
            );
          }}
        />
        <Controller
          name={`newPassword`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                required={true}
                value={value}
                onChange={onChange}
                type="password"
                placeholder="新しいパスワード"
                label="新しいパスワード"
                error={Boolean(error?.message)}
                helperText={error?.message}
                disabled={disabled}
              />
            );
          }}
        />
      </Stack>
      <DialogActions>
        <Button onClick={onClose} disabled={disabled} color="error">
          {"キャンセル"}
        </Button>
        <Button onClick={onUpdate} autoFocus disabled={disabled}>
          {"変更"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
