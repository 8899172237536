import React from "react";
import { Agent } from "views/templates/Agent";

/**
 * 販売代理店の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return (
    <div>
      <Agent />
    </div>
  );
};
