import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

export const useStyles = makeStyles((theme) => ({
  main_title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: grey[800],
    marginBottom: theme.spacing(1),
  },
  sub_title: { fontSize: "14px", color: grey[600] },
}));
