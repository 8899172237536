import { Box } from "@mui/material";

/**
 * サービスのテキストを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const Text = (props) => (
  <Box className={props.classes.service}>
    <span>{props.title}</span>
    {props.description}
  </Box>
);

/**
 * サービスを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const Service = (props) => (
  <Box className={props.classes.plan_service}>
    <Box fontSize="13px">{props.value.overview}</Box>
    {props.value.industrialWastesService && (
      <Text title={"受注"} description={"スポット回収・定期回収"} />
    )}
    {props.value.vihicleDispatchService && (
      <Text title={"配車"} description={"配車表・配車計画"} />
    )}
    {(props.value.generalWastesService ||
      props.value.industrialWastesService) && (
      <Text
        title={"伝票"}
        description={
          <>
            {props.value.industrialWastesService && <div>{`マニフェスト`}</div>}
            {props.value.generalWastesService && <div>{`オリジナル伝票`}</div>}
          </>
        }
      />
    )}
    {props.value.estimateService && (
      <Text title={"見積"} description={"見積書"} />
    )}
    {props.value.invoiceService && (
      <Text title={"請求"} description={"請求書"} />
    )}
    {props.value.orderService && (
      <Text title={"受注"} description={"スポット回収・定期回収"} />
    )}
    {(props.value.payPerUseWorkplace ||
      props.value.payPerUseWorkplaceFrom ||
      props.value.payPerUseUser ||
      props.value.payPerUseUserFrom) && (
      <Text
        title={"従量課金"}
        description={
          <>
            {props.value.payPerUseWorkplace && (
              <div>{`${new Intl.NumberFormat().format(
                props.value.payPerUseWorkplace
              )}円/事業場`}</div>
            )}
            {props.value.payPerUseWorkplaceFrom && (
              <div>{`${new Intl.NumberFormat().format(
                props.value.payPerUseWorkplaceFrom
              )}円/事業場以降から課金`}</div>
            )}
            {props.value.payPerUseUser && (
              <div>{`${new Intl.NumberFormat().format(
                props.value.payPerUseUser
              )}円/ユーザー`}</div>
            )}
            {props.value.payPerUseUserFrom && (
              <div>{`${new Intl.NumberFormat().format(
                props.value.payPerUseUserFrom
              )}ユーザー以降から課金`}</div>
            )}
          </>
        }
      />
    )}
  </Box>
);

/**
 * プラン詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box boxShadow={20} className={props.classes.plan_details}>
    {props?.trialDay && (
      <span className={props.classes.plan_trial}>
        無料トライアル {props?.trialDay} 日
      </span>
    )}
    <Box className={props.classes.plan_name_price}>
      <Box className={props.classes.plan_name}>{props?.name}</Box>
      <Box className={props.classes.plan_price}>
        月額<span>{new Intl.NumberFormat().format(props?.basicCharge)}</span>
        円～
      </Box>
    </Box>
    <Box className={props.classes.plan_service}>
      <Box fontSize="13px">{props?.overview}</Box>
      {props?.servicesProvide &&
        props.servicesProvide.map((service, index) => (
          <Service value={service} classes={props.classes} />
        ))}
    </Box>
  </Box>
);
