/**
 * 販売代理店の表示を行うコンテナコンポーネントです
 * @param {object} props プロパティ
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  return render({
    ...props,
  });
};
