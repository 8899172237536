import { If } from "views/atoms";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUser, userSelector } from "ducks/User";
import { fetchPlanServices } from "ducks/PlanServices";
import { fetchPlans } from "ducks/Plans";
import { fetchRoles } from "ducks/Roles";
import { useGraphQLSubscription } from "../../../utils/api";
import { subscribe } from "../../../ducks/SystemAlert";
import { onCreateSystemNotification } from "api/graphql/subscriptions";

/**
 * 認証後のページ基盤のコンテナコンポーネントです。
 * @param {object} props プロパティオブジェクト
 * @param {JSX.Element} props.children 子コンポーネント
 * @returns {JSX.Element}
 */
export const AuthenticatedFoundation = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrentUser());
    dispatch(fetchPlanServices());
    dispatch(fetchRoles());
    dispatch(fetchPlans());
  }, [dispatch]);

  const user = useSelector(userSelector);

  useGraphQLSubscription(onCreateSystemNotification, null, (data) => {
    dispatch(subscribe(data.onCreateSystemNotification));
  });

  return (
    <>
      <If condition={!!user} elseComponent={<></>}>
        {children}
      </If>
    </>
  );
};
