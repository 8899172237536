import { Document, Page } from "react-pdf";
import React, { useState } from "react";

/**
 * PDFを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.source PDFのソース
 * @returns {JSX.Element}
 */
export const AllPagesPDFView = ({ source }) => {
  const [pagesNumber, setPagesNumber] = useState(null);

  const handleLoadSuccess = ({ numPages }) => {
    setPagesNumber(numPages);
  };

  return (
    <Document
      file={source}
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={handleLoadSuccess}
    >
      {Array.from(new Array(pagesNumber), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
};
