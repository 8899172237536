import { useRef, useState } from "react";
import { useStyles } from "./styles";

export const Container = ({
  render,
  filePDF,
  labelCheckbox = "規約に同意します。",
  onChecked,
  checkedName,
  ...props
}) => {
  const classes = useStyles();
  const listInnerRef = useRef();
  const [checked, setChecked] = useState({ [checkedName]: false });
  const [hideCheck, setHideCheck] = useState(true);
  const [numPages, setNumPages] = useState(null);

  const handleChangeChecked = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setChecked({ [name]: value });
    onChecked && onChecked({ [name]: value });
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setHideCheck(false);
      }
    }
  };

  return render({
    classes: classes,
    files: filePDF,
    numPages: numPages,
    checked: checked,
    hideCheck: hideCheck,
    checkedName: checkedName,
    onChangeChecked: handleChangeChecked,
    labelCheckbox: labelCheckbox,
    onDocumentLoadSuccess: handleDocumentLoadSuccess,
    onScroll: handleScroll,
    listInnerRef: listInnerRef,
    ...props,
  });
};
