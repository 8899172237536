import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { debugLog } from "utils/log";

/**
 * 日付の選択の表示を行うコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} label ラベル
 * @param {string} value 日付文字列
 * @fires Container#onChange 変更時
 * @fires Container#onError エラー時
 * @param {boolean} required 必須であるか
 * @param {boolean} error エラーを表示するか
 * @param {string} helperText エラー文字列
 * @param {string} placeholder プレースホルダ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  label = "",
  value,
  onChange = (date) => debugLog(date),
  onError = (params) => debugLog(params),
  required = false,
  error,
  helperText,
  placeholder,
  fullWidth = false,
  ...props
}) => {
  const classes = useStyles();
  const [date, setDate] = useState(null);

  useEffect(() => {
    const result = DateTime.fromJSDate(value);
    if (result.isValid) {
      setDate(value.toFormat("yyyy/MM/dd"));
    } else {
      setDate(null);
    }
  }, [value]);

  const handleChange = (date) => {
    const result = DateTime.fromJSDate(date).toFormat("yyyy/MM/dd");
    setDate(result);
    onChange && onChange(result);
  };

  return render({
    placeholder: placeholder,
    label: label,
    classes: classes,
    date: date,
    onChange: handleChange,
    required: required,
    error: error,
    helperText: helperText,
    fullWidth: fullWidth,
    ...props,
  });
};
