import clsx from "clsx";
import { Avatar, AvatarGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { blue, green } from "@mui/material/colors";
import { listServices } from "api/graphql/queries";

const useStyles = makeStyles({
  group: {
    "& .blue": {
      backgroundColor: blue[500],
    },
    "& .greener": {
      backgroundColor: green[600],
    },
  },
  avatar: {
    fontSize: "13px",
    border: "none",
    marginLeft: "-6px",
  },
});

const PlanAvatar = (props) => {
  return (
    <Avatar className={clsx(props.classes.avatar, props.value?.code)}>
      {props.value?.name?.substring(0, 2) ?? ""}
    </Avatar>
  );
};

export const PlanAvatarGroup = (props) => {
  const classes = useStyles();

  return (
    <AvatarGroup className={classes.group}>
      {props?.value
        ?.filter((item) => item.available)
        ?.map((item, index) => (
          <PlanAvatar classes={classes} value={item} key={index} />
        )) ?? <div></div>}
    </AvatarGroup>
  );
};

export const planServicesProvided = {
  field: "servicesProvided",
  headerName: "提供サービス",
  width: 200,
  renderCell: PlanAvatarGroup,
};
