/**
 * 詳細ページの枠組みを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div className={props.classes.root}>
    <div className={props.classes.header}>
      <div className={props.classes.information_area}>{props.information}</div>
      <div className={props.classes.action_area}>{props.action}</div>
    </div>
    {props.children}
  </div>
);
