import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormHelperText } from "@mui/material";

/**
 * パスワードフィールドの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  label,
  onChange,
  value,
  classes,
  required = false,
  error,
  showPassword,
  onClickShowPassword,
  onMouseDownPassword,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" fontSize="14px">
      <div className={classes.label}>
        {label} {required ? <span className={classes.required}>必須</span> : ""}
      </div>
      <FormControl
        fullWidth
        size="small"
        variant="outlined"
        required={required}
        error={Boolean(error)}
      >
        <OutlinedInput
          id="standard-adornment-password"
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={onChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={onClickShowPassword}
                onMouseDown={onMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          className={classes.style_inputField}
          {...props}
        />
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};
