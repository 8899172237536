import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { createUserAccount } from "api/graphql/mutations";
import { rolesSelector } from "ducks/Roles";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMutation } from "utils/useMutation";
import { RoleSelector } from "../RoleSelector";
import { basicSchema } from "./basicSchema";
import { useState } from "react";

const defaultValues = {
  name: null,
  role: null,
  email: null,
};

/**
 * メンバーを追加するフォームコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.open 開閉状態
 * @fires Form#onClose 閉じる時
 * @param {boolean} props.disabled 無効設定
 * @param {object} props.customErrors - カスタムエラーオブジェクト
 * @returns {JSX.Element}
 */
export const Form = ({
  value,
  open = false,
  onClose = () => {},
  onSubmit = () => {},
  onError = () => {},
}) => {
  const [customErrors, setCustomErrors] = useState(null);
  const roles = useSelector(rolesSelector);

  const [sendMutation, sendMutationStatus] = useMutation(createUserAccount, {
    onBeforeExecute: () => {
      setCustomErrors(null);
    },
    onCompleted: (data) => {
      onSubmit(data);
      onClose();
    },
    onError: ({ errors }) => {
      for (let error of errors) {
        if (error.errorType === "UsernameExistsException") {
          setCustomErrors({
            email: {
              message: "入力されたメールアドレスは既に登録されています。",
            },
          });
        }
      }
    },
    succeedMessage: "追加しました。",
    errorMessage: "エラーが発生したため、追加に失敗しました。",
  });

  const { control, getValues, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
      role: !!value
        ? roles.find((role) => role.name === value?.roleName)
        : null,
      roleName: undefined,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    if (open === true) {
      reset({
        ...defaultValues,
        ...value,
        role: !!value
          ? roles.find((role) => role.name === value?.roleName)
          : null,
        roleName: undefined,
      });
    }
  }, [reset, value, open]);

  const handleAdd = async () => {
    const result = await trigger();

    if (result) {
      const { name, role, email } = getValues();
      sendMutation({
        input: {
          id: value?.id,
          planSubscriberId: null,
          name: name,
          roleId: role?.id,
          email: email,
          expectedVersion: value?.version,
        },
      });
    } else {
      onError && onError(formState.errors);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={sendMutationStatus.loading}
    >
      <DialogTitle>{"メンバーを追加"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {
            "メンバーを追加するには、ここにユーザー名、メールアドレスを入力してください。"
          }
        </DialogContentText>
        <Stack
          spacing={2}
          sx={{
            pt: 1,
          }}
        >
          <Controller
            name={`name`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextField
                  autoFocus
                  id="names"
                  label="ユーザー名"
                  fullWidth
                  type="text"
                  variant="standard"
                  value={value}
                  onChange={onChange}
                  error={Boolean(error) || !!customErrors?.name}
                  helperText={error?.message || customErrors?.name?.message}
                  disabled={sendMutationStatus.loading}
                  InputLabelProps={{ shrink: true }}
                />
              );
            }}
          />
          <Controller
            name={`role`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RoleSelector
                  value={value}
                  onChange={onChange}
                  error={Boolean(error) || customErrors?.role}
                  helperText={error?.message || customErrors?.role?.message}
                  disabled={sendMutationStatus.loading}
                />
              );
            }}
          />
          <Controller
            name={`email`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextField
                  margin="dense"
                  id="email"
                  label="メールアドレス"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={value}
                  onChange={onChange}
                  error={Boolean(error) || customErrors?.email}
                  helperText={error?.message || customErrors?.email?.message}
                  disabled={sendMutationStatus.loading}
                  InputLabelProps={{ shrink: true }}
                />
              );
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAdd} disabled={sendMutationStatus.loading}>
          {"追加"}
        </Button>
        <Button
          color="error"
          onClick={onClose}
          disabled={sendMutationStatus.loading}
        >
          {"キャンセル"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
