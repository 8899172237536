import { Button } from "@mui/material";
import React from "react";
import { XGridComponent, FullScreenDialog } from "views/molecules";
import { PlanForm } from "views/organisms/Plans";
import { StepGuider } from "views/atoms";

/**
 * 販売プラン管理の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @param {array} rows テーブルの内容
 * @param {array} columns テーブルのヘダータイトル
 * @param {func} onChangeSelection 選択された行を取得する
 * @param {boolean} open ダイアログを有効か無効にする
 * @param {func} onOpenDialog ダイアログを開くイベント
 * @param {func} onCloseDialog ダイアログを閉じるイベント
 * @param {func} onChangeDataSubmit 登録されたデータを取得するイベント
 */
export const Presententer = (props) => (
  <div>
    <XGridComponent
      {...props.planXGridParams}
      columns={props.columns}
      onChangeSelection={props.onChangeSelection}
      onColumns={false}
      onFilter={false}
      onDownload={false}
      disableColumnMenu={true}
      actionButton={
        <Button
          className="guide-add"
          variant="contained"
          color="primary"
          size="large"
          onClick={props.onOpenDialog}
        >
          新しいプランを追加
        </Button>
      }
    />
    <FullScreenDialog
      formId={"PlanForm"}
      open={props.open}
      onClose={props.onCloseDialog}
      title="新しいプランを登録"
      textConfirm="登録"
      isSubmit={props.isLoading}
    >
      <PlanForm
        id={"PlanForm"}
        basePlans={props.basePlans}
        services={props.services}
        onSubmit={props.onSubmit}
      />
    </FullScreenDialog>
    <StepGuider value={props.GuiderSteps} />
  </div>
);
