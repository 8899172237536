import { useCallback, useEffect, useReducer } from "react";
import { sleep } from "./sleep";

const initState = {
  rows: [],
  filter: null,
  sort: null,
  errors: null,
  isLoading: false,
  pageSize: 10,
  page: 0,
  total: 0,
  columnVisibility: null,
};

const actions = {
  beforeFetch: "useXGridComponents/fetch/before",
  succeeded: "useXGridComponents/fetch/succeeded",
  errors: "useXGridComponents/fetch/error",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.beforeFetch:
      return {
        ...state,
        rows: [],
        isLoading: true,
      };
    case actions.succeeded:
      return {
        ...state,
        rows: action.payload.rows,
        total: action.payload.total,
        isLoading: false,
      };
    case actions.errors:
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const useXGridComponents = (
  columns,
  query,
  fixedOptions,
  initialState,
  rows = []
) => {
  if (!columns) {
    throw new Error("カラムが設定されてません。");
  }

  if (!query) {
    throw new Error("クエリが設定されてません。");
  }

  const [state, dispatch] = useReducer(reducer, {
    ...initState,
    ...initialState,
  });

  const fetch = useCallback(
    (filter = null, sort = null, offset, limit) => {
      dispatch({
        type: actions.beforeFetch,
      });

      sleep(2, () => Promise.resolve(rows))
        .then(() => {
          dispatch({
            type: actions.succeeded,
            payload: {
              rows: rows,
              total: rows.total,
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: actions.errors,
            payload: error,
          });
        });
    },
    [fixedOptions]
  );

  const refetch = () => {
    fetch(
      state.filter,
      state.sort,
      state.page * state.pageSize,
      state.pageSize
    );
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      fetch(
        state.filter,
        state.sort,
        state.page * state.pageSize,
        state.pageSize
      );
    }
    return () => {
      unmounted = true;
    };
    // 初回コールの為、警告無効
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRows = (rows) => {
    dispatch({
      type: actions.succeeded,
      payload: rows,
    });
  };

  return {
    params: {
      rows: rows,
      columns: state.columnVisibility ? state.columnVisibility : columns,
      loading: state.isLoading,
      pageSize: state.pageSize,
      page: state.page,
      rowCount: state.total,
    },
    functions: {
      setRows,
      refetch,
    },
    error: state.errors,
  };
};
