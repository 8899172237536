import { useStyles } from "./styles";

/**
 * テキストフィールドの表示を行うコンテナコンポーネントです。
 * @param {string} label ラベル
 * @param {boolean} required 必須かどうか
 * @param {string} value 値
 * @param {boolean} error エラー表示するか
 * @param {string} helperText メッセージ
 * @fires Container#onChange 変更時
 */
export const Container = ({
  render,
  label,
  required,
  value,
  error,
  helperText,
  onChange,
  ...props
}) => {
  const classes = useStyles();

  return render({
    classes: classes,
    label: label,
    required: required,
    value: value,
    error: error,
    helperText: helperText,
    onChange: onChange,
    ...props,
  });
};
