import { Box, Paper } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { RenderTitleInPopupOrModal } from "views/atoms";
import { StepHeader } from "../StepHeader";
import { Steppers } from "../Steppers";
import { ConfirmForm } from "./Child";

/**
 * 加入者情報入力のステップ3の表示を行うプレゼンテーションコンポーネントです。
 * @returns JSX Element
 */
export const Presententer = (props) => (
  <>
    <StepHeader
      isValid={props.isValid}
      onClickButton={props.isValid ? props.onClickButton : null}
      buttonText="登録"
    />
    <Steppers activeStep={props.activeStep}>
      <Box mt={4} mb={4}>
        <Paper elevation={4}>
          <Box p={4} pl={5}>
            <RenderTitleInPopupOrModal
              mainTitle="加入者情報を入力"
              subTitle={["各項目を入力してください。"]}
            />
            <Box mt={3}>
              <FormProvider {...props.methods}>
                <ConfirmForm />
              </FormProvider>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Steppers>
  </>
);
