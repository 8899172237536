import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";

/**
 * タブを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {string} props.value 値
 * @param {array} props.options 1タブの情報 { value, label, disabled, content }
 * @fires Tabs#onChange 変更時
 * @returns {JSX.Element}
 */
export const Tabs = ({ value = null, onChange = () => {}, options = [] }) => {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="tabs-label">
            {options.map((option, index) => (
              <Tab key={index} label={option.label} value={option.value} />
            ))}
          </TabList>
        </Box>
        {options.map((option, index) => (
          <TabPanel
            key={index}
            value={option.value}
            sx={{
              backgroundColor: "#fff",
            }}
          >
            {option.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};
