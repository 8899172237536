import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import { Chip } from "@mui/material";

/**
 * 取り下げChipを表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Withdrawal = () => {
  return (
    <Chip icon={<UndoOutlinedIcon />} label={"取り下げ"} color="default" />
  );
};
