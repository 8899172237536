import { useStyles } from "./styles";

/**
 * 機能を説明するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} mark 機能のマーク
 * @param {string} name 機能名
 * @param {string} description 機能の説明
 * @param {array} tags 機能のタグリスト
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  mark,
  name,
  description,
  functionName,
  ...props
}) => {
  const classes = useStyles();

  return render({
    classes: classes,
    mark: mark,
    name: name,
    description: description,
    functionName: functionName,
    ...props,
  });
};
