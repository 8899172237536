import { makeStyles } from "@mui/styles";

const drawerWidth = 270;

export const stylesCreator = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    overflowY: "hidden",
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  toolbar_logo: {
    width: "130px",
    height: "100%",
    backgroundImage: `url(${window.location.origin}/logo.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100px",
    backgroundPosition: "center",
    marginRight: 55,
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      backgroundPosition: "left",
    },
  },
}));
