import { useEffect } from "react";
import { useStyles } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import { basic } from "./Validations/basic";
import { ContactProgressStatus } from "utils/contexts/ContactProgressStatus";

const defaultValue = {
  contactTime: DateTime.fromJSDate(new Date()).toFormat("yyyy/MM/dd hh:mm a"),
  supportState: "",
  details: "",
};

/**
 * 対応状況の更新モーダル内容を表示するコンポーネントです。
 * @param {func} 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props プロパティ
 * @returns {JSX.Elment}
 */
export const Container = ({
  render,
  id = "support-form",
  value,
  onSubmit,
  onError,
  ...props
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: { ...defaultValue, ...value },
    resolver: yupResolver(basic),
  });

  useEffect(() => {
    reset({ ...defaultValue, ...value });
  }, [reset, value]);

  return render({
    id: id,
    classes: classes,
    control: control,
    errors: errors,
    contactProgressStatus: ContactProgressStatus,
    submit: handleSubmit(onSubmit, onError),
    ...props,
  });
};
