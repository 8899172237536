import * as yup from "yup";

export const ConfirmSchema = yup.object().shape({
  plan: yup.array().nullable().required(),
  businessName: yup.string().required(),
  industry: yup.string().required(),
  postCode: yup.string().required(),
  prefectures: yup.string().required(),
  city: yup.string().required(),
  streetAddress: yup.string().required(),
  otherAddress: yup.string().required(),
});
