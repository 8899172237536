import { API, graphqlOperation } from "aws-amplify";
import { add } from "ducks/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { debugLog } from "./log";

/**
 * ミューテーションを実行する関数と状態を受け取るフックです。
 * @param {string} MUTATION GraphQLミューテーション
 * @param {object} options オプション
 * @param {func} options.onBeforeExecute api実行前
 * @param {func} options.onCompleted 完了時
 * @param {func} options.onError エラー時
 * @param {string} options.succeedMessage ミューテーション実行成功時のメッセージ
 * @param {string} options.errorMessage ミューテーションエラー時のメッセージ
 * @fires useMutation#options.onCompleted 完了時
 * @fires useMutation#options.onError エラー時
 * @returns {array} - 配列オブジェクト[ミューテーション, 状態(data, error, loading)]
 */
export const useMutation = (MUTATION, options) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    data: null,
    error: null,
    loading: false,
  });

  const mutation = (variables) => {
    options?.onBeforeExecute?.();

    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    debugLog("========================================");
    debugLog(" - ミューテーションの実行：");
    debugLog("   - ミューテーション：", MUTATION);
    debugLog("   - 引数：", variables);

    return API.graphql(graphqlOperation(MUTATION, variables))
      .then((res) => {
        debugLog(" - ミューテーションの成功：", res);
        setState((prevState) => ({
          ...prevState,
          data: res.data,
        }));
        options?.succeedMessage &&
          dispatch(
            add({
              value: options?.succeedMessage,
              severity: "success",
            })
          );
        options?.onCompleted?.(res?.data);
      })
      .catch((err) => {
        debugLog(" - ミューテーションのエラー：", err);
        options?.errorMessage &&
          dispatch(
            add({
              value: options?.errorMessage,
              severity: "error",
            })
          );
        setState((prevState) => ({
          ...prevState,
          error: err,
        }));
        options?.onError?.(err);
      })
      .finally(() => {
        debugLog(" - ミューテーション実行終了");
        debugLog("========================================");
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  };

  return [mutation, { ...state }];
};
