import React from "react";
import { FormProvider } from "react-hook-form";
import { FullScreenDialog } from "views/molecules";
import { AgentInviteForm } from "../AgentInviteForm";

/**
 * 新しい販売代理店に利用手続きを送付するダイアログを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <FullScreenDialog
      open={props.open}
      onClose={props.onCloseDialog}
      title="新しい販売代理店に利用手続きを送付"
      textConfirm="送付"
      onSubmit={props.onSubmit}
    >
      <FormProvider {...props.methods}>
        <AgentInviteForm />
      </FormProvider>
    </FullScreenDialog>
  );
};
