import { useStyles } from "./styles";

/**
 * 認証画面のテンプレートを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = useStyles();

  const handleClickPrivacyPolicy = () => {
    // todo: プライバシーポリシーボタンが押された時
  };

  const handleClickTermsUse = () => {
    // todo: 利用規約が押された時
  };

  return render({
    classes: classes,
    onClickPrivacyPolicy: handleClickPrivacyPolicy,
    onClickTermsUse: handleClickTermsUse,
    ...props,
  });
};
