import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Dialog,
  Divider,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { useState } from "react";
import { useOpener } from "utils/useOpener";
import { PasswordChangeForm } from "../PasswordChangeForm";
import { AllPagesPDFView } from "./AllPagesPDFView";
import PrivacyPolicy from "../../../../resources/pdf/PrivacyPolicy.pdf";
import TermsOfService from "../../../../resources/pdf/TermsOfService.pdf";

/**
 * ユーザーアカウントボタンが押された時のポップアップするメニューを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.anchorEl アンカー要素
 * @param {boolean} props.loading 読み込み中
 * @param {boolean} props.open 開閉状態
 * @fires PopupMenu#onClose 閉じる時
 * @fires PopupMenu#onClickManagementAcount アカウント管理メニュークリック時
 * @fires PopupMenu#onClickSignOut ログアウトメニュークリック時
 * @returns {JSX.Element}
 */
export const PopupMenu = ({
  value = null,
  anchorEl = null,
  loading = false,
  open = false,
  onClose = () => {},
  onClickManagementAcount = () => {},
  onClickSignOut = () => {},
}) => {
  const [openPDF, setOpenPDF] = useState(false);
  const [pdf, setPDF] = useState(null);
  const changePasswordDialog = useOpener();

  const handleClickPrivacyPolicy = () => {
    setPDF(PrivacyPolicy);
    setOpenPDF(true);
  };

  const handleClickTermsService = () => {
    setPDF(TermsOfService);
    setOpenPDF(true);
  };

  if (loading === true) {
    return <></>;
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="user-account-menu"
        open={open}
        onClose={onClose}
      >
        <MenuItem>
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
            width={"100%"}
          >
            <Avatar
              src={value?.icon}
              sx={{
                width: 56,
                height: 56,
                fontSize: "20px",
                backgroundColor: indigo[200],
              }}
            >
              <PersonIcon />
            </Avatar>
            <Typography>{value?.name ?? ""}</Typography>
            <Typography
              sx={{
                color: "darkgrey",
                fontSize: "14px",
              }}
            >
              {value?.email ?? ""}
            </Typography>
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => changePasswordDialog.toggle(true)}>
          <ListItemIcon>
            <ManageAccountsOutlinedIcon />
          </ListItemIcon>
          <ListItemText>パスワード変更</ListItemText>
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={onClickManagementAcount}>
        <ListItemIcon>
          <ManageAccountsOutlinedIcon />
        </ListItemIcon>
        <ListItemText>アカウント管理</ListItemText>
      </MenuItem>
      <Divider /> */}
        <MenuItem onClick={onClickSignOut}>
          <ListItemIcon>
            <LogoutOutlinedIcon />
          </ListItemIcon>
          <ListItemText>ログアウト</ListItemText>
        </MenuItem>
        <Divider />
        <Stack direction={"row"} spacing={2}>
          <Link
            onClick={handleClickPrivacyPolicy}
            component="button"
            sx={{ p: 1 }}
          >
            プライバシーポリシー
          </Link>
          <Link
            onClick={handleClickTermsService}
            component="button"
            sx={{ p: 1 }}
          >
            サービス利用規約
          </Link>
        </Stack>
        <Dialog
          onClose={() => {
            setOpenPDF(false);
            setPDF(null);
          }}
          open={openPDF}
          PaperProps={{
            sx: {
              width: "fit-content",
              maxWidth: "95vw",
            },
          }}
        >
          <AllPagesPDFView source={pdf} />
        </Dialog>
      </Menu>
      <PasswordChangeForm
        open={changePasswordDialog.open}
        onClose={() => changePasswordDialog.toggle(false)}
      />
    </>
  );
};
