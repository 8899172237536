import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { InputField, PostCodeInputField, SelectPopup } from "views/molecules";
import {
  PlanDetails,
  PlanSelect,
} from "views/organisms/Subscribers/SubscriberInviteForm/ChildComponents";

/**
 *　加入者情報入力フォームの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props  ポロパティ
 * @returns
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Controller
          name="plan"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <PlanSelect
              label="加入プラン"
              required={true}
              value={value}
              disabled={props.disabledSelectPlan}
              options={props.plansType}
              onChange={onChange}
              error={props.errors?.plan}
            />
          )}
        />
        <Box mt={1} mb={2}>
          <PlanDetails planDetails={props.planDetails} />
        </Box>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={9}>
        <Controller
          name="businessName"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <InputField
              label="事業者の名称"
              placeholder="事業者の名称を入力します。"
              required={true}
              value={value}
              onChange={onChange}
              error={props.errors?.businessName}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Controller
          name="industry"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <SelectPopup
              label="本社の業種"
              required={true}
              value={value}
              placeholder="未選択"
              onChange={onChange}
              error={props.errors?.industry}
              options={props.BusinessType}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={3}>
        <Controller
          name="postCode"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <PostCodeInputField
              label="本社の郵便番号"
              required={true}
              placeholder="例）000-0000"
              value={value}
              onGetFullAddress={props.onGetFullAddress}
              onChange={onChange}
              error={props.errors?.postCode}
            />
          )}
        />
      </Grid>
      <Grid item xs={9} />
      <Grid item xs={3}>
        <Controller
          name="prefectures"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <SelectPopup
              label="本社の都道府県"
              required={true}
              value={value}
              placeholder="未選択"
              onChange={onChange}
              options={props.Prefectures}
              error={props.errors?.prefectures}
            />
          )}
        />
      </Grid>
      <Grid item xs={9} />
      <Grid item xs={6}>
        <Controller
          name="city"
          render={({ field: { value, onChange } }) => (
            <InputField
              label="本社の市区町村"
              required={true}
              placeholder="例）北九州市若松区"
              value={value}
              onChange={onChange}
              error={props.errors?.city}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={9}>
        <Controller
          name="streetAddress"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <InputField
              label="本社の町域"
              required={true}
              placeholder="例）中川町"
              value={value}
              onChange={onChange}
              error={props.errors?.streetAddress}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={9}>
        <Controller
          name="otherAddress"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <InputField
              label="本社の町域以降"
              required={true}
              placeholder="例）3-2　建物名"
              value={value}
              onChange={onChange}
              error={props.errors?.otherAddress}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3}>
        <Controller
          name="phone"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <InputField
              label="本社の電話番号"
              placeholder="例）000-0000-0000"
              required={false}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={9} />
      <Grid item xs={3}>
        <Controller
          name="fax"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <InputField
              label="本社のFAX番号"
              placeholder="例）000-0000-0000"
              required={false}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={9} />
      <Grid item xs={9}>
        <Controller
          name="email"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <InputField
              label="本社のメールアドレス"
              placeholder="例）info@ecope-pro.co.jp"
              required={false}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};
