import { createSlice } from "@reduxjs/toolkit";
import { fetchCurrentUser } from "./api";
import { createSelector } from "reselect";

/*
  object shape
    notification
      id [String]
      severity [String]
      message [String | object]
      title [String]

    notification of severity
      "error"
      "warning"
      "info"
      "success"
*/

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {
      tenantId: "",
      id: "",
      name: "",
      email: "",
      createdAt: null,
      updatedAt: null,
    },
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアからユーザーを取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.UserSlice;

/**
 * ストアからユーザー情報を取得します。
 */
export const userSelector = createSelector(
  selector,
  (user) => user.data
);

/**
 * ストアから読み込み状態を取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (user) => user.isLoading
);

/**
 * ストアからエラー情報を取得します。
 */
export const errorSelector = createSelector(
  selector,
  (user) => user.error
);

export default userSlice.reducer;
