import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import clsx from "clsx";
import { routes, toSideMenu } from "routes";
import { Copyright } from "views/atoms";
import {
  Alert,
  BeginnerMark,
  FeedbackButton,
  ModalPopup,
  Notifications,
  RouterBreadcrumbs,
  SideMenu,
  SystemAlert,
  UserAccountButton,
} from "views/molecules";
import { FeedbackForm } from "../FeedbackForm";
import { Button as UserAccountButton2 } from "features/User/Account/Button";
import { Notifications as NotificationsFeatures } from "features/Notifications";
import { If } from "views/atoms";

const Main = styled("main")(({ theme, open }) => ({
  backgroundColor: indigo[50],
  minHeight: "100vh",
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0),
  },
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

/**
 * 認証後のページのテンプレートを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  classes,
  open,
  onToggleDrawer,
  children,
  notifications,
  user,
  onClickHomePage,
  isOpenFeedback,
  onOpenFeedback,
  onCloseFeedback,
  submitFeedBack,
  loading,
}) => (
  <div className={classes.root}>
    <CssBaseline />
    <AppBar position="fixed" color="inherit" elevation={1}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onToggleDrawer}
          edge="start"
          sx={{
            marginRight: "15px",
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box className={clsx(classes.toolbar_logo)} onClick={onClickHomePage} />
        <Box
          color="primary.main"
          flex={1}
          fontWeight="fontWeightBold"
          fontSize="18px"
        >
          マネジメントコンソール
        </Box>
        <If condition={process.env.REACT_APP_IS_DEMO === "true"}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: "bold",
            }}
          >
            {"DEMO"}
          </Typography>
        </If>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <BeginnerMark />
          <Notifications
            className={classes.notifications}
            value={notifications}
          />
          {/* <UserAccountButton value={user} /> */}
          <UserAccountButton2 />
          {/* <NotificationsFeatures /> */}
        </Box>
      </Toolbar>
    </AppBar>
    <Drawer
      variant="permanent"
      sx={(theme) => ({
        zIndex: theme.zIndex.appBar - 1,
      })}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      PaperProps={{
        sx: {
          width: open === true ? 270 : 76,
          height: "100vh",
        },
      }}
    >
      <Toolbar />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={(theme) => ({
          minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 10px)`,
          overflowX: "hidden",
        })}
      >
        <Box sx={{ overflow: "none" }}>
          <SideMenu routes={toSideMenu(routes)} open={open} />
          {/* {open === true && (
            <>
              <Divider />
              <FeedbackButton onClick={onOpenFeedback} />
            </>
          )} */}
        </Box>
        {open === true && <Copyright />}
      </Box>
    </Drawer>
    <Main open={open}>
      <Toolbar />
      <SystemAlert />
      {loading && <LinearProgress />}
      <Box p={3}>
        <RouterBreadcrumbs />
        {children}
      </Box>
    </Main>
    <Alert />
    <ModalPopup
      open={isOpenFeedback}
      onClose={onCloseFeedback}
      title="フィードバックの送信"
      subheader={
        <>
          <Typography>
            {
              "フィードバックにご協力ください。皆様からのご意見をお持ちしております。"
            }
          </Typography>
          <Typography>
            {
              "お送りいただいた情報は、プライバシーポリシーと利用規約に基づき、技術的な問題やサービスの改善のために使用します。"
            }
          </Typography>
        </>
      }
      action={
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={onCloseFeedback}
            disabled={loading}
            sx={{
              mr: 2,
            }}
          >
            キャンセル
          </Button>
          <Button
            form="feedback-form"
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            送信
          </Button>
        </>
      }
    >
      <FeedbackForm id="feedback-form" onSubmit={submitFeedBack} />
    </ModalPopup>
  </div>
);
