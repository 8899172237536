import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogActions, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { basicSchema } from "./Validations";

const defaultValues = {
  name: null,
};

/**
 * 役職を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.value 名前
 * @param {boolean} props.open 開閉状態
 * @param {boolean} props.disabled 無効設定
 * @fires NameForm#onClose 閉じる時
 * @fires NameForm#onSubmit 送信時
 * @fires NameForm#onError エラー時
 * @returns {JSX.Element}
 */
export const PositionForm = ({
  value,
  open,
  onClose,
  onSubmit = () => {},
  onError = () => {},
  disabled = false,
}) => {
  const { control, getValues, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      name: value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        name: value,
      });
    }
  }, [reset, open]);

  const onUpdate = async () => {
    const result = trigger();
    if (result) {
      onSubmit(getValues());
    } else {
      onError(formState.errors);
    }
  };

  return (
    <Dialog open={open}>
      <Stack
        spacing={2}
        sx={{
          p: 2,
        }}
      >
        <Controller
          name={`position`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                required={true}
                value={value}
                onChange={onChange}
                placeholder="役職を入力します。"
                error={Boolean(error?.message)}
                helperText={error?.message}
                disabled={disabled}
              />
            );
          }}
        />
      </Stack>
      <DialogActions>
        <Button onClick={onClose} disabled={disabled} color="error">
          {"キャンセル"}
        </Button>
        <Button onClick={onUpdate} autoFocus disabled={disabled}>
          {"保存"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
