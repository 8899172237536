import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debugLog } from "utils/log";
import { useStyles } from "./styles";
import { toggle, selector } from "ducks/Loading";

/**
 * お問い合わせの詳細の表示を行うコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elmentを返すメソッド
 * @param {object} value お問い合わせ情報
 * @fires Container#onSubmitSupport 対応状況送信時
 * @fires Container#onErrorSupport 対応状況エラー時
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  submit = Promise.resolve(),
  onSubmit = () => {},
  onError = (e) => debugLog(e),
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(selector).status;
  const [sortModel, setSortModel] = useState([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const handleChangeSortModel = ({ sortModel }) => {
    setSortModel(sortModel);
  };

  const handleSubmit = async (data) => {
    dispatch(toggle(true));
    submit(data)
      .then((res) => {
        setTimeout(() => {
          onSubmit(res);
          setOpen(false);
          dispatch(toggle(false));
        }, 5000);
      })
      .catch((err) => {
        debugLog(err);
        onError(err);
        dispatch(toggle(false));
      });
  };

  return render({
    classes: classes,
    open: open,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    value: value,
    handleSubmit: handleSubmit,
    handleError: (err) => debugLog(err),
    submitting: isLoading,
    sortModel: sortModel,
    setSortModel: handleChangeSortModel,
    ...props,
  });
};
