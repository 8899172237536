import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSingleDialog } from "utils/useSingleDialog";
import { v4 } from "uuid";

export const ProcedureStatus = ["未開封", "未対応", "期限切れ", "送付エラー"];

const renderState = (params) => {
  const { value } = params;

  return (
    <Box color={value === "2" || value === "3" ? "error.main" : ""}>
      {ProcedureStatus[value]}
    </Box>
  );
};

const rows = [
  {
    id: v4(),
    procedureState: "0",
    sendedTime: "2021/07/01 12:23:05",
    company: "株式会社00000000",
    personInCharge: "000000000000",
    email: "info@greener.co.jp",
  },
  {
    id: v4(),
    procedureState: "1",
    sendedTime: "2021/07/01 12:23:05",
    company: "株式会社00000000",
    personInCharge: "000000000000",
    email: "info@greener.co.jp",
  },
  {
    id: v4(),
    procedureState: "2",
    sendedTime: "2021/07/01 12:23:05",
    company: "株式会社00000000",
    personInCharge: "000000000000",
    email: "info@greener.co.jp",
  },
  {
    id: v4(),
    procedureState: "3",
    sendedTime: "2021/07/01 12:23:05",
    company: "株式会社00000000",
    personInCharge: "000000000000",
    email: "info@greener.co.jp",
  },
];
const columns = [
  {
    field: "procedureState",
    headerName: "手続き状況",
    width: 130,
    renderCell: renderState,
  },
  {
    field: "sendedTime",
    headerName: "送付時間",
    width: 200,
  },
  {
    field: "company",
    headerName: "会社名",
    flex: 1,
  },
  {
    field: "personInCharge",
    headerName: "担当者名",
    width: 200,
  },
  {
    field: "email",
    headerName: "メールアドレス",
    flex: 1,
  },
];

/**
 * 引数を受けて、JSXを返します。
 * @param {func} 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props プロパティ
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dialog = useSingleDialog();

  const handleChangeSelection = (params) => {
    const selected = rows.find((row) => params.includes(row.id));
    navigate(`${location.pathname}/invite/${selected.id}`, {
      formValue: selected,
    });
  };

  const handleChangeDataSubmit = (params) => {
    console.log("params.form.submit: ", params);
  };

  return render({
    rows: rows,
    columns: columns,
    onChangeSelection: handleChangeSelection,
    open: dialog.isOpen,
    onOpenDialog: dialog.handleOpen,
    onCloseDialog: dialog.handleClose,
    onChangeDataSubmit: handleChangeDataSubmit,
    ...props,
  });
};
