import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 内容タブを表示するコンポーネントです
 * @param {object} value お問い合わせの情報
 * @param {object} props プロパティ
 */
export const TabInformation = containerPresententer(Container, Presententer);
