import { Box, Grid } from "@mui/material";
import { Controller, FormProvider } from "react-hook-form";
import { RenderTitleInPopupOrModal } from "views/atoms";
import { InputField } from "views/molecules";

/**
 * ユーザー名とメールアロドレスの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} classes クラス名
 * @param {object} methods useFormのmethods
 * @param {object} control useFormのcontrol
 * @returns JSX Element
 */

export const Presententer = (props) => (
  <>
    <RenderTitleInPopupOrModal
      mainTitle="アカウント登録"
      subTitle={["以下のアカウントで利用手続きを行います。"]}
    />
    <Box mt={3}>
      <FormProvider {...props.methods}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Controller
              control={props.control}
              name="email"
              render={({ field: { value, onChange } }) => (
                <InputField
                  label="メールアドレス"
                  required={false}
                  value={value}
                  disabled={true}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={9}>
            <Controller
              control={props.control}
              name="username"
              render={({ field: { value, onChange } }) => (
                <InputField
                  label="ユーザー名"
                  required={false}
                  disabled={true}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </FormProvider>
    </Box>
  </>
);
