import { onOpen, selector } from "ducks/GuiderSteps/slice";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";

/**
 * 好き数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { visibility } = useSelector(selector);
  
  const handleClickBeginner = () => {
    dispatch(onOpen());
  };

  return render({
    classes: classes,
    onClickBeginner: handleClickBeginner,
    visibility: visibility,
    ...props,
  });
};
