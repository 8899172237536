import { useState } from "react";
import { styles } from "./styles";

const PostalCodeMark = (value) => {
  const x = value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,1})(\d{0,4})/);
  return !x[3] ? x[1] + x[2] : `${x[1]}${x[2]}-${x[3]}`;
};

/**
 * 郵便番号の入力を行うコンテナコンポーネントです。
 * @param {string} label ラベル
 * @param {string} value 郵便番号
 * @fires Container#onChange 変更時
 * @param {boolean} required 必須か
 * @fires Container#onGetFullAddress 全体の住所を取得します。
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onChange,
  label,
  required,
  onGetFullAddress,
  ...props
}) => {
  const [state, setState] = useState(value);
  const [error, setError] = useState("");
  const classes = styles();
  const handleOnchange = (e) => {
    const postCode = PostalCodeMark(e.target.value);
    setState(postCode);
    onChange(postCode);
  };

  const handleSearch = () => {
    const zipcode = state.replace(/\D/g, "");

    fetch(`https://api.zipaddress.net/?zipcode=${zipcode}`, {
      mode: "cors",
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        if (result.code === 200) {
          onGetFullAddress && onGetFullAddress(result?.data);
          setError("");
        } else if (result.code === 404) {
          setError("住所が見つかりませんでした");
          onGetFullAddress && onGetFullAddress("");
        }
      });
  };

  return render({
    classes: classes,
    state: state,
    error: error,
    label: label,
    required: required,
    onChange: handleOnchange,
    onSearchShow: state?.length === 8,
    onSearch: handleSearch,
    ...props,
  });
};
