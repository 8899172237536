import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { InputField } from "views/molecules";
import { PlanSelect, PlanDetails } from "../index";

export const Presententer = (props) => {
  return (
    <form onSubmit={props.handleSubmit} id={props.id} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={1} fontWeight="fontWeightBold" fontSize="15px">
            {props.titie}
          </Box>
          <Box fontSize="13px">{props.description}</Box>
        </Grid>
        <Grid item xs={9}>
          <Controller
            name="companyName"
            control={props.control}
            render={({ field: { value, onChange } }) => (
              <InputField
                label="会社名"
                placeholder="会社名を入力します。"
                required={true}
                value={value}
                onChange={onChange}
                error={props.errors?.companyName}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Controller
            name="nameOfInquirer"
            control={props.control}
            render={({ field: { value, onChange } }) => (
              <InputField
                label="担当者名"
                placeholder="担当者名を入力します。"
                required={true}
                value={value}
                onChange={onChange}
                error={props.errors?.nameOfInquirer}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={9}>
          <Controller
            name="email"
            control={props.control}
            render={({ field: { value, onChange } }) => (
              <InputField
                label="メールアドレス"
                placeholder="例）info@ecope-pro.co.jp"
                required={true}
                value={value}
                onChange={onChange}
                error={props.errors?.email}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={9}>
          <Controller
            name="plan"
            control={props.control}
            render={({ field: { value, onChange } }) => (
              <PlanSelect
                label="プラン"
                required={true}
                value={value}
                options={props.plansType}
                onChange={onChange}
                error={props.errors?.plan}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={9}>
          <PlanDetails planDetails={props.planDetails} />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </form>
  );
};
