import { useState, useEffect } from "react";
import { debugLog } from "utils/log";
import { styles } from "./styles";

/**
 * フリーワード検索を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @fires Container#onSearch 検索値を渡すイベントです。
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  onSearch = (data) => debugLog(data),
  ...props
}) => {
  const classes = styles();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue("");
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      onSearch && onSearch(event.target.value);
      event.preventDefault();
    }
  };

  const handleClickSearch = () => {
    onSearch(value);
  };

  return render({
    classes: classes,
    value: value,
    onChange: handleChange,
    handleKeyDown: handleKeyDown,
    onClickSearch: handleClickSearch,
    ...props,
  });
};
