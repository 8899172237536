import { Box, Button, Grid } from "@mui/material";
import clsx from "clsx";
import { Controller, FormProvider } from "react-hook-form";
import { RenderTitleInPopupOrModal } from "views/atoms";
import { InputField, InputFieldPassword } from "views/molecules";

/**
 * 新しいユーザーの登録の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} classes クラス名 *
 * @param {object} methods useFormのmethods
 * @param {object} control useFormのcontrol
 * @param {object} errors useFormのerrors
 *
 * @param {func} onBackToConfirm 確認段階に戻る
 * @param {func} onResendMailRegsiter 確認メールを再送する
 * @param {func} onRegister 登録ボタンのイベント
 * @returns JSX Element
 */
export const Presententer = (props) => (
  <>
    <RenderTitleInPopupOrModal
      mainTitle="アカウント登録"
      subTitle={["新しいアカウントを登録します。"]}
    />
    <Box mt={3}>
      <FormProvider {...props.methods}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Controller
              control={props.control}
              name="email"
              render={({ field: { value, onChange } }) => (
                <InputField
                  label="メールアドレス"
                  required={false}
                  value={value}
                  disabled={true}
                  onChange={onChange}
                  error={props.errors?.email}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={12}>
            <Box mt={3} display="flex" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={props.onResendMailRegsiter}
                className={props.classes.button}
              >
                再送
              </Button>

              <Box ml={3} fontSize="13px">
                メールアドレス宛にパス―コードを送付しました。確認後に6桁の数字を入力してください。
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="passcode"
              control={props.control}
              defaultValue=""
              render={({ field: { value, onChange } }) => (
                <InputField
                  label="パスコード"
                  placeholder="メールに記載されている6桁の数字を入力してください。"
                  required={true}
                  value={value ?? ""}
                  onChange={onChange}
                  error={Boolean(props.errors?.passcode)}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Controller
              name="username"
              control={props.control}
              defaultValue=""
              render={({ field: { value, onChange } }) => (
                <InputField
                  label="ユーザー名"
                  placeholder="ユーザー名を入力してください。"
                  required={true}
                  value={value ?? ""}
                  onChange={onChange}
                  error={Boolean(props.errors?.username)}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Controller
              name="password"
              control={props.control}
              defaultValue=""
              render={({ field: { value, onChange } }) => (
                <InputFieldPassword
                  label="パスワード"
                  placeholder="パスワードを入力してください。"
                  required={true}
                  value={value ?? ""}
                  onChange={onChange}
                  error={Boolean(props.errors?.password)}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={props.onBackToConfirm}
              className={props.classes.button}
            >
              戻る
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onRegister}
              className={clsx(props.classes.button, props.classes.button_login)}
            >
              登録
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  </>
);
