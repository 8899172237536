import { Avatar } from "@mui/material";

/**
 * 機能を説明するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div className={props.classes.container}>
    <div>
      <Avatar className={props.classes.mark}>{props.mark}</Avatar>
    </div>
    <div className={props.classes.label}>
      <span className={props.classes.name}>{props.name}</span>
      <span className={props.classes.description}>{props.description}</span>
      <div className={props.classes.tags}>
        {props.functionName?.map((tag, index) => (
          <span key={index}>{tag}</span>
        ))}
      </div>
    </div>
  </div>
);
