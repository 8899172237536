/**
 * コピーライトを表示するコンテナコンポーネントです。
 * @param {render} render 引数を受けてJSX Elementを返すメソッド
 * @param {string} systemUrl　システムのURL
 * @param {string} providerName システム名
 * @param {object} ...props その他プロパティ
 */
const Container = ({
  render,
  systemUrl = process.env.REACT_APP_SYSTEM_PROVIDER_URL,
  providerName = process.env.REACT_APP_SYSTEM_PROVIDER_NAME,
  ...props
}) => {
  return render({
    providerName: providerName,
    systemUrl: systemUrl,
    year: new Date().getFullYear(),
    ...props,
  });
};

export default Container;
