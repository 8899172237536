import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 認証後のページのテンプレートを表示するコンポーネントです。
 * @returns {JSX.Element}
 */
export const AuthenticatedTemplate = containerPresententer(
  Container,
  Presententer
);
