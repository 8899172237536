import React from "react";
import { TabsDetails } from "views/molecules";
import { TabInvites, TabAgent } from "views/organisms/Agent";

/**
 * 販売代理店の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return (
    <div>
      <TabsDetails
        tabs={[
          { name: "利用開始手続き", tabContent: <TabInvites /> },
          { name: "販売代理店", tabContent: <TabAgent /> },
        ]}
      />
    </div>
  );
};
