import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  ant_empty_img_1: {
    fill: theme.palette.type === "light" ? "#aeb8c2" : "#262626",
  },
  ant_empty_img_2: {
    fill: theme.palette.type === "light" ? "#f5f5f7" : "#595959",
  },
  ant_empty_img_3: {
    fill: theme.palette.type === "light" ? "#dce0e6" : "#434343",
  },
  ant_empty_img_4: {
    fill: theme.palette.type === "light" ? "#fff" : "#1c1c1c",
  },
  ant_empty_img_5: {
    fillOpacity: theme.palette.type === "light" ? "0.8" : "0.08",
    fill: theme.palette.type === "light" ? "#f5f5f5" : "#fff",
  },
}));
