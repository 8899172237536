import React from "react";
import { XGridComponent } from "views/molecules";

/**
 * 加入者一覧を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div className={props.classes.support_table}>
    <XGridComponent
      onToolbar={false}
      rows={props.rows}
      columns={props.columns}
      disableColumnMenu={true}
      disableSelectionOnClick={true}
    />
  </div>
);
