import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { indigo, grey, amber } from "@mui/material/colors";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

const DivWithSelectedMark = styled("div")({
  "& a.active": {
    backgroundColor: grey[200],
    borderLeft: `${amber[500]} solid 3px`,
  },
});

/**
 * サイドメニューを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const { routes, open } = props;
  return (
    <DivWithSelectedMark>
      <List>
        {routes.map((route, index) => (
          <SideMenuItem
            path={route.path}
            text={open && route.title}
            icon={route.icon}
            key={index}
          />
        ))}
      </List>
    </DivWithSelectedMark>
  );
};

/**
 * サイドメニューのアイテムを表すプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const SideMenuItem = ({ path, text, icon }) => {
  const renderLink = useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <NavLink to={path} ref={ref} {...itemProps} />
      )),
    [path]
  );
  return (
    <li>
      <ListItem
        button
        component={renderLink}
        sx={{
          ml: 1,
        }}
      >
        {icon ? (
          <ListItemIcon style={{ color: indigo[200], minWidth: "40px" }}>
            {icon}
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={text}
          sx={{
            color: grey[600],
          }}
          primaryTypographyProps={{
            sx: {
              fontSize: "14px",
            },
          }}
        />
      </ListItem>
    </li>
  );
};
