import { makeStyles } from "@mui/styles";
import { overideStyleButton } from "utils/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: "765px",
  },
  box_feedback: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      marginLeft: 0,
    },
    "& .MuiSwitch-root": {
      marginLeft: theme.spacing(2),
    },
  },
  button: {
    ...overideStyleButton(theme),
  },
  button_cancel: {
    marginRight: theme.spacing(2),
  },
  box_screenshot_img: {
    width: "40%",
  },
}));
