import { API, graphqlOperation } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { debugLog } from "./log";

/**
 * クエリを呼びます。
 * @param {object} props プロパティ
 * @param {string} query クエリ
 * @param {object} variables 引数
 * @param {boolean} skip 初回読み込みをスキップするか
 * @fires useQuery#onCompleted 完了時
 * @fires useQuery#onError エラー時
 * @returns {boolean} loading 読み込み状態
 * @returns {string} error エラー
 * @returns {object} data データ
 * @returns {func} refetch 再取得関数
 */
export const useQuery = ({
  query,
  variables,
  onCompleted = (data) => debugLog(data),
  onError = (err) => debugLog(err),
  skip = false,
}) => {
  const [options, setOptions] = useState({
    loading: false,
    error: null,
    data: null,
  });

  const fetch = useCallback(
    (variables) => {
      setOptions((prevState) => ({
        ...prevState,
        loading: true,
      }));

      debugLog("========================================");
      debugLog(" - クエリの実行：");
      debugLog("   - クエリ：", query);
      debugLog("   - 引数：", variables);

      return API.graphql(graphqlOperation(query, variables))
        .then((res) => {
          debugLog(" - クエリの成功：", res);
          setOptions((prevState) => ({
            ...prevState,
            error: null,
            data: res.data,
          }));
          onCompleted({ data: res.data });
        })
        .catch((err) => {
          debugLog(" - クエリの失敗：", err);
          setOptions((prevState) => ({
            ...prevState,
            error: err,
            data: null,
          }));
          onError({ error: err });
        })
        .finally(() => {
          setOptions((prevState) => ({
            ...prevState,
            loading: false,
          }));
          debugLog(" - クエリ実行終了");
          debugLog("========================================");
        });
    },
    [query]
  );

  const refetch = (newVariables = variables) => {
    return fetch(newVariables);
  };

  useEffect(() => {
    if (!skip) {
      fetch(variables);
    }
  }, [fetch, JSON.stringify(variables)]);

  return {
    ...options,
    refetch: refetch,
  };
};
