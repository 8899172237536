import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import { Chip } from "@mui/material";

/**
 * 開封済Chipを表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Opened = () => {
  return (
    <Chip icon={<DraftsOutlinedIcon />} label={"開封済"} color="warning" />
  );
};
