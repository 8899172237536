import { useStyles } from "./styles";

/**
 * 販売プランの内容タブを表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, basePlans, services, value, ...props }) => {
  const classes = useStyles();

  return render({
    basePlan: basePlans?.find((plan) => plan.id === value.basePlanId),
    value: value,
    classes: classes,
    services: services,
    ...props,
  });
};
