import { Box } from "@mui/material";
import { InvitationStatusList } from "features/Subscribers/InvitationStatusList";
import { List } from "features/Subscribers/List";
import { useState, useEffect } from "react";
import { Tabs } from "views/molecules";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";

/**
 * 加入者管理の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 */
export const Presententer = ({
  onTransiteInviteDetails,
  onTransiteSubscriberDetails,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);

  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    setTab(qs.get("tab") ? parseInt(qs.get("tab")) : 0);
  }, [location.search]);

  const handleChangeTab = (tabNumber) => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({ tab: tabNumber })}`,
      },
      {
        replace: true,
      }
    );

    setTab(tabNumber);
  };

  return (
    <Box>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        options={[
          {
            label: "利用開始手続き",
            value: 0,
            content: (
              <InvitationStatusList onSelected={onTransiteInviteDetails} />
            ),
          },
          {
            label: "加入者",
            value: 1,
            content: <List onSelected={onTransiteSubscriberDetails} />,
          },
        ]}
      ></Tabs>
    </Box>
  );
};
