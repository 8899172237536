import { Box } from "@mui/material";
import React from "react";
import { PlanDetail } from "../PlanDetail";

/**
 * プラン詳細リストで表示するプレゼンテーションコンポーネントです。
 * @returns {JSX.Element}
 */
export const Presententer = (props) =>
  props.planDetails?.length >= 0 && (
    <Box className={props.classes.plans}>
      {props.value.map((plan, index) => (
        <PlanDetail value={plan} key={index} />
      ))}
    </Box>
  );
