import { Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useSingleDialog } from "utils/useSingleDialog";

/**
 * 手続き状態を表します。
 */
const STATUS = [
  {
    id: 0,
    name: "未開封",
  },
  {
    id: 1,
    name: "未対応",
  },
  {
    id: 2,
    name: "期限切れ",
    color: "error",
  },
  {
    id: 3,
    name: "送付エラー",
    color: "error",
  },
];

/**
 * カラムの定義を表します。
 */
const columns = [
  {
    field: "procedureState",
    headerName: "手続き状況",
    width: 130,
    renderCell: ({ value }) => {
      const status = STATUS[value];
      return <Typography color={status.color}>{status.name}</Typography>;
    },
  },
  {
    field: "sendedAt",
    headerName: "送付日時",
    width: 200,
  },
  {
    field: "companyName",
    headerName: "会社名",
    flex: 1,
  },
  {
    field: "nameOfInquirer",
    headerName: "担当者名",
    width: 200,
  },
  {
    field: "email",
    headerName: "メールアドレス",
    flex: 1,
  },
  {
    field: "seller",
    headerName: "販売者",
  },
];

/**
 * 利用開始手続きを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elmentを返すメソッド
 * @param {array} rows 行データ
 * @returns {JSX.Element}
 */
export const Container = ({ render, rows = [] }) => {
  const dialog = useSingleDialog();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (params) => {
    navigate(`${location.pathname}/${params[0]}`, {
      formValue: params[0],
    });
  };

  return render({
    columns: columns,
    rows: rows,
    handleClick: handleClick,
    onOpenDialog: dialog.handleOpen,
    onCloseDialog: dialog.handleClose,
    open: dialog.isOpen,
  });
};
