/**
 * サイドメニューを表示するコンテナコンポーネントです。
 * @param {render} render 引数を受けて、JSX Elementを返します。
 * @param {array} routes システムのパス情報
 * @param {boolean} open 開閉状態
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, routes, open = true, ...props }) => {
  return render({ routes: routes, open: open, ...props });
};
