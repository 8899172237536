import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 利用開始手続き詳細を表示するコンポーネントです
 * @param {object} props プロパティ
 * @param {object} value お問い合わせ情報
 */
export const InviteDetails = containerPresententer(Container, Presententer);
