import { LicenseInfo } from "@mui/x-license-pro";
import Amplify from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import { pdfjs } from "react-pdf";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import amplifyConfig from "./api/aws-exports";
import routes from "./routes";
import {
  AuthenticatedTemplate,
  AuthenticatedFoundation,
} from "./views/organisms";
import {
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  VerifyContact,
} from "./views/pages/Auth";
import { MaintenanceMonitor } from "views/atoms/MaintenanceMonitor";
import { CacheBuster } from "views/atoms/CacheBuster"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_LICENSE_KEY);

Amplify.configure(amplifyConfig);

const AuthenticatedRoutes = () => {
  return (
    <BrowserRouter>
      <AuthenticatedFoundation>
        <AuthenticatedTemplate>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
            <Route
              exact
              path="/maintenance"
              render={() => {
                window.location.href = "/maintenance.html";
              }}
            />
          </Routes>
        </AuthenticatedTemplate>
      </AuthenticatedFoundation>
    </BrowserRouter>
  );
};

const Contents = withAuthenticator(AuthenticatedRoutes, false, [
  <SignIn />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <ForgotPassword />,
  <RequireNewPassword />,
]);

const App = () => {
  return (
    <>
      <CacheBuster/>
      <MaintenanceMonitor />
      <Contents />
    </>
  );
};

export default App;
