import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "utils/TemporaryModules";
import { schema } from "./schema";
import { useEffect } from "react";

const defaultValues = {
  currentPassword: null,
  newPassword: null,
};

/**
 * パスワード変更フォームを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @fires PasswordChangeForm#onClose 閉じる時
 * @fires PasswordChangeForm#onCompleted 完了時
 * @fires PasswordChangeForm#onError エラー時
 * @returns {JSX.Element}
 */
export const PasswordChangeForm = ({
  value = null,
  open = false,
  onClose = () => {},
  onCompleted = () => {},
  onError = console.log,
}) => {
  const { control, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        ...value,
      });
    }
  }, []);

  const [passwordChangeMutation, passwordChangeMutationStatus] = useMutation(
    "パスワード変更api",
    {
      onCompleted: (data) => {
        onCompleted();
        onClose();
      },
      succeedMessage: "パスワード変更しました。",
      errorMessage: "エラーが発生したため、パスワード変更に失敗しました。",
    },
    false,
    null,
    null
  );

  const handleChangePassword = async () => {
    const result = await trigger();

    if (result) {
      passwordChangeMutation({
        input: {
          id: value?.id,
        },
      });
    } else {
      onError(formState.errors);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>パスワード変更</DialogTitle>
      <Stack
        spacing={2}
        sx={{
          p: 2,
        }}
      >
        <Controller
          name={`currentPassword`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                required={true}
                value={value}
                onChange={onChange}
                type="password"
                placeholder="現在のパスワード"
                label="現在のパスワード"
                error={Boolean(error?.message)}
                helperText={error?.message}
                disabled={passwordChangeMutationStatus.loading}
              />
            );
          }}
        />
        <Controller
          name={`newPassword`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                required={true}
                value={value}
                onChange={onChange}
                type="password"
                placeholder="新しいパスワード"
                label="新しいパスワード"
                error={Boolean(error?.message)}
                helperText={error?.message}
                disabled={passwordChangeMutationStatus.loading}
              />
            );
          }}
        />
      </Stack>
      <DialogActions>
        <Button
          color="error"
          disabled={passwordChangeMutationStatus.loading}
          onClick={onClose}
        >
          キャンセル
        </Button>
        <Button onClick={handleChangePassword}>変更</Button>
      </DialogActions>
    </Dialog>
  );
};
