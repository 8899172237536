import reducer from "./slice";
import {
  userSelector,
  isLoadingSelector,
  errorSelector,
  selector,
} from "./slice";
import { fetchCurrentUser } from "./api";

export default reducer;

export {
  selector,
  userSelector,
  isLoadingSelector,
  errorSelector,
  fetchCurrentUser,
};
