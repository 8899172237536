import { Box, TextField } from "@mui/material";

/**
 *　/**
 * テキストフィルドの表示を行うプレゼンテーションコンポーネントです。
 * @param {string} label テキストのレベル
 * @param {object} classes クラス名
 * @param {funct} onChange データ更新を処理する
 * @param {boolean} required 必須かどうか設定
 * @param {string} value テキストフィールドのバリュー
 */
export const Presententer = ({
  label,
  onChange,
  value,
  classes,
  required = false,
  ...props
}) => {
  const plan = value
    .map((v) => {
      const price = new Intl.NumberFormat().format(v.basicCharge);
      return `${v.name}　${price}円/月　${v.trialDay ? "(トライアル)" : ""}`;
    })
    .join("、");

  return (
    <Box display="flex" flexDirection="column" fontSize="14px">
      <div className={classes.label}>
        {label} {required ? <span className={classes.required}>必須</span> : ""}
      </div>
      <TextField
        size="small"
        variant="outlined"
        value={plan}
        // onChange={onChange}
        className={classes.style_inputField}
        {...props}
      />
    </Box>
  );
};
