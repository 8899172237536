/**
 * @type {array}
 * 権限を表します。
 */
export const Authorities = [
  {
    id: 1,
    name: "オーナー",
  },
  {
    id: 2,
    name: "管理者",
  },
];

/**
 * @type {array}
 * 権限名を取得します。
 */
export const RoleNames = ["オーナー", "管理者"];
