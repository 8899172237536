import { makeStyles } from "@mui/styles";

export const stylesCreator = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  form: {
    padding: theme.spacing(5),
  },
}));
