import React from "react";

/**
 * タグ表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) =>
  props.children && (
    <span
      style={{
        backgroundColor: props.backgroundcolor,
        borderRadius: props.borderradius,
        color: props.color,
        fontSize: props.fontSize,
        margin: props.margin,
        padding: props.padding,
        ...props.otherstyle,
      }}
      {...props}
    >
      {props.children}
    </span>
  );
