import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

/**
 * チェックボックスを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <FormControlLabel
      label={props.label}
      disabled={props.disabled}
      classes={props.classes}
      labelPlacement={props?.labelPlacement ?? "top"}
      inputRef={props.inputRef}
      control={
        <Switch name={props.name} color="primary" checked={props.checked} onChange={props.onChange} />
      }
    />
  );
};
