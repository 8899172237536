import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { Selector as PlanSelector } from "features/Plan/Selector";
import { Controller, useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import { useMutation } from "utils/useMutation";
import { useQuery } from "utils/useQuery";
import { useOpener } from "utils/useOpener";
import { InformationItem } from "views/atoms/InformationItem";
import { Labeling } from "views/atoms/Labeling";
import { DetailsPageFrame } from "views/organisms/DetailsPageFrame";
import { Canceled, InTrial, Subscribed } from "../StatusChips";
import { basic } from "./basic";
import { If } from "views/atoms";
import { ConfirmDialog, XGridComponent } from "views/molecules";
import { useParams } from "react-router-dom";
import { getPlanSubscriber } from "api/graphql/queries";
import { toggle } from "ducks/Loading";
import { useDispatch } from "react-redux";
import { changePlan, cancelPlanSubscriber } from "api/graphql/mutations";
import { formatISODateToText, formatAPIDateTimeToText } from "utils/formats";
import { listWorkplacesTotals } from "api/graphql/queries";

const columns = [
  {
    field: "calculatedOn",
    headerName: "算出日",
    width: 150,
    renderCell: (params) =>
      formatISODateToText({ value: params.value, formatString: "yyyy/MM/dd" }),
  },
  {
    field: "count",
    headerName: "有効事業所数",
    type: "number",
    width: 120,
    renderCell: (params) =>
      (params?.row?.workplaceCount ?? 0) +
      (params?.row?.clientWorkplaceCount ?? 0),
    sortable: false,
  },
];

const defaultValues = {
  plans: null,
};

/**
 * 加入者の詳細を表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const updateDialog = useOpener();
  const cancelDialog = useOpener();

  const workplacesTotals = useQuery({
    query: listWorkplacesTotals,
    variables: {
      planSubscriberId: id,
      sortDirection: "DESC",
    },
    onCompleted: () => {},
    onError: () => {},
  });

  const [updatePlanMutation, updatePlanMutationStatus] = useMutation(
    changePlan,
    {
      onCompleted: () => {
        subscriber.refetch();
        updateDialog.toggle(false);
      },
      succeedMessage: "変更しました。",
      errorMessage: "エラーが発生したため、変更に失敗しました。",
    }
  );

  const [cancelMutation, cancelMutationStatus] = useMutation(
    cancelPlanSubscriber,
    {
      onCompleted: () => {
        subscriber.refetch();
        cancelDialog.toggle(false);
      },
      succeedMessage: "解約しました。",
      errorMessage: "エラーが発生したため、解約に失敗しました。",
    }
  );

  const { control, trigger, getValues, formState, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(basic),
  });

  const subscriber = useQuery({
    query: getPlanSubscriber,
    variables: {
      id: id,
    },
    onCompleted: (data) => {
      reset({
        plans: data?.getPlanSubscriber?.plan,
      });
    },
  });

  dispatch(toggle(subscriber.loading));

  const handleDecidePlan = async () => {
    const result = await trigger();

    if (result) {
      const values = getValues();
      updatePlanMutation({
        input: {
          planSubscriberId: id,
          planId: values?.plans?.id,
        },
      });
    } else {
      debugLog("検証に失敗: ", formState.errors);
    }
  };

  return (
    <DetailsPageFrame
      information={
        <Stack spacing={2}>
          {subscriber?.data?.getPlanSubscriber?.state === "subscribed" && (
            <Subscribed />
          )}
          {subscriber?.data?.getPlanSubscriber?.status === "unsubscribed" && (
            <Canceled />
          )}
          <Typography variant="h6">
            {subscriber?.data?.getPlanSubscriber?.companyName ?? ""}
          </Typography>
        </Stack>
      }
      action={
        <If
          condition={
            subscriber?.data?.getPlanSubscriber?.state === "subscribed"
          }
        >
          <Button
            variant="contained"
            color="error"
            size="large"
            onClick={() => cancelDialog.toggle(true)}
            disabled={
              cancelMutationStatus.loading ||
              updatePlanMutationStatus.loading ||
              subscriber.loading
            }
          >
            解約する
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => updateDialog.toggle(true)}
            disabled={
              cancelMutationStatus.loading ||
              updatePlanMutationStatus.loading ||
              subscriber.loading
            }
          >
            提供するプランを変更する
          </Button>
        </If>
      }
    >
      <Stack spacing={2}>
        <Paper
          sx={{
            p: 2,
            mt: 1,
          }}
        >
          <Stack spacing={2}>
            <InformationItem label="加入プラン">
              {subscriber?.data?.getPlanSubscriber?.plan?.name ?? ""}
            </InformationItem>
            <InformationItem label="加入日時">
              {formatAPIDateTimeToText({
                value: subscriber?.data?.getPlanSubscriber?.startedUsingAt,
              })}
            </InformationItem>
            <InformationItem label="会社名">
              {subscriber?.data?.getPlanSubscriber?.companyName ?? ""}
            </InformationItem>
            <InformationItem label="担当者">
              {subscriber?.data?.getPlanSubscriber?.contactPersonnel ?? ""}
            </InformationItem>
          </Stack>
        </Paper>
        <Paper
          sx={{
            p: 2,
            mt: 1,
          }}
        >
          <XGridComponent
            columns={columns}
            checkboxSelection={false}
            multipleSelection={false}
            onDownload={false}
            onFilter={false}
            onColumns={false}
            rows={
              workplacesTotals?.data?.listWorkplacesTotals?.items?.map(
                (i, index) => ({ id: index, ...i })
              ) ?? []
            }
          />
        </Paper>
      </Stack>
      <Dialog
        open={updateDialog.open}
        onClose={
          updatePlanMutationStatus.loading === true
            ? null
            : () => updateDialog.toggle(false)
        }
      >
        <DialogTitle>プラン変更</DialogTitle>
        <DialogContent>
          <DialogContentText>
            変更するプランを選択してください。
          </DialogContentText>
        </DialogContent>
        <Box
          sx={{
            pl: 2,
          }}
        >
          <Controller
            name="plans"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Labeling label="プラン" required={true}>
                <PlanSelector
                  required={true}
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                  disabled={updatePlanMutationStatus.loading}
                />
              </Labeling>
            )}
          />
        </Box>
        <DialogActions>
          <Button
            disabled={updatePlanMutationStatus.loading}
            onClick={() => updateDialog.toggle(false)}
            color="error"
          >
            キャンセル
          </Button>
          <Button
            disabled={updatePlanMutationStatus.loading}
            onClick={handleDecidePlan}
          >
            変更
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title="確認"
        message="解約します。よろしいですか？"
        open={cancelDialog.open}
        onPositive={() =>
          cancelMutation({
            input: {
              id: id,
            },
          })
        }
        onNegative={() => cancelDialog.toggle(false)}
        positiveText="はい"
        negativeText="いいえ"
        disabled={cancelMutationStatus.loading}
      />
    </DetailsPageFrame>
  );
};
