import { makeStyles } from "@mui/styles";
import { amber, grey, indigo } from "@mui/material/colors";

export const useStyles = makeStyles((theme) => ({
  button_feedback: {
    position: "relative",
    justifyContent: "flex-start",
    padding: "9.5px 20px",
    marginTop: theme.spacing(2),
    borderRadius: "0",
    "&:hover": {
      "&::before": {
        backgroundColor: amber[500],
      },
    },
    "& .MuiButton-startIcon": {
      color: indigo[200],
      marginRight: "15px",
      "& .MuiSvgIcon-root": { fontSize: "24px" },
    },
    "& .MuiButton-label": {
        color: grey[700]
    }
  },
}));
