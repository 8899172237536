import { Backdrop, Fade, Modal } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

/**
 * モーダルの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={props.classes.modal}
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Card
          sx={(theme) => ({
            zIndex: theme.zIndex.modal + 1,
            p: 2,
          })}
        >
          <CardHeader
            avatar={props.avatar}
            action={props.headerAction}
            title={props.title}
            subheader={props.subheader}
          />
          <CardContent
            classes={{
              root: props.classes.content,
            }}
          >
            {props.children}
          </CardContent>
          <CardActions disableSpacing>{props.action}</CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};
