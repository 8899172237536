import { useStyles } from "./styles";

export const Container = ({ render, ...props }) => {
  const classes = useStyles();

  return render({
    classes: classes,
    ...props,
  });
};
