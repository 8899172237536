import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  box_wraper_left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  box_logo: {
    paddingTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    backgroundImage: `url(${window.location.origin}/layered.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  linkRouter: {
    color: theme.palette.info.main,
    fontSize: "14px",
  },
  box_copyRight: {
    textAlign: "center",
    paddingTop: theme.spacing(2),
    fontSize: "12px",
  },
  buttonStyle: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}));
