import { makeStyles } from "@mui/styles";

export const createStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    lineHeight: "24px",
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    "& .cellValue": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  support_table: {},
  text_content: {
    width: "100%",
    padding: theme.spacing(1),
    "& .Mui-disabled": {
      color: "rgba(0,0,0,.87)",
      width: "100%",
      height: "100%",
      padding: 0,
      lineHeight: "23px",
      "&::before": {
        borderBottomStyle: "none",
      },
    },
  },
}));
