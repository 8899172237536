import { Paper } from "@mui/material";
import { Table } from "features/Member/Table";
import { DetailsPageFrame } from "views/organisms/DetailsPageFrame";

export const Members = ({ onClick }) => {
  return (
    <DetailsPageFrame information={<></>}>
      <Paper
        sx={{
          m: 1,
          p: 2,
        }}
      >
        <Table onSelected={onClick} />
      </Paper>
    </DetailsPageFrame>
  );
};
