import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { selector } from "ducks/Plans";
import { useSelector } from "react-redux";

/**
 * プランを選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.label ラベル
 * @param {object | string} props.value プラン情報 または 空文字
 * @param {boolean} props.error エラー
 * @param {string} props.helperText 補助的テキスト
 * @fires Selector#props.onChange 変更時処理
 * @param {boolean} props.disabled 無効設定
 * @returns {JSX.Element}
 */
export const Selector = ({
  label = "",
  value = null,
  error = false,
  helperText = "",
  onChange = () => {},
  disabled = false,
}) => {
  const plans = useSelector(selector);

  const handleChange = (event) => {
    onChange(
      plans?.value.find((plan) => plan.id === event.target.value) ?? null
    );
  };

  if (plans.isLoading) {
    return <Skeleton />;
  }

  if (plans.error) {
    return (
      <Typography color={"error.main"}>{"エラーが発生しました。"}</Typography>
    );
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} error={error} disabled={disabled}>
      <InputLabel id="label">{label}</InputLabel>
      <Select
        labelId="label"
        id="plan-selector"
        value={value?.id ?? null}
        label={label}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>未選択</em>
        </MenuItem>
        {plans?.value?.map((plan, index) => (
          <MenuItem key={index} value={plan.id}>
            {plan.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
