import { useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { debugLog } from "./log";

/**
 * サブスクリプションを実行します。
 * @param {object} subscription サブスクリプションクエリ
 * @param {object} options オプション
 * @fires graphQLSubscription#callback コールバック
 * @returns {object}
 */
export const graphQLSubscription = (subscriptionQuery, options, callback) => {
  return API.graphql(graphqlOperation(subscriptionQuery, options)).subscribe({
    next: ({ value: { data, errors } }) => {
      debugLog("graphQLSubscription", errors);
      callback(data);
    },
  });
};

/**
 * サブスクリプションを実行するHookです。
 * @param {object} subscription サブスクリプションクエリ
 * @param {object} options オプション
 * @fires graphQLSubscription#callback コールバック
 */
export const useGraphQLSubscription = (
  subscriptionQuery,
  options,
  callback
) => {
  useEffect(() => {
    const subscription = graphQLSubscription(
      subscriptionQuery,
      options,
      callback
    );

    return () => subscription.unsubscribe();
  }, []);
};
