import { useState } from "react";
import { styles } from "./styles";

/**
 * パスワードフィールドの表示を行うコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = styles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return render({
    classes: classes,
    showPassword: showPassword,
    onClickShowPassword: handleClickShowPassword,
    onMouseDownPassword: handleMouseDownPassword,
    ...props,
  });
};
