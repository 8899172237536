import { Box, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";

export const InformationItem = ({ label, children }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        borderBottom: `1px solid ${grey[300]}`,
      }}
    >
      <Stack
        sx={{
          backgroundColor: grey[300],
          height: 40,
          minWidth: "230px",
          padding: "10px 5px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {label}
      </Stack>
      {typeof children === "string" ? (
        <Stack
          justifyContent="center"
          alignItems={"center"}
          sx={{
            whiteSpace: "pre-wrap",
          }}
        >
          {children}
        </Stack>
      ) : (
        <Box>{children}</Box>
      )}
    </Stack>
  );
};
