import { Auth } from "aws-amplify";
import { useState } from "react";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";

/**
 * ユーザーアカウントボタンを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value ユーザー
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [managementAccount, setManagementAccount] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    await Auth.signOut();
    navigate(
      {
        pathname: "/",
      },
      {
        replace: true,
      }
    );
    navigate(0);
  };

  return render({
    ...props,
    classes: classes,
    anchorEl: anchorEl,
    value: value,
    onOpenMenu: handleOpenMenu,
    onCloseMenu: handleCloseMenu,
    signOut: signOut,
    managementAccount: managementAccount,
    setManagementAccount: setManagementAccount,
  });
};
