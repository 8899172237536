import { Box, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Tag } from "../Tag";

/**
 * ラベリングするコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.label ラベル
 * @param {boolean} props.required 必須であるか
 * @returns {JSX.Element}
 */
export const Labeling = ({ label = null, required = false, children }) => {
  return (
    <Stack spacing={0.5}>
      <Stack spacing={2} direction="row">
        {label && <Box color={grey[600]}>{label}</Box>}
        {required === true && (
          <Box>
            <Tag>{"必須"}</Tag>
          </Box>
        )}
      </Stack>
      <Box>{children}</Box>
    </Stack>
  );
};
