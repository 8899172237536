import React, { useState } from "react";
import { isEmpty, ConsoleLogger as Logger } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import { makeStyles } from "@mui/styles";
import {
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import { AuthenticationTemplate } from "../../organisms";

const logger = new Logger("RequireNewPassword");

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const initialState = {
  password: "",
};

const objectWithProperties = (obj, keys) => {
  const target = {};
  for (const key in obj) {
    if (keys.indexOf(key) === -1) {
      continue;
    }
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }
    target[key] = obj[key];
  }
  return target;
};

export const RequireNewPassword = (props) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const _validAuthStates = ["requireNewPassword"];

  const setInput = (key, value) => setFormState({ ...formState, [key]: value });

  const submit = () => {
    setError("");
    setLoading(true);
    const user = props.authData;
    const { password } = formState;
    const { requiredAttributes } = user.challengeParam;
    const attrs = objectWithProperties(formState, requiredAttributes);

    Auth.completeNewPassword(user, password, attrs)
      .then((user) => {
        logger.debug("complete new password", user);
        if (user.challengeName === "SMS_MFA") {
          props.onStateChange("confirmSignIn", user);
        } else {
          Auth.verifiedContact(user).then((data) => {
            if (!isEmpty(data.verified)) {
              props.onStateChange("signedIn", user);
            } else {
              user = Object.assign(user, data);
              props.onStateChange("verifyContact", user);
            }
          });
        }
      })
      .catch((err) => {
        logger.debug(err);
        setError("エラーが発生しました。");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    _validAuthStates.includes(props.authState) && (
      <AuthenticationTemplate>
        <Typography component="h2" variant="h5">
          新しいパスワードの設定
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={formState.password}
            onChange={(event) => setInput("password", event.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            disabled={loading}
            onClick={submit}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "設定"}
          </Button>
          {error && (
            <Typography color="error" paragraph>
              {error}
            </Typography>
          )}
          <Link
            variant="body2"
            component="button"
            onClick={() => props.onStateChange("signIn")}
          >
            ログインに戻る
          </Link>
        </form>
      </AuthenticationTemplate>
    )
  );
};

export default RequireNewPassword;
