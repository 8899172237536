import React from "react";
import { Box, Grid, Link } from "@mui/material";
import { Copyright } from "views/atoms";

/**
 * 認証画面のテンプレートを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Grid container component="main" className={props.classes.root}>
    <Grid item xs={12} sm={8} md={5}>
      <Box className={props.classes.box_wraper_left}>
        <Box className={props.classes.box_logo} pt={4}>
          <img
            src={`${window.location.origin}/logo.png`}
            alt="logo"
            style={{
              width: "350px",
            }}
          />
          <Box color="primary.main" fontSize="24px" fontWeight="fontWeightBold">
            {"マネジメントコンソール" +
              (process.env.REACT_APP_IS_DEMO === "true" ? "DEMO" : "")}
          </Box>
        </Box>
        <Box>{props.children}</Box>
        <Box pb={4}>
          <Box display="flex" justifyContent="flex-end">
            <Link
              href="#"
              onClick={props.onClickPrivacyPolicy}
              className={props.classes.linkRouter}
              style={{ marginRight: "32px" }}
            >
              プライバシーポリシー
            </Link>
            <Link
              href="#"
              className={props.classes.linkRouter}
              onClick={props.onClickTermsUse}
            >
              サービス利用規約
            </Link>
          </Box>
          <Box className={props.classes.box_copyRight}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12} sm={4} md={7} className={props.classes.image} />
  </Grid>
);
