const enableDebugLog =
  process.env.NODE_ENV === "development" &&
  console &&
  typeof console.log != "undefined";

/**
 * デバッグ環境時に表示されるログです。
 * @param {object} messages ログ表示内容
 */
export const debugLog = (messages) => {
  if (enableDebugLog) {
    console.log(messages);
  }
};


/**
 * デバッグ環境時に表示される情報ログです。
 * @param  {...any} messages ログ表示内容
 */
export const infoDebugLog = (...messages) => {
  if (enableDebugLog) {
    console.log(
      `%c[情報]\n${messages}%c`,
      "padding: 5px; background-color: navy; color: white;",
      ""
    );
  }
};