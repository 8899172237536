import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ログインの段階を表示するコンポーネントです。
 * @param {object} value ユーザーのメールアドレス
 * @param {func} onBack 確認の段階に戻る
 * @param {func} onnNext ログインの段階に移動する
 */
export const EmailLogin = containerPresententer(Container, Presententer);
