import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * タブデフォルトを表示するコンテナプレゼンテーションです
 * @param {func} getTab タブのナンバーを取得する
 * @param {array} tabs タブの内容の配列　例）[{name : "", tabContent : <tabInfor/> }]
 * @param {object} tabs.name タブの内容
 * @param {object} tabs.tabContent タブの内容
 */
export const TabsDetails = containerPresententer(Container, Presententer);
