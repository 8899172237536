import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 初心者の案内ポップアップを表示するコンポーネントです。
 * @param {array} value 案内のステップ　例 [element: "",position : "",intro: "",]
 * @returns {JSX.Element}
 */
export const StepGuider = containerPresententer(Container, Presententer);
