import { useStyles } from "./styles";

/**
 * プランの詳細を表示するコンテナコンポーネントです。
 * @param {func} 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} planDetails プラン詳細情報
 * @returns {JSX.Element}
 */
export const Container = ({ render, planDetails, ...props }) => {
  const classes = useStyles();

  return render({
    classes: classes,
    planDetails: planDetails,
    ...props,
  });
};
