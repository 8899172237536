import { Badge, IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import React from "react";

/**
 * お知らせの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <IconButton
    onClick={props.onClick}
    className={props.classes.box_icon_notifications}
  >
    <StyledBadge
      badgeContent={props.value?.length ?? 0}
      className={props.classes.icon_notifications}
      color="secondary"
    >
      <NotificationsIcon />
    </StyledBadge>
  </IconButton>
);

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 2,
    top: 2,
  },
}))(Badge);
