import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  visibilityIcon: { opacity: 0.4 },
  beginner_mark: {
    width: "25px",
    height: "25px",
    opacity: " 0.4",
  },
}));
