import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { minutesToMilliseconds, useInterval } from "utils/useInterval";
import {
  versionCompare,
  EQUAL_TO,
  LESS_THAN,
  GREATER_THAN,
} from "./VersionCompare";
import packageJson from "../../../../package.json";

/**
 * キャッシュを破壊するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.url キャッシュを破壊する基準とするリソースのURL
 * @returns {JSX.Element}
 */

const url = `${process.env.PUBLIC_URL}/cache-busting.txt`;
export const CacheBuster = () => {
  const [codeHasChanged, setCodeHasChange] = useState(false);

  console.info(`システムバージョン: ${packageJson.version}`);

  const fetchSource = () => {
    return fetch(`${url}?${DateTime.now().toFormat("yyyymmddhhmmss")}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("offline");
        }
        return response.text();
      })
      .then((version) => {
        switch (versionCompare(packageJson.version, version)) {
          case EQUAL_TO:
            break;
          case GREATER_THAN:
          case LESS_THAN:
            setCodeHasChange(true);
            return;
          default:
            break;
        }
      });
  };
  useEffect(() => {
    fetchSource();
  }, []);

  const reload = (e) => {
    window.location.reload();
    e?.preventDefault?.();
  };

  useInterval(() => {
    fetchSource();
  }, minutesToMilliseconds(1));

  if (!codeHasChanged) {
    return <></>;
  }

  return (
    <Dialog
      open={codeHasChanged}
      aria-labelledby="update-notification-title"
      aria-describedby="update-notification-description"
    >
      <DialogTitle id="update-notification-title">
        {"システム更新通知"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="update-notification-description">
          {"システムが最新版に更新されました。"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={reload} autoFocus>
          {"再読み込み"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
