import { Button } from "@mui/material";
import React from "react";
import { XGridComponent } from "views/molecules";
import { AgentInviteDialog } from "../AgentInviteDialog";

/**
 * 利用開始手続きタブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @param {array} rows テーブルの内容
 * @param {array} columns テーブルのヘダータイトル
 * @param {func} onChangeSelection 選択された行を取得する
 * @param {boolean} open ダイアログを有効か無効にする
 * @param {func} onOpenDialog ダイアログを開くイベント
 * @param {func} onCloseDialog ダイアログを閉じるイベント
 * @param {func} onChangeDataSubmit 登録されたデータを取得するイベント
 */
export const Presententer = (props) => {
  return (
    <div>
      <XGridComponent
        rows={props.rows}
        columns={props.columns}
        onChangeSelection={props.onChangeSelection}
        onColumns={false}
        onFilter={true}
        onDownload={false}
        disableColumnMenu={true}
        actionButton={
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={props.onOpenDialog}
          >
            新しい加入者に利用手続きを送付
          </Button>
        }
      />
      <AgentInviteDialog
        open={props.open}
        onCloseDialog={props.onCloseDialog}
        onChangeDataSubmit={props.onChangeDataSubmit}
      />
    </div>
  );
};
