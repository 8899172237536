import { DateTime } from "luxon";
import { API, graphqlOperation } from "aws-amplify";
//import { searchContactsByOffset } from "api/graphql/queries";
// import { createContact } from "api/graphql/mutations";
import { useXGridComponents } from "../../../utils/useXGridComponents";
import { useNavigate, useLocation } from "react-router-dom";
import { columns } from "views/templates/Contact/Container";
import { add } from "ducks/Alert";
import { useDispatch } from "react-redux";

/**
 * お問い合わせ管理の表示を行うコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const xGridContacts = useXGridComponents(columns, "searchContactsByOffset");

  const transiteDetailPage = (params) => {
    navigate(`${location.pathname}/${params[0]}`, {
      formValue: params[0],
    });
  };

  const submit = (params) => {
    // return API.graphql(
    //   graphqlOperation(createContact, {
    //     input: {
    //       processingState: params.supportState,
    //       method: params.contactMethods,
    //       receivedAt: DateTime.fromJSDate(new Date(params.contactTime)),
    //       category: params.contactType,
    //       companyName: params.company,
    //       nameOfInquirer: params.representativeName,
    //       phone: params.phoneNumber === "" ? null : params.phoneNumber,
    //       email: params.email === "" ? null : params.email,
    //       message: params.remarks,
    //     },
    //   })
    // );
  };

  const handleSubmitted = () => {
    xGridContacts.functions.refetch();
    dispatch(
      add({
        title: null,
        value: "登録しました",
        severity: "success",
      })
    );
  };

  const handleError = (err) => {
    dispatch(
      add({
        title: null,
        value: "エラーが発生したため、登録できませんでした",
        severity: "error",
      })
    );
  };

  return render({
    submit: submit,
    onSubmitted: handleSubmitted,
    onError: handleError,
    onTransite: transiteDetailPage,
    contactXGridParams: xGridContacts.params,
    isLoading: xGridContacts.params.loading,
    ...props,
  });
};
