import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchPlans } from "./api";

const plans = createSlice({
  name: "Plans",
  initialState: {
    value: [],
    isLoading: false,
    error: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.value = [];
          for (let service of action.payload) {
            state.value.push(service);
          }
        }
        state.isLoading = false;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアからプラン一覧全体を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.Plans;

/**
 * ストアからプランを取得します。
 */
export const plansSelector = createSelector(selector, (plans) => plans.value);

/**
 * ストアから読み込み状態を取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (plans) => plans.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(selector, (plans) => plans.error);

export default plans.reducer;
