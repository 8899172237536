import { Button } from "@mui/material";
import { useOpener } from "utils/useOpener";
import { XGridComponent } from "views/molecules";
import { Form } from "./Form";
import { searchUserAccountsByOffset } from "api/graphql/queries";
import { useXGridComponents, fromQueryString } from "utils/useXGridComponents";
import { useLocation } from "react-router-dom";

const columns = [
  {
    field: "name",
    headerName: "ユーザー名",
    flex: 1,
  },
  {
    field: "roleName",
    headerName: "権限",
    flex: 1,
    sortable: false,
  },
  {
    field: "email",
    headerName: "メールアドレス",
    flex: 1,
  },
  {
    field: "removedAt",
    headerName: "状況",
    renderCell: (params) => {
      return !!params.value ? "停止" : "";
    },
    sortable: false,
  },
];

/**
 * ユーザー一覧を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @fires Table#onSelected 選択時
 * @returns {JSX.Element}
 */
export const Table = ({ onSelected = () => {} }) => {
  const location = useLocation();
  const form = useOpener();
  const xGridObject = useXGridComponents(
    columns,
    searchUserAccountsByOffset,
    null,
    fromQueryString(location.search)
  );

  const handleSubmit = () => {
    xGridObject.functions.refetch();
  };

  const handleSelect = (items) => {
    onSelected &&
      onSelected({
        value: xGridObject.params.rows.filter((row) => items.includes(row.id)),
        info: {
          search: xGridObject.search,
        },
      });
  };

  return (
    <>
      <XGridComponent
        {...xGridObject.params}
        onChangeSelection={handleSelect}
        onColumns={false}
        onFilter={true}
        onDownload={false}
        disableColumnMenu={true}
        actionButton={
          <Button
            variant="contained"
            color="primary"
            onClick={() => form.toggle(true)}
          >
            メンバーを追加
          </Button>
        }
      />
      <Form
        open={form.open}
        onClose={() => form.toggle(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};
