import { createSlice } from "@reduxjs/toolkit";
import { fetchPlanServices } from "./api";
import { createSelector } from "reselect";

const planServices = createSlice({
  name: "PlanServices",
  initialState: {
    value: [],
    isLoading: false,
    error: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanServices.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPlanServices.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.value = [];
          for (let service of action.payload) {
            state.value.push(service);
          }
        }
        state.isLoading = false;
      })
      .addCase(fetchPlanServices.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * プランの提供サービスを取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.PlanServices;

/**
 * プランの提供サービス情報を取得します。
 */
export const planServiceSelector = createSelector(
  selector,
  (planService) => planService.value
);

export default planServices.reducer;
