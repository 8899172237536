import { useStyles } from "./styles";

/**
 * プラン詳細リストで表示するコンテナコンポーネントです。
 * @param {object} value プラン詳細リスト
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useStyles();

  return render({
    classes: classes,
    value: value,
    ...props,
  });
};
