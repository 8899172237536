import React, { useState } from "react";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import { makeStyles } from "@mui/styles";
import {
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import { AuthenticationTemplate } from "../../organisms";

const logger = new Logger("ForgotPassword");

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const initialState = {
  username: "",
  code: "",
  password: "",
};

export const ForgotPassword = (props) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [delivery, setDelivery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const _validAuthStates = ["forgotPassword"];

  const setInput = (key, value) => setFormState({ ...formState, [key]: value });

  const send = () => {
    const { username } = formState;
    Auth.forgotPassword(username)
      .then((data) => {
        logger.debug(data);
        setDelivery(data.CodeDeliveryDetails);
      })
      .catch((err) => {
        logger.debug(err);
        setError("エラーが発生しました。");
      });
  };

  const submit = () => {
    setError("");
    setLoading(true);
    const { username, code, password } = formState;
    Auth.forgotPasswordSubmit(username, code, password)
      .then((data) => {
        logger.debug(data);
        props.onStateChange("signIn");
        setDelivery(null);
      })
      .catch((err) => {
        switch (error.code) {
          case "CodeMismatchException":
            setError("入力されたコードに誤りがあります。");
            break;
          case "LimitExceededException":
            setError("入力規定回数を超えました。");
            break;
          case "ExpiredCodeException":
            setError("入力されたコードの有効期限が切れています。");
            setDelivery(null);
            break;
          case "InvalidPasswordException":
          case "InvalidParameterException":
            setError("指定されたパスワードは規定の強度を満たしていません。");
            break;
          default:
            logger.debug(err);
            setError("エラーが発生しました。");
            break;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    _validAuthStates.includes(props.authState) && (
      <AuthenticationTemplate>
        <Typography component="h2" variant="h5">
          {delivery
            ? "確認コードを入力して新しいパスワードを設定する"
            : "パスワード再設定メールを送る"}
        </Typography>
        <form className={classes.form} noValidate>
          {delivery ? (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="確認コード"
                autoFocus
                value={formState.code}
                onChange={(event) => setInput("code", event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                value={formState.password}
                onChange={(event) => setInput("password", event.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                disabled={loading}
                onClick={submit}
                fullWidth
              >
                {loading ? <CircularProgress size={24} /> : "設定"}
              </Button>
            </>
          ) : (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                autoFocus
                autoComplete="email"
                value={formState.username}
                onChange={(event) => setInput("username", event.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={send}
                fullWidth
              >
                送信
              </Button>
            </>
          )}
          {error && (
            <Typography color="error" paragraph>
              {error}
            </Typography>
          )}
          <Link
            variant="body2"
            component="button"
            onClick={() => props.onStateChange("signIn")}
          >
            ログインに戻る
          </Link>
        </form>
      </AuthenticationTemplate>
    )
  );
};
