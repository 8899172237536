import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

export const useStyles = makeStyles((theme) => ({
  box_data_infor: {
    display: "flex",
    alignItems: "stretch",
    borderBottom: "1px solid",
    borderBottomColor: grey[300],
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  box_data_label: {
    minWidth: "230px",
    backgroundColor: grey[300],
    textAlign: "center",
    padding: "10px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box_data_value: {
    flexGrow: 1,
    padding: "8px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    whiteSpace: "pre-wrap",
  },
}));
