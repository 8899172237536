import { Avatar } from "@mui/material";
import { AvatarGroup } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { RenderText } from "views/atoms";
import { toText, toCurrency } from "utils/contexts/PlansMeteredBilling";

const RenderAvatar = (props) => (
  <div className={props.classes.box_data_infor}>
    <div className={props.classes.box_data_label}>{props.label}</div>
    <AvatarGroup
      className={props.classes.group_avatar}
    >
      {props.value
        ?.filter((item) => item.available)
        .map?.((item, index) => (
          <Avatar
            alt={item.name}
            className={clsx(props.classes.item_avatar, item.code)}
            key={index}
          >
            {item.name.substring(0, 2)}
          </Avatar>
        )) ?? <div></div>}
    </AvatarGroup>
  </div>
);

/**
 * 販売プランの内容の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div>
    <RenderText
      label="ペースプラン"
      value={`${props.basePlan?.name ?? ""} ${toCurrency(
        props.basePlan?.basicCharge
      )} 円/月`}
    />
    <RenderText
      label="基本料金"
      value={`${toCurrency(props.value?.basicCharge)} 円/月`}
    />
    <RenderText
      label="従量課金"
      value={
        <div>
          {props.value?.meteredBilling?.map((billing, index) => (
            <div key={index}>{toText(billing)}</div>
          )) ?? <></>}
        </div>
      }
    />
    <RenderText label="トライアル日数" value={props.value?.trialDays} />
    <RenderText label="概要" value={props.value?.description} />
    <RenderAvatar
      label="提供サービス"
      classes={props.classes}
      value={props.services}
    />
  </div>
);
